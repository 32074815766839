import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonToast,
  IonBackButton,
} from "@ionic/react";
import { useParams } from "react-router";
import SchedulerNavbar from "../../../../components/Scheduler/SchedulerNavbar/SchedulerNavbar";
import "./ResetPassword.css";

import React, { useMemo } from "react";
import Footer from "../../../../components/Checkout/Footer";
import { eye, eyeOff, navigateCircleOutline } from "ionicons/icons";

import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import plnSymbol from "../../../../assets/img/logo/pln_symbol.svg";
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";
import { Capacitor } from "@capacitor/core";
import StringUtils from "../../../../lib/StringUtils";
import { Link } from "react-router-dom";
import SEOHeaders from "../../../../components/SEOHeaders";
import mobileLoginBG from "../../../../assets/img/backgrounds/mobileLoginBG-min.jpg";
import whiteSymbol from "../../../../assets/img/logo/pln_symbol_white.svg";
import memberTrust from "../../../../assets/img/icons/memberTrust.svg";
import LinkUtils from "../../../../lib/LinkUtils";

class ResetPasswordV2 extends React.Component<{ history: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    slide: 1,
  };

  googleMap: any = React.createRef();
  formSection: any = React.createRef();
  listSection: any = React.createRef();

  handleTransition() {
    const interval = setInterval(() => {
      let slide = this.state.slide;

      if (slide == 4) {
        slide = 1;
      } else {
        slide = slide + 1;
      }

      this.setState({
        slide,
      });
    }, 10000);

    this.setState({
      slideInterval: interval,
    });
  }

  componentDidMount(): void {
    this.handleTransition();

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    let token = urlParams.get("token");
    let code = urlParams.get("code");

    if (!token) {
      return this.props.history.push("/login");
    }

    this.setState({
      token,
    });

    if (code) {
      this.setState({
        code,
      });
    }
  }

  componentWillUnmount(): void {
    if (this.state.slideInterval) {
      clearInterval(this.state.slideInterval);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {}

  setError(message: string) {
    this.setState({
      error: message,
    });

    if (message) {
      setTimeout(() => {
        this.setError("");
      }, 7000);
    }
  }

  continue() {
    this.setState({
      submitting: true,
    });

    API.setPassword(
      this.state.token,
      this.state.password,
      this.state.code ? this.state.code : null
    )
      .then(
        (data: any) => {
          let resp = data?.data?.data;

          console.log(resp);

          PubSub.publish(Constants.TOAST.SHOW, {
            template: "success",
            message: "Your password has been successfully updated.",
            position: "top",
          });

          this.setState({
            token: "",
            password: "",
          });

          this.props.history.push("/login");
        },
        (e) => {
          console.error(e);

          this.setError(
            `Unable to set your password. ${
              e?.response?.data?.message
                ? e?.response?.data?.message
                : "Unknown error occurred, please try again."
            }`
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });

    return;
  }

  render() {
    return (
      <IonPage>
        <SEOHeaders title="Reset Password"></SEOHeaders>
        {Capacitor.isNativePlatform() && (
          <>
            <IonHeader>
              <IonToolbar className="pln-native-toolbar">
                <IonButtons slot="start">
                  <IonBackButton />
                </IonButtons>
                <IonTitle>Reset Password</IonTitle>
              </IonToolbar>
            </IonHeader>
          </>
        )}
        <IonContent>
          <div style={{}}>
            {/** Desktop View */}
            <div
              style={{
                maxWidth: 1200,
                margin: "auto",
              }}
            >
              <IonGrid className="p-0">
                <IonRow>
                  <IonCol
                    size-xs={12}
                    size-md={6}
                    className="lg:border-l-[1px] border-gray-100"
                  >
                    <div className="p-4 flex w-full h-full items-center">
                      <div>
                        <h1 className="mt-3 text-3xl lg:text-4xl mb-0 font-weight-bold">
                          Set Your Password
                        </h1>
                        <p
                          className="small text-gray-900 mt-2"
                          style={{ lineHeight: 1.3 }}
                        >
                          Enter a new password below to update your account.
                          Your new password must be 8+ characters long.
                        </p>
                        <div className="mt-4">
                          <form
                            onSubmit={(e: any) => {
                              e.preventDefault();

                              this.continue();
                            }}
                          >
                            <div className="mt-3">
                              {this.state.error && (
                                <div className="mb-3 text-danger">
                                  {this.state.error}
                                </div>
                              )}

                              <div className={`relative mb-4`}>
                                <label
                                  htmlFor="newPassword"
                                  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs sm:text-sm font-medium text-gray-900"
                                >
                                  Password
                                </label>
                                <input
                                  auto-capitalize="none"
                                  name="newPassword"
                                  id="newPassword"
                                  type={
                                    this.state.viewPassword
                                      ? "text"
                                      : "password"
                                  }
                                  autoComplete="new-password"
                                  className={`block w-full outline-0 rounded border-[1px] px-[0.75rem] py-[0.75rem] sm:px-3 sm:py-3 text-gray-900 shadow-sm ring-0 ring-inset ring-orange-500 placeholder:text-gray-400 text-sm sm:text-base sm:leading-6`}
                                  placeholder="Password"
                                  value={this.state.newPassword}
                                  required={true}
                                  onChange={(event: any) => {
                                    this.setState({
                                      password: event?.target?.value,
                                    });
                                  }}
                                />
                                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-[0.75rem]">
                                  <button
                                    type="button"
                                    className="focus:outline-none text-xs text-gray-900"
                                    onClick={() => {
                                      this.setState({
                                        viewPassword: !this.state.viewPassword,
                                      });
                                    }}
                                  >
                                    {this.state.viewPassword ? "Hide" : "Show"}
                                  </button>
                                </div>
                              </div>

                              <IonButton
                                type="submit"
                                className="pln-button-web"
                                mode="ios"
                                expand={"block"}
                                color="primary"
                                disabled={
                                  !this.state.password ||
                                  this.state.password?.length < 8 ||
                                  this.state.submitting
                                }
                                onClick={() => {
                                  this.continue();
                                }}
                              >
                                {this.state.submitting ? (
                                  <>
                                    <IonSpinner name="dots"></IonSpinner>
                                  </>
                                ) : (
                                  <>Continue</>
                                )}
                              </IonButton>
                              <button
                                type="submit"
                                style={{
                                  position: "absolute",
                                  visibility: "hidden",
                                }}
                              >
                                submit
                              </button>
                              <div
                                className="mt-3 text-center text-gray-700"
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                By clicking Continue, you agree to our{" "}
                                <a
                                  href="https://projectleannation.com/tos"
                                  rel="noreferrer"
                                  onClick={(e: any) => {
                                    e.preventDefault();

                                    LinkUtils.openInNewTab(
                                      "https://projectleannation.com/tos"
                                    );
                                  }}
                                  className="text-gray-700 underline"
                                >
                                  Terms of Service
                                </a>
                                ,{" "}
                                <a
                                  href="https://projectleannation.com/privacy"
                                  rel="noreferrer"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    LinkUtils.openInNewTab(
                                      "https://projectleannation.com/privacy"
                                    );
                                  }}
                                  className="text-gray-700 underline"
                                >
                                  Privacy Policy
                                </a>
                                , and{" "}
                                <a
                                  href="https://projectleannation.com/marketing-policy"
                                  rel="noreferrer"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    LinkUtils.openInNewTab(
                                      "https://projectleannation.com/marketing-policy"
                                    );
                                  }}
                                  className="text-gray-700 underline"
                                >
                                  Marketing Policy
                                </a>
                                .
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </IonCol>
                  <IonCol
                    className="d-none d-md-flex p-0 right-col"
                    style={{ position: "relative" }}
                  >
                    <div className="absolute left-[0px] top-[0px] w-full">
                      <div
                        className="flex items-start justify-start pb-4"
                        style={{
                          background:
                            "linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 71%, rgba(0,0,0,0.15) 91%, rgba(0,0,0,0) 100%)",
                        }}
                      >
                        <img
                          src={whiteSymbol}
                          alt="Project LeanNation Logo"
                          className="mt-[2rem] ml-[2rem] w-[40%] max-w-[100px]"
                        ></img>
                      </div>
                    </div>
                    <div
                      className="w-full h-[100vh] bg-cover bg-center bg-norepeat border-l-[1px] border-gray-200"
                      style={{
                        backgroundImage: `url(${mobileLoginBG})`,
                      }}
                    ></div>
                    <div
                      className="absolute bottom-[0px] left-[0px] w-full p-[1.5rem] lg:p-[2rem]"
                      style={{
                        background:
                          "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 6%, rgba(0,0,0,0.5519502801120448) 29%, rgba(0,0,0,0.8) 100%)",
                      }}
                    >
                      <div className="mb-3">
                        <img
                          src={memberTrust}
                          className="w-[60%] max-w-[200px]"
                          alt="Trust"
                        />
                      </div>
                      <h1 className="text-3xl lg:text-4xl tracking-tight text-white font-bold">
                        Achieve More, Every Day
                      </h1>
                      <p className="text-white tracking-tight font-medium text-xl lg:text-2xl mt-2">
                        with tools, guidance, and prepared meals designed for
                        real progress.
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default ResetPasswordV2;
