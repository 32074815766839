import { IonContent, IonPage, IonSkeletonText } from "@ionic/react";
import UpcomingOrderCardSkeleton from "../AccountManagement/UpcomingOrderCard/UpcomingOrderCardSkeleton";

const WebAppSkeletonLoader: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          style={{
            height: 66,
            width: "100",
            borderBottom: "1px solid var(--midlighter)",
            background: "var(--white)",
          }}
        ></div>
        <div
          style={{ maxWidth: 1200, margin: "auto" }}
          className="ion-padding px-lg-0 pt-0"
        >
          <div className="mb-4 mt-5 pb-2">
            <IonSkeletonText
              style={{
                height: 24,
                width: "80%",
                maxWidth: 220,

                borderRadius: 6,
              }}
              animated={true}
            ></IonSkeletonText>
            <IonSkeletonText
              style={{
                height: 12,
                width: "50%",
                maxWidth: 160,
                marginTop: 12,
                borderRadius: 6,
              }}
              animated={true}
            ></IonSkeletonText>
            <div className="py-1"></div>
            <IonSkeletonText
              style={{
                height: 22,
                width: "50%",
                maxWidth: 100,
                borderRadius: 6,
              }}
              className="mt-4 mb-0"
              animated={true}
            ></IonSkeletonText>
          </div>
          <div className="mt--2">
            <UpcomingOrderCardSkeleton
              nativeMode={false}
            ></UpcomingOrderCardSkeleton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default WebAppSkeletonLoader;
