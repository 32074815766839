export default class Constants {
  static BUILDERIO_KEY: string = "0b72fb641a144b10bbcac93ca42d7779";
  static STRIPE_KEY_TEST: string =
    "pk_test_51JLvqwHigOZnL8zU7ohNYK6rLGKYjVwqPXIYLjRwPqcxaEPZlSJWZoz51pQdlRaLnH0Rf2dUBNkQSILiGHSyKBK200IjgYgx3A";
  static STRIPE_KEY: string =
    "pk_live_51JLvqwHigOZnL8zUzZahCuNfPFUA54fwhEyqDEN74eJEaT8JTVqgw63QRba6BKMA5KGSOO1806M7id8ckIZWDTPw00IQq5XaHH";

  static GOOGLE_MAPS_API_KEY: string =
    "AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs";

  static GOOGLE_RECAPTCHA_KEY = "6LeTEiQiAAAAAHS9Ad_mpYXW9H8D82TRofn740Tz";

  static GOOGLE_CLIENT_ID =
    "508705100957-vi6b2kvikgdgab9te8pjo2rqiurmu5jj.apps.googleusercontent.com";
  static GOOGLE_CLIENT_SCOPES =
    "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.phonenumbers.read";

  static SEGMENT_API_KEY = "r7C41BUUTklV0j1BO8BJkpG3zu0oqz7n";
  static SEGMENT_API_KEY_DEV = "ImdvYAYxLfjAX9zdRjWVE4MFCKDoa768";

  static TOAST: any = {
    SHOW: "toastShow",
  };

  static CONVERSION_VALUES: any = {
    NEW_MEMBER: 928.65, // LTV * 0.7 - Last assessed 2024-08-10
    NEW_LEAD: 75.22, // LTV * 0.7 * Conversion Rate - Last assessed 2024-08-10
  };

  static APPOINTMENT_TYPES: any = {
    "ec-consult": {
      APPOINTMENT_TYPE: "ec-consult",
      NAME: "Member Check-In & InBody Scan",
      DESCRIPTION:
        "Meet with your coach to take an InBody scan, assess progress towards your goals, and review your plan.",
      DURATION_SECONDS: 20 * 60,
      DURATION_READABLE: "20 Minute",
      PUBLIC: false,
      FIELDS: ["name", "email", "phone", "notes"],
    },
    "nc-consult": {
      APPOINTMENT_TYPE: "nc-consult",
      NAME: "Client Consultation & Inbody Scan",
      DESCRIPTION:
        "Meet one-on-one with a coach to get a FREE InBody scan, discuss your goals, and develop a strategy to achieve success.",
      DURATION_SECONDS: 20 * 60,
      DURATION_READABLE: "20 Minute",
      PUBLIC: true,
      FIELDS: ["name", "email", "phone", "goal", "notes"],
    },
    "challenge-io": {
      APPOINTMENT_TYPE: "challenge-io",
      NAME: "6 Week Challenge: Weigh-In / Weigh-Out",
      DESCRIPTION:
        "Stop in for your 6 Week challenge weigh-in / weigh-out to track your progress and see how you've improved.",
      DURATION_SECONDS: 60 * 5,
      DURATION_READABLE: "5 Minute",
      PUBLIC: true,
      FIELDS: ["name", "email", "phone", "notes"],
    },
  };

  static HEATING_INSTRUCTIONS_NOT_HEATED: string =
    "This item does not need heating to eat.";

  static NEW_MEMBER_GOAL_OPTIONS: any = [
    {
      name: "Manage My Weight",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-[40px] w-[40px] md:h-[50px] md:w-[50px] ml-auto mr-auto mb-[0.5rem] inline"><path d="M21.3 15.3a2.4 2.4 0 0 1 0 3.4l-2.6 2.6a2.4 2.4 0 0 1-3.4 0L2.7 8.7a2.41 2.41 0 0 1 0-3.4l2.6-2.6a2.41 2.41 0 0 1 3.4 0Z"/><path d="m14.5 12.5 2-2"/><path d="m11.5 9.5 2-2"/><path d="m8.5 6.5 2-2"/><path d="m17.5 15.5 2-2"/></svg>`,
    },
    {
      name: "Save Time",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-[40px] w-[40px] md:h-[50px] md:w-[50px] ml-auto mr-auto mb-[0.5rem] inline"><circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16 14"/></svg>`,
    },
    {
      name: "Improve Health & Performance",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-[40px] w-[40px] md:h-[50px] md:w-[50px] ml-auto mr-auto mb-[0.5rem] inline"><path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z"/></svg>`,
    },
    {
      name: "Control My Portion Sizes",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-[40px] w-[40px] md:h-[50px] md:w-[50px] ml-auto mr-auto mb-[0.5rem] inline"><path d="M21.21 15.89A10 10 0 1 1 8 2.83"/><path d="M22 12A10 10 0 0 0 12 2v10z"/></svg>`,
    },
    {
      name: "Eat More Variety",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-[40px] w-[40px] md:h-[50px] md:w-[50px] ml-auto mr-auto mb-[0.5rem] inline"><path d="M6 13.87A4 4 0 0 1 7.41 6a5.11 5.11 0 0 1 1.05-1.54 5 5 0 0 1 7.08 0A5.11 5.11 0 0 1 16.59 6 4 4 0 0 1 18 13.87V21H6Z"/><line x1="6" x2="18" y1="17" y2="17"/></svg>`,
    },
    {
      name: "None Of These Apply",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-[40px] w-[40px] md:h-[50px] md:w-[50px] ml-auto mr-auto mb-[0.5rem] inline"><circle cx="12" cy="12" r="10"/><path d="m4.9 4.9 14.2 14.2"/></svg>`,
    },
  ];

  static DEFAULT_CHECKOUT_MEALS: any = [
    {
      _id: "6630fffe4e8c3c71b6f16228",
      name: "BBQ Chicken Pizza",
      description:
        "<p>A cauliflower crust topped with smoky BBQ chicken, tangy pickled jalapeños, melted Monterey Jack cheese, and zesty red onion.</p>\n",
      imageURL:
        "https://s3.amazonaws.com/cdn.projectleannation.com/assets/43be3a71-2865-410b-9975-eec2a3e4d02c.png",
      sku: "BBQ Chicken Pizza",
      planTypeID: "613bdb37164e9569b55f38d0",
      nutrition: {
        calories: 490,
        carbs: 28,
        protein: 42,
        fat: 24,
        sugar: 1,
        fiber: 1,
        sodium: 470,
      },
      foodWeight: 10.33,
      finishedWeight: null,
      categories: [
        {
          _id: "613e470913c758764b8ca86b",
          name: "Gluten Free",
          Created: "2021-09-29T17:57:49.219Z",
          modifiedAt: "2021-09-29T17:57:49.219Z",
          _idString: "613e470913c758764b8ca86b",
        },
      ],
      allergens: [
        {
          _id: "613e46cd13c758764b8ca862",
          name: "Egg",
          Created: "2021-09-29T17:57:48.119Z",
          modifiedAt: "2021-09-29T17:57:48.119Z",
          _idString: "613e46cd13c758764b8ca862",
        },
        {
          _id: "613e46d413c758764b8ca864",
          name: "Dairy",
          Created: "2021-09-29T17:57:49.171Z",
          modifiedAt: "2021-09-29T17:57:49.171Z",
          _idString: "613e46d413c758764b8ca864",
        },
      ],
      microwaveInstructions:
        "<p><strong>From The Freezer: </strong>Heat in the Microwave for 3-4 minutes until desired temperature. Allow all steam to dissipate before opening the seal. Remove the seal to open container and stir contents. Heat for another 30-45 seconds.</p>\n    <p><strong>Thawed / From The Fridge: </strong>Heat in the Microwave for 2-3 minutes until desired temperature. Allow all steam to dissipate before opening the seal. Remove the seal to open container and stir contents. Heat for another 15-30 seconds.</p>",
      airFryInstructions:
        '<p><span style="color: rgb(82,95,127);background-color: rgb(247,247,246);font-size: 16px;font-family: Karla, sans-serif;"><strong>From The Freezer: </strong>Transfer meal to oven-save dish. Heat for 8-10 minutes or until desired temperature. Stir contents &amp; enjoy your meal!</span></p>\n     <p><span style="color: rgb(82,95,127);background-color: rgb(247,247,246);font-size: 16px;font-family: Karla, sans-serif;"><strong>Thawed / From The Fridge:</strong> Transfer meal to oven-save dish. Heat for 7-8 minutes or until desired temperature. Stir contents &amp; enjoy your meal!</span></p>',
      ovenInstructions:
        "<p><strong>From The Freezer: </strong>Transfer meal to oven-save dish. Heat for 12-15 minutes at 300°F or until desired temperature. Stir contents &amp; enjoy your meal!</p>\n    <p><strong>Thawed / From The Fridge: </strong>Transfer meal to oven-save dish. Heat for 8-12 minutes at 300°F or until desired temperature. Stir contents &amp; enjoy your meal!</p>",
      primaryProtein: "Chicken - Strips",
      primaryProteinCategory: "Chicken",
      ingredients:
        "Chicken Strips (Chicken Breast, Water, Salt, Sugar, Natural Flavors), BBQ Sauce, Cheddar Jack Cheese (Milk, Cheese Cultures, Enzymes), Cauliflower Crust (Cauliflower, Mozzarella, Rice Flour, Rice Starch, Egg Whites, Yeast, Salt, Garlic Powder, Spices), Red Onion, Jalapeno Pepper. ",
      transFat: 0,
      saturatedFat: 2,
      cholesterol: 110,
      vitaminD: 0,
      potassium: 350,
      calcium: 513,
      iron: 1,
      createdAt: "2024-04-30T14:28:14.055Z",
      modifiedAt: "2024-07-31T07:03:05.739Z",
      _idString: "6630fffe4e8c3c71b6f16228",
      deeplinkURL: "https://pln.fit/mi-6630fffe4e8c3c71b6f16228",
      webOnlyDeeplinkURL: "https://pln.fit/e/v7URzuqJdJb",
      qrCodeImageURL:
        "https://s3.amazonaws.com/cdn.projectleannation.com/assets/86f55dbf-909d-4ac7-87c8-5a6911f149a9.png",
      platedImageURL:
        "https://s3.amazonaws.com/cdn.projectleannation.com/assets/61ae1ba4-b355-4af0-8e61-128cbb015f40.png",
      squareItem: "UYORVBOI5FNPWW36BSAA4UYU",
      labelIngredients: null,
      logisticsCode: "LN",
      spicy: false,
      planType: {
        _id: "613bdb37164e9569b55f38d0",
        name: "Lifestyle",
        description:
          "The foundation of our lifestyle plan is consuming whole foods that are perfectly portioned to help you crush your health goals.  You’ll love the way it feels to eat nutrient-rich, gluten-free meals.",
        calorieMin: 400,
        calorieMax: 500,
        Created: "2021-09-29T17:57:47.764Z",
        LastUpdated: "2021-09-29T17:57:47.764Z",
        groupCode: "L",
        modifiedAt: "2023-03-23T18:12:23.116Z",
        _idString: "613bdb37164e9569b55f38d0",
      },
    },
    {
      _id: "65d35825a81518f035c7b550",
      name: "Blackened Lemon Shrimp",
      description:
        "<p>Blackened shrimp paired with creamy grits, edamame, and cilantro lime aioli. A tantalizing fusion of flavors in every bite!</p>\n",
      imageURL:
        "https://s3.amazonaws.com/cdn.projectleannation.com/assets/bb913b75-3284-45d0-9899-fabd49cb3472.png",
      sku: "Blackened Lemon Shrimp",
      planTypeID: "613bdb37164e9569b55f38d0",
      nutrition: {
        calories: 375,
        carbs: 18,
        protein: 38,
        fat: 18,
        sugar: 2,
        fiber: 5,
        sodium: 589,
      },
      foodWeight: 12.45,
      finishedWeight: null,
      categories: [
        {
          _id: "613e471013c758764b8ca86c",
          name: "Grain Free",
          Created: "2021-09-29T17:57:49.310Z",
          modifiedAt: "2021-09-29T17:57:49.310Z",
          _idString: "613e471013c758764b8ca86c",
        },
      ],
      allergens: [
        {
          _id: "613e46cd13c758764b8ca862",
          name: "Egg",
          Created: "2021-09-29T17:57:48.119Z",
          modifiedAt: "2021-09-29T17:57:48.119Z",
          _idString: "613e46cd13c758764b8ca862",
        },
        {
          _id: "613e46d113c758764b8ca863",
          name: "Soy",
          Created: "2021-09-29T17:57:49.088Z",
          modifiedAt: "2021-09-29T17:57:49.088Z",
          _idString: "613e46d113c758764b8ca863",
        },
        {
          _id: "613e46df13c758764b8ca865",
          name: "Fish",
          Created: "2021-09-29T17:57:49.255Z",
          modifiedAt: "2021-09-29T17:57:49.255Z",
          _idString: "613e46df13c758764b8ca865",
        },
        {
          _id: "613e46d413c758764b8ca864",
          name: "Dairy",
          Created: "2021-09-29T17:57:49.171Z",
          modifiedAt: "2021-09-29T17:57:49.171Z",
          _idString: "613e46d413c758764b8ca864",
        },
      ],
      microwaveInstructions:
        "<p><strong>From The Freezer: </strong>Heat in the Microwave for 3-4 minutes until desired temperature. Allow all steam to dissipate before opening the seal. Remove the seal to open container and stir contents. Heat for another 30-45 seconds.</p>\n    <p><strong>Thawed / From The Fridge: </strong>Heat in the Microwave for 2-3 minutes until desired temperature. Allow all steam to dissipate before opening the seal. Remove the seal to open container and stir contents. Heat for another 15-30 seconds.</p>",
      airFryInstructions:
        '<p><span style="color: rgb(82,95,127);background-color: rgb(247,247,246);font-size: 16px;font-family: Karla, sans-serif;"><strong>From The Freezer: </strong>Transfer meal to oven-save dish. Heat for 8-10 minutes or until desired temperature. Stir contents &amp; enjoy your meal!</span></p>\n     <p><span style="color: rgb(82,95,127);background-color: rgb(247,247,246);font-size: 16px;font-family: Karla, sans-serif;"><strong>Thawed / From The Fridge:</strong> Transfer meal to oven-save dish. Heat for 7-8 minutes or until desired temperature. Stir contents &amp; enjoy your meal!</span></p>',
      ovenInstructions:
        "<p><strong>From The Freezer: </strong>Transfer meal to oven-save dish. Heat for 12-15 minutes at 300°F or until desired temperature. Stir contents &amp; enjoy your meal!</p>\n    <p><strong>Thawed / From The Fridge: </strong>Transfer meal to oven-save dish. Heat for 8-12 minutes at 300°F or until desired temperature. Stir contents &amp; enjoy your meal!</p>",
      primaryProtein: "Fish - Salmon",
      primaryProteinCategory: "Fish",
      transFat: 0,
      saturatedFat: 2,
      cholesterol: 255,
      vitaminD: 0,
      potassium: 590,
      calcium: 163,
      iron: 3,
      createdAt: "2024-02-19T13:31:17.944Z",
      modifiedAt: "2024-07-31T07:03:05.645Z",
      _idString: "65d35825a81518f035c7b550",
      deeplinkURL: "https://pln.fit/mi-65d35825a81518f035c7b550",
      squareItem: "7OYBVRGTPGW77OATMT3NEDAB",
      ingredients:
        "Shrimp, Grits [white Hominy Grits Made From Corn, Niacin*, Reduced Iron*, Thiamine Mononitrate*, Riboflavin,* Folic Acid*, * One Of The B Vitamins],  Shelled Soybeans, Cilantro Lime Aioli [olive Oil, Whole Eggs, Cilantro, Vinegar, Water, Egg Yolks, Salt, Green Hatch Chiles, Lemon Juice, Olive Oil].",
      labelIngredients: null,
      webOnlyDeeplinkURL: "https://pln.fit/e/8k5YiFOxqHb",
      qrCodeImageURL:
        "https://s3.amazonaws.com/cdn.projectleannation.com/assets/c4d1fadf-62fa-4773-90db-390a0088b0ff.png",
      logisticsCode: "LP",
      platedImageURL:
        "https://s3.amazonaws.com/cdn.projectleannation.com/assets/3cd97da2-a2e1-4d50-a5c8-1c7fcc3a2ee4.png",
      spicy: true,
      planType: {
        _id: "613bdb37164e9569b55f38d0",
        name: "Lifestyle",
        description:
          "The foundation of our lifestyle plan is consuming whole foods that are perfectly portioned to help you crush your health goals.  You’ll love the way it feels to eat nutrient-rich, gluten-free meals.",
        calorieMin: 400,
        calorieMax: 500,
        Created: "2021-09-29T17:57:47.764Z",
        LastUpdated: "2021-09-29T17:57:47.764Z",
        groupCode: "L",
        modifiedAt: "2023-03-23T18:12:23.116Z",
        _idString: "613bdb37164e9569b55f38d0",
      },
    },
    {
      _id: "65d358e3a81518f035c7b551",
      name: "Buffalo Chicken Mac",
      description:
        "<p>Cavatappi pasta with creamy gruyere cheese sauce, topped with smoked buffalo chicken and paired with broccoli.</p>\n",
      imageURL:
        "https://s3.amazonaws.com/cdn.projectleannation.com/assets/05132b58-f94a-4b41-857c-22561c89a3f8.png",
      sku: "Buffalo Chicken Mac",
      planTypeID: "613bdb37164e9569b55f38d0",
      nutrition: {
        calories: 470,
        carbs: 40,
        protein: 42,
        fat: 17,
        sugar: 9,
        fiber: 4,
        sodium: 609,
      },
      foodWeight: 11.44,
      finishedWeight: null,
      categories: [
        {
          _id: "613e470913c758764b8ca86b",
          name: "Gluten Free",
          Created: "2021-09-29T17:57:49.219Z",
          modifiedAt: "2021-09-29T17:57:49.219Z",
          _idString: "613e470913c758764b8ca86b",
        },
      ],
      allergens: [
        {
          _id: "613e46cd13c758764b8ca862",
          name: "Egg",
          Created: "2021-09-29T17:57:48.119Z",
          modifiedAt: "2021-09-29T17:57:48.119Z",
          _idString: "613e46cd13c758764b8ca862",
        },
        {
          _id: "613e46d113c758764b8ca863",
          name: "Soy",
          Created: "2021-09-29T17:57:49.088Z",
          modifiedAt: "2021-09-29T17:57:49.088Z",
          _idString: "613e46d113c758764b8ca863",
        },
        {
          _id: "613e46d413c758764b8ca864",
          name: "Dairy",
          Created: "2021-09-29T17:57:49.171Z",
          modifiedAt: "2021-09-29T17:57:49.171Z",
          _idString: "613e46d413c758764b8ca864",
        },
      ],
      microwaveInstructions:
        "<p><strong>From The Freezer: </strong>Heat in the Microwave for 3-4 minutes until desired temperature. Allow all steam to dissipate before opening the seal. Remove the seal to open container and stir contents. Heat for another 30-45 seconds.</p>\n    <p><strong>Thawed / From The Fridge: </strong>Heat in the Microwave for 2-3 minutes until desired temperature. Allow all steam to dissipate before opening the seal. Remove the seal to open container and stir contents. Heat for another 15-30 seconds.</p>",
      airFryInstructions:
        '<p><span style="color: rgb(82,95,127);background-color: rgb(247,247,246);font-size: 16px;font-family: Karla, sans-serif;"><strong>From The Freezer: </strong>Transfer meal to oven-save dish. Heat for 8-10 minutes or until desired temperature. Stir contents &amp; enjoy your meal!</span></p>\n     <p><span style="color: rgb(82,95,127);background-color: rgb(247,247,246);font-size: 16px;font-family: Karla, sans-serif;"><strong>Thawed / From The Fridge:</strong> Transfer meal to oven-save dish. Heat for 7-8 minutes or until desired temperature. Stir contents &amp; enjoy your meal!</span></p>',
      ovenInstructions:
        "<p><strong>From The Freezer: </strong>Transfer meal to oven-save dish. Heat for 12-15 minutes at 300°F or until desired temperature. Stir contents &amp; enjoy your meal!</p>\n    <p><strong>Thawed / From The Fridge: </strong>Transfer meal to oven-save dish. Heat for 8-12 minutes at 300°F or until desired temperature. Stir contents &amp; enjoy your meal!</p>",
      primaryProtein: "Chicken - Pulled Thigh",
      primaryProteinCategory: "Chicken",
      transFat: 0,
      saturatedFat: 4,
      cholesterol: 145,
      vitaminD: 0,
      potassium: 480,
      calcium: 169,
      iron: 3,
      createdAt: "2024-02-19T13:34:27.886Z",
      modifiedAt: "2024-07-31T07:03:05.722Z",
      _idString: "65d358e3a81518f035c7b551",
      deeplinkURL: "https://pln.fit/mi-65d358e3a81518f035c7b551",
      squareItem: "YI6GQLCPZJJZ7GMGQNPZDDBL",
      ingredients:
        "Chicken, Broccoli, Pasta [water, Brown Rice Flour, Potato Starch, Whole Egg, Xanthan Gum], Buffalo Sauce [aged Cayenne Peppers, Vinegar, Sugar, Honey, Soy Sauce, Olive Oil, Spices], Cheese Sauce [cream, Cheddar Cheese, Monterey Jack Cheese, Gruyere Cheese, Corn Starch, Garlic, Onion, Spice].",
      labelIngredients: null,
      webOnlyDeeplinkURL: "https://pln.fit/e/byfNz4PxqHb",
      qrCodeImageURL:
        "https://s3.amazonaws.com/cdn.projectleannation.com/assets/fd3c4ee7-57e5-427b-9861-fd12b9cb1b5e.png",
      logisticsCode: "LE",
      platedImageURL:
        "https://s3.amazonaws.com/cdn.projectleannation.com/assets/e4f83f2b-5a32-4794-b3de-121a51f5dff1.png",
      spicy: true,
      planType: {
        _id: "613bdb37164e9569b55f38d0",
        name: "Lifestyle",
        description:
          "The foundation of our lifestyle plan is consuming whole foods that are perfectly portioned to help you crush your health goals.  You’ll love the way it feels to eat nutrient-rich, gluten-free meals.",
        calorieMin: 400,
        calorieMax: 500,
        Created: "2021-09-29T17:57:47.764Z",
        LastUpdated: "2021-09-29T17:57:47.764Z",
        groupCode: "L",
        modifiedAt: "2023-03-23T18:12:23.116Z",
        _idString: "613bdb37164e9569b55f38d0",
      },
    },
  ];
}
