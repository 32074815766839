import PubSub from "./PubSub";
import Event from "./Event";
import axios from "axios";
import Constants from "./Constants";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { App } from "@capacitor/app";

import { PushNotifications } from "@capacitor/push-notifications";

export default class API {
  static ENV_STAGING: any = "https://apiv2-staging.projectleannation.com";
  static ENV_PRODUCTION: any = "https://apiv2.projectleannation.com";
  //static ENV_PRODUCTION: any = "https://68cb046140d5.ngrok.app";

  static ENV_DEV: any = "http://localhost:8080";
  //static ENV_DEV = "https://b33a-8-9-83-164.ngrok.io";

  static ENV_USER_TOKEN: any = "";
  static ENV_CURRENT: any = "";

  /**
   * Sets the environment
   *
   * @param {*} env
   */
  static setEnvironment(env: any) {
    this.ENV_CURRENT = env;

    PubSub.publish(Event.ENV_CHANGED);
  }

  /**
   * Gets the current auth token.
   */
  static getAuthToken() {
    return this.ENV_USER_TOKEN;
  }

  /**
   * Sets the user's auth token.
   *
   * @param {*} val
   */
  static setAuthToken(val: any) {
    this.ENV_USER_TOKEN = val;

    PubSub.publish(Event.USER_LOADED);
  }

  /**
   * Sends a generic request.
   *
   * @param {*} options
   */
  static _sendRequest(options: any) {
    if (this.ENV_CURRENT) {
      return axios(options);
    } else {
      return new Promise((resolve, reject) => {
        PubSub.subscribe(Event.ENV_CHANGED, () => {
          options.uri = this.ENV_CURRENT + options.uri;

          axios(options).then(
            (data) => {
              if (data) {
                resolve(data);
              }
            },
            (error) => {
              reject(error);
            }
          );
        });
      });
    }
  }

  /**
   * Sends an unauthenticed HTTP request.
   *
   * @param method
   * @param path
   * @param data
   */
  static _unauthenticatedRequest(
    method: any,
    path: any,
    data: any = null,
    externalRequest: boolean = false
  ) {
    let options: any = {
      method: method,
      url: externalRequest ? path : this.ENV_CURRENT + path,
      json: true,
    };

    if (data) {
      options.data = data;
    }

    return this._sendRequest(options);
  }

  static scheduleAppointment(storeID: any = null, payload: any = null) {
    return this._unauthenticatedRequest(
      "POST",
      "/scheduler/stores/" + storeID,
      payload
    );
  }

  static getExistingAppointments(
    storeID: any = null,
    type = "nc-consult",
    date: any = null
  ) {
    return this._unauthenticatedRequest(
      "GET",
      `/scheduler/appointments/availability?storeID=${storeID}&type=${type}&date=${date}`
    );
  }

  static getAppointmentByID(appointmentID: any = null) {
    return this._unauthenticatedRequest(
      "GET",
      "/scheduler/appointments/" + appointmentID
    );
  }

  static cancelAppointment(appointmentID: any = null) {
    return this._unauthenticatedRequest(
      "DELETE",
      "/scheduler/appointments/" + appointmentID
    );
  }

  static rescheduleAppointment(
    appointmentID: any = null,
    dateTime: any = null
  ) {
    return this._unauthenticatedRequest(
      "PUT",
      "/scheduler/appointments/" + appointmentID + "/reschedule",
      {
        dateTime: dateTime,
      }
    );
  }

  /**
   * Creates or updates a lead by email.
   *
   * @param {*} email
   * @param {*} zip
   * @param {*} source
   * @param {*} prms
   * @returns
   */
  static createOrUpdateLead(
    email: any = null,
    zip: any = null,
    source: any = null,
    prms: any = {}
  ) {
    let params = prms ? prms : {};

    if (email) {
      params.email = email;
    }

    if (zip) {
      params.zipCode = zip.toString();
    }

    if (source) {
      params.source = source;
    }

    return this._unauthenticatedRequest(
      "POST",
      "/customer-account/lead?v=2",
      params
    );
  }

  static updateLeadCart(
    lead: any = null,
    cartState: any = {},
    forceOverwrite = false
  ) {
    let payload: any = {
      storeID: lead.storeID ? lead.storeID : "",
      email: lead.email ? lead.email : "",
      phone: lead.phone ? lead.phone : "",
      zipCode: lead.zipCode ? lead.zipCode.toString() : "",
      utmSource: lead.utmSource ? lead.utmSource : "",
      utmMedium: lead.utmMedium ? lead.utmMedium : "",
      utmCampaign: lead.utmCampaign ? lead.utmCampaign : "",
      utmContent: lead.utmContent ? lead.utmContent : "",
      utmTerms: lead.utmTerms ? lead.utmTerms : "",
      promo: lead.promo ? lead.promo : "",
      cartState,
      forceOverwrite,
    };

    return this._unauthenticatedRequest(
      "POST",
      "/customer-account/lead?v=2",
      payload
    );
  }

  /**
   * Sends a basic authenticated HTTP request.
   *
   * @param method
   * @param path
   * @param username
   * @param password
   * @param data
   */
  static _basicAuthRequest(
    method: any = null,
    path: any = null,
    username: any = null,
    password: any = null,
    data: any = null
  ) {
    let options: any = {
      method: method,
      url: this.ENV_CURRENT + path,
      json: true,
      auth: {
        username: username,
        password: password,
      },
    };

    if (data) {
      options.data = data;
    }

    return this._sendRequest(options);
  }

  /**
   * Sends a bearer authenticated HTTP request.
   *
   * @param method
   * @param path
   * @param token
   * @param data
   */
  static _bearerAuthRequest(
    method: any = null,
    path: any = null,
    data: any = null
  ) {
    let options: any = {
      method: method,
      url: this.ENV_CURRENT + path,
      json: true,
      headers: { Authorization: `Bearer ${this.getAuthToken()}` },
    };

    if (data) {
      options.data = data;
    }

    return this._sendRequest(options);
  }

  static getStores() {
    return this._unauthenticatedRequest("GET", "/public/stores");
  }

  static getEmailPreferences(payload: any = null) {
    return this._unauthenticatedRequest(
      "POST",
      "/public/communications/preferences",
      payload
    );
  }

  static manageEmailPreferences(payload: any = null) {
    return this._unauthenticatedRequest(
      "POST",
      "/public/communications/unsubscribe",
      payload
    );
  }

  static getStoresForZip(zip: any = null, withGeolocation = false) {
    const url =
      "/public/stores?zip=" +
      zip +
      "&withGeolocation=" +
      (withGeolocation ? "true" : "false");

    return this._unauthenticatedRequest("GET", url);
  }

  static getStoreByID(id: any = null) {
    return this._unauthenticatedRequest("GET", "/public/stores/" + id);
  }

  static getMealByID(id: any = null) {
    return this._unauthenticatedRequest("GET", "/public/meals/" + id);
  }

  static getCouponByCode(id: any = null) {
    return this._unauthenticatedRequest("GET", "/public/discounts/" + id);
  }

  static getCartState(id: any = null) {
    return this._unauthenticatedRequest("GET", "/customer-account/lead/" + id);
  }

  static authenticateCustomer(email: any = "") {
    return this._unauthenticatedRequest(
      "GET",
      "/customer-account/authenticate?email=" + email
    );
  }

  static authenticateCustomerWithPassword(email: any = "", password: any = "") {
    return this._basicAuthRequest(
      "GET",
      "/customer-account/authenticate/password",
      email,
      password
    );
  }

  static sendForgotPasswordVerification(method: any = null, value: any = null) {
    let payload: any = {
      method,
    };

    if (method == "email") {
      payload.email = value;
    }

    if (method == "phone") {
      payload.phone = value;
    }

    return this._unauthenticatedRequest(
      "PUT",
      "/customer-account/authenticate/password/forgot",
      payload
    );
  }

  static verifyForgotPassword(token: any = null, code: any = null) {
    let payload: any = {
      token,
      code,
    };

    return this._unauthenticatedRequest(
      "PUT",
      "/customer-account/authenticate/password/forgot/verify",
      payload
    );
  }

  static setPassword(token: any = null, password: any = null, code = null) {
    let payload: any = {
      token,
      password,
      code,
    };

    return this._unauthenticatedRequest(
      "PUT",
      "/customer-account/authenticate/password/reset",
      payload
    );
  }

  static deleteOnlineAccount() {
    return this._bearerAuthRequest(
      "DELETE",
      "/customer-account/delete-online-account",
      {}
    );
  }

  static changePassword(currentPassword: any = null, password: any = null) {
    let payload: any = {
      current: currentPassword,
      password,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/customer-account/authenticate/password/change",
      payload
    );
  }

  static setPasswordNewAccount(email: any = null, password: any = null) {
    let payload: any = {
      email,
      password,
    };

    return this._unauthenticatedRequest(
      "POST",
      "/customer-account/authenticate/password",
      payload
    );
  }

  // TODO: reimplement
  static async getCustomerAccount(email: any = "") {
    if (Capacitor.isNativePlatform()) {
      let device = await Device.getInfo();
      let app = await App.getInfo();
      let deviceID = await Device.getId();

      let notifications = await PushNotifications.checkPermissions();

      let appInfo: any = {
        appVersion: app.version,
        appBuild: app.build,
        deviceModel: device.model,
        devicePlatform: device.platform,
        deviceOperatingSystem: device.operatingSystem,
        deviceOSVersion: device.osVersion,
        deviceAndroidSDKVersion: device.androidSDKVersion,
        deviceManufacturer: device.manufacturer,
        deviceWebviewVersion: device.webViewVersion,
        deviceID: deviceID.identifier,
        pushNotifications: notifications?.receive == "granted",
      };

      let url = `/customer-account/account?appInfo=${JSON.stringify(appInfo)}`;

      return this._bearerAuthRequest("GET", url);
    } else {
      return this._bearerAuthRequest("GET", "/customer-account/account");
    }
  }

  // TODO: reimplement
  static pauseCustomerSubscription(
    email = "",
    interval = null,
    afterCutoff = false
  ) {
    let payload: any = {
      email: email,
      after_cutoff: afterCutoff,
    };

    return this._unauthenticatedRequest(
      "PUT",
      "/customer-account/pause",
      payload
    );
  }

  static unskipCustomerSubscription() {
    return this._bearerAuthRequest("PUT", "/customer-account/unskip");
  }

  // TODO: reimplement
  static holdCustomerSubscription(email: any = "", isAfterCutoff = false) {
    let payload: any = {
      email: email,
      after_cutoff: isAfterCutoff,
    };

    return this._unauthenticatedRequest(
      "PUT",
      "/customer-accounts/hold",
      payload
    );
  }

  // TODO: reimplement
  static activateCustomerSubscription() {
    return this._bearerAuthRequest("PUT", "/customer-account/activate/v2", {});
  }

  // TODO: reimplement
  static updateMealsForCustomer(email = "", meals = []) {
    let payload: any = {
      email: email,
      meals: meals,
    };

    return this._unauthenticatedRequest(
      "POST",
      "/customer-account/meals",
      payload
    );
  }

  static fetchUserNotifications(page: number, limit: number = 10) {
    return this._bearerAuthRequest(
      "GET",
      `/customer-account/notifications?page=${page ? page : 1}&limit=${
        limit > 0 ? limit : 10
      }&orderby=${JSON.stringify({ timestamp: -1 })}`
    );
  }

  static setNotificationsAsRead(ids: Array<string> = []) {
    return this._bearerAuthRequest(
      "PUT",
      `/customer-account/notifications/read`,
      {
        notificationIDs: ids,
      }
    );
  }

  static fetchUserOrders(filters = {}, page: number, limit: number = 10) {
    return this._bearerAuthRequest(
      "GET",
      `/customer-account/orders?filters=${JSON.stringify(filters)}&page=${
        page ? page : 1
      }&count=${limit > 0 ? limit : 10}&orderby=${JSON.stringify({
        fulfillmentDate: -1,
      })}`
    );
  }

  static resendOrderReceipt(id = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/customer-account/orders/${id}/resend-receipt`
    );
  }

  /**
   * Captures meal feedback
   *
   * @param mealID
   * @param rating
   * @param heatingMethods
   * @param comments
   * @returns
   */
  static submitMealFeedback(
    mealID: string,
    rating = "",
    heatingMethods: any = [],
    comments = "",
    customerID = "",
    storeID = ""
  ) {
    let payload: any = {
      rating,
      heatingMethods,
      comments,
      mealID,
      customerID,
      storeID,
    };

    return this._unauthenticatedRequest(
      "POST",
      "/customer-account/meals/feedback",
      payload
    );
  }

  // TODO: reimplement
  static checkout(
    leadID: any = null,
    plan: any = null,
    meals: any = null,
    authData: any = null,
    billingData: any = null,
    shippingData: any = null,
    coupon: any = null,
    referredBy: any = null,
    marketingOptIn = false,
    firstBillDate = "",
    chooseLater = false
  ) {
    let params: any = {
      leadID,
      plan: plan,
      meals: meals,

      billing: billingData,
      shipping: shippingData ? shippingData : null,
      coupon: coupon ? coupon : null,
      referredBy: referredBy,
      marketingOptIn: marketingOptIn ? true : false,
      chooseLater: chooseLater ? true : false,
    };

    if (!authData?.provider || authData?.provider != "stripe") {
      params.paymentAuth = authData;
      params.paymentProvider = "auth.net";
    } else {
      params.paymentProvider = "stripe";
      params.stripeCustomerID = authData.stripeCustomerID;
      params.stripePaymentMethod = authData.stripePaymentMethod;
    }

    if (firstBillDate) {
      params.firstBillDate = firstBillDate;
    }

    return this._unauthenticatedRequest(
      "POST",
      "/customer-account/checkout?v=2",
      params
    );
  }

  // TODO: reimplement
  static reactivate(
    plan: any = null,
    meals: any = null,
    authData: any = null,
    billingData: any = null,
    shippingData: any = null,
    coupon: any = null
  ) {
    let params = {
      plan: plan,
      meals: meals,
      payment_auth: authData,
      billing: billingData,
      shipping: shippingData ? shippingData : null,
      coupon: coupon ? coupon : null,
    };

    return this._unauthenticatedRequest(
      "POST",
      "/customer-accounts/reactivate",
      params
    );
  }

  /**
   * Updates the payment method for a user.
   *
   * @param {*} data
   * @returns
   */
  static updatePaymentMethod(data: any = null) {
    let payload: any = {
      paymentAuth: data,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/customer-account/account/payment",
      payload
    );
  }

  /**
   * Updates the payment method for a user.
   *
   * @param {*} data
   * @returns
   */
  static changeCustomerPlan(planID: any = null) {
    let payload: any = {
      planID,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/customer-account/account/plan",
      payload
    );
  }

  /**
   * Sets a key value paramneter for a user
   * @param key
   * @param value
   * @returns
   */
  static setUserParameter(key: string, value: any = null) {
    let payload: any = {
      key,
      value,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/customer-account/account/parameters",
      payload
    );
  }

  /**
   * Updates a user's billing address
   *
   * @param {*} name
   * @param {*} address_1
   * @param {*} address_2
   * @param {*} city
   * @param {*} state
   * @param {*} zip
   * @param {*} phone
   * @returns
   */
  static updateBillingAddress(
    name: any = null,
    address_1: any = null,
    address_2: any = null,
    city: any = null,
    state: any = null,
    zip: any = null,
    phone: any = null
  ) {
    let payload: any = {
      name,
      address_1,
      address_2,
      city,
      state,
      zip,
      phone,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/customer-account/account/address/billing",
      payload
    );
  }

  /**
   * Updates a user's shipping address
   *
   * @param {*} name
   * @param {*} address_1
   * @param {*} address_2
   * @param {*} city
   * @param {*} state
   * @param {*} zip
   * @param {*} phone
   * @returns
   */
  static updateShippingAddress(
    name: any = null,
    address_1: any = null,
    address_2: any = null,
    city: any = null,
    state: any = null,
    zip: any = null,
    phone: any = null
  ) {
    let payload: any = {
      name,
      address_1: address_1,
      address_2,
      city,
      state,
      zip,
      phone,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/customer-account/account/address/shipping",
      payload
    );
  }

  static updateContactInformation(
    name: any = null,
    email: any = null,
    phone: any = null
  ) {
    let payload: any = {
      name,
      email,
      phone,
    };

    //   console.log(payload)

    return this._bearerAuthRequest(
      "PUT",
      "/customer-account/account/contact",
      payload
    );
  }

  static logSkipFeedback(nextBillDate = "", reason = "") {
    return this._bearerAuthRequest("POST", "/customer-account/pause/feedback", {
      nextBillDate,
      reason,
    });
  }

  static signInWithGoogleLeads(data: any = null) {
    return this._bearerAuthRequest(
      "POST",
      "/public/webhooks/google/oauth/leads/redirect",
      data
    );
  }

  static signInWithAppleLeads(data: any = null, nonce = "", clientId = "") {
    return this._bearerAuthRequest(
      "POST",
      `/public/webhooks/apple/oauth/leads/redirect?nonce=${nonce}&clientID=${clientId}`,
      data
    );
  }

  static getStripeCheckoutIntent(email: any) {
    return this._unauthenticatedRequest(
      "POST",
      "/customer-account/checkout/stripe/payment-intent",
      {
        email,
      }
    );
  }

  /**
   * Reverse geocodes a lat long into an address
   *
   * @param lat
   * @param lng
   * @returns
   */
  static reverseGeocodeLatLng(lat: any = null, lng: any = null) {
    return this._unauthenticatedRequest(
      "GET",
      `/public/reverse-geocode?lat=${lat}&lng=${lng}&withStores=true`,
      null
    );
  }

  static applePayMerchantVerification(url: any = "") {
    return this._unauthenticatedRequest(
      "POST",
      `/public/webhooks/apple/merchant-verification`,
      {
        validationURL: url,
      },
      false
    );
  }

  static applePayTest(opaqueData: any = null) {
    return this._unauthenticatedRequest(
      "POST",
      `/public/webhooks/apple/checkout-test`,
      {
        opaqueData,
      },
      false
    );
  }

  /**
   * Reverse geocodes a lat long into an address
   *
   * @param lat
   * @param lng
   * @returns
   */
  static getPLNPlusPasskitPass(customerID: String) {
    return axios({
      method: "GET",
      url: `${this.ENV_CURRENT}/public/passkit/${customerID}`,
      responseType: "blob",
    });
  }
}
