/**
 * Event constants for the app.
 */

class Event {
  static ENV_CHANGED = "apiEnvChanged";
  static USER_LOADED = "userLoaded";
  static STRIPE_CLIENT_LOADED = "stripeClientLoaded";

  static INAPP_WISEPOP = "wisepop";

  static IN_APP_NOTIFICATION = "inAppNotification";

  static WINDOW_RESIZE = "windowResized";

  static MODAL = {
    SCHEDULING_MODAL: "schedulingModal",
    RESCHEDULING_MODAL: "reschedulingModal",
  };

  static CHECKOUT = {
    COUPON_UPDATED: "checkoutCouponUpdated",
    LEAD_RESTORED: "checkoutLeadRestored",
    STORE_UPDATED: "checkoutStoreUpdated",
    PLAN_UPDATED: "checkoutPlanUpdated",
  };

  static BILLING_ACCOUNT = {
    DELETED: "billingAccountDeleted",
    CREATED: "billingAccountCreated",
    MODIFIED: "billingAccountModified",
    CREATE_OPEN: "openCreateBillingAccountModal",
  };

  static MEAL = {
    DELETED: "mealDeleted",
    CREATED: "mealCreated",
    MODIFIED: "mealModified",
    CREATE_OPEN: "openMealCreateModal",
    OPEN_INFO: "openMealInfo",
  };

  static STORE = {
    DELETED: "storeDeleted",
    CREATED: "storeCreated",
    MODIFIED: "storeModified",
    CREATE_OPEN: "openCreateStoreModal",
    MODIFY_OPEN: "openModifyStoreModal",
    DELETE_OPEN: "openDeleteStoreModal",
    MODIFY_BILLING_ACCOUNT_OPEN: "openModifyStoreBillingAccountModal",
  };

  static PLAN = {
    CREATE_OPEN: "openCreatePlanModal",
  };

  static PRODUCT_GROUP = {
    DELETED: "productGroupDeleted",
    CREATED: "productGroupCreated",
    MODIFIED: "productGroupModified",
    CREATE_OPEN: "openProductGroupModal",
  };

  static NAVBAR = {
    DISCOUNT_BANNER_CLOSED: "checkoutNavbarDiscountBannerClosed",
  };
}

export default Event;
