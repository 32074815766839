/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Event from "../lib/Event";
//import FirebaseUtils from "lib/FirebaseUtils";
import PubSub from "../lib/PubSub";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import _ from "underscore";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonIcon,
} from "@ionic/react";

import glutenFree from "../assets/img/icons/gluten_free.svg";
import { add, remove } from "ionicons/icons";
import StringUtils from "../lib/StringUtils";
import SegmentUtils from "../lib/SegmentUtils";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Capacitor } from "@capacitor/core";

class MealOptionCardNoSelection extends React.Component<{
  mobile?: boolean;
  meal?: any;
  selected?: any;
  disableAdd?: boolean;
  incrementMeal?: any;
  compact?: boolean;
  checkoutMode?: boolean;
  modalID?: string;
  position?: number;
}> {
  state: any = {};

  openMealInfo(meal: any) {
    if (this.props.checkoutMode) {
      return;
    }

    // Haptic feedback
    if (Capacitor.isNativePlatform()) {
      Haptics.impact({ style: ImpactStyle.Medium });
    }

    let props: any = {
      brand: "Project LeanNation",
      category: meal?.planType?.name + " Meals",
      image_url: meal?.imageURL,
      name: meal?.name,
      product_id: meal?._id,
      url: meal?.deeplinkURL,
    };

    if (this.props.position) {
      props.position = this.props.position;
    }

    SegmentUtils.track("Product Clicked", props);

    PubSub.publish(Event.MEAL.OPEN_INFO, { meal, id: this.props.modalID });
  }

  renderTopCategory(meal: any) {
    let top: any = "";
    let priority = 0;

    if (!meal?.categories?.length) {
      top = (
        <div
          color="info"
          className="px-sm-2 px-1 py-1 bg-white text-uppercase text-dark font-weight-bold"
          style={{
            fontSize: this.props.mobile ? 11 : "12px",
            borderRadius: "3px",
            border: "1px solid var(--light)",
          }}
        >
          Balanced
        </div>
      );

      return top;
    }

    for (let i = 0; i < meal.categories.length; i++) {
      let cat = meal.categories[i];

      if (cat.name == "Lean Cheats" && priority <= 100) {
        top = (
          <div
            color="danger"
            className="px-sm-2 px-1 py-1 bg-white text-dark text-uppercase font-weight-bold"
            style={{
              fontSize: this.props.mobile ? 11 : "12px",
              borderRadius: "3px",
              border: "1px solid var(--light)",
            }}
          >
            {cat.name}
          </div>
        );
        priority = 100;
      }

      if (cat.name == "Plant Based" && priority <= 90) {
        top = (
          <div
            color="success"
            className="px-sm-2 px-1 py-1 bg-white text-dark text-uppercase font-weight-bold"
            style={{
              fontSize: this.props.mobile ? 11 : "12px",
              borderRadius: "3px",
              border: "1px solid var(--light)",
            }}
          >
            {cat.name}
          </div>
        );
        priority = 90;
      }

      if (cat.name == "Grain Free" && priority <= 80) {
        top = (
          <div
            color="warning"
            className="px-sm-2 px-1 py-1 bg-white text-uppercase text-dark font-weight-bold"
            style={{
              fontSize: this.props.mobile ? 11 : "12px",
              borderRadius: "3px",
              border: "1px solid var(--light)",
            }}
          >
            {cat.name}
          </div>
        );
        priority = 80;
      }

      if (cat.name == "Dairy Free" && priority <= 70) {
        top = (
          <div
            color="info"
            className="px-sm-2 px-1 py-1 bg-white text-uppercase text-dark font-weight-bold"
            style={{
              fontSize: this.props.mobile ? 11 : "12px",
              borderRadius: "3px",
              border: "1px solid var(--light)",
            }}
          >
            {cat.name}
          </div>
        );
        priority = 70;
      }
    }

    if (!top) {
      top = (
        <div
          color="info"
          className="px-sm-2 px-1 py-1 bg-white text-uppercase text-dark font-weight-bold"
          style={{
            fontSize: this.props.mobile ? 11 : "12px",
            borderRadius: "3px",
            border: "1px solid var(--light)",
          }}
        >
          Balanced
        </div>
      );
    }

    return top;
  }

  getSegmentProps() {
    let props: any = {
      brand: "Project LeanNation",
      category: this.props.meal?.planType?.name + " Meals",
      image_url: this.props.meal?.imageURL,
      name: this.props.meal?.name,
      product_id: this.props.meal?._id,
      url: this.props.meal?.deeplinkURL,
      quantity: 1,
    };

    if (this.props.position) {
      props.position = this.props.position;
    }

    return props;
  }

  isInSelected(meal: any) {
    let i = _.findWhere(this.state.selectedMeals, { _id: meal._id });

    if (i) {
      return true;
    }

    return false;
  }

  stripHTML(html: any) {
    return html.replace(/(<([^>]+)>)/gi, "");
  }

  convertToPlain(html: any) {
    return StringUtils.htmlToText(html);
  }

  render() {
    return (
      <>
        {this.props.mobile ? (
          <>
            <IonCard
              mode="ios"
              className="m-0 meal-info-card"
              style={{
                borderRadius: 0,
                boxShadow: "none",
              }}
            >
              <IonCardContent
                className="p-0 d-flex bg-white rounded-lg border-[1px] border-gray-100"
                style={{}}
              >
                <div
                  onClick={() => this.openMealInfo(this.props.meal)}
                  className={`border-right ${
                    !this.props.checkoutMode && "cursor-pointer"
                  }`}
                  style={{
                    height: "auto",
                    width: this.props.checkoutMode ? "80px" : "95px",
                    background: "var(--primary-lighter)",
                    position: "relative",
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url(${
                        this.props.meal.platedImageURL
                          ? this.props.meal.platedImageURL
                          : this.props.meal.imageURL
                      })`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: this.props.meal?.platedImageURL
                        ? "160%"
                        : "230%",
                      position: "absolute",

                      left: 0,
                      top: 0,
                    }}
                  ></div>
                  <div
                    style={{
                      position: "absolute",
                      right: "0.2rem",
                      bottom: "0.25rem",
                      background: "var(--primary-darker)",
                      borderRadius: "100%",
                      width: 20,
                      height: 20,
                    }}
                    className="text-right "
                  >
                    <img
                      src={glutenFree}
                      style={{
                        width: "20px",
                        height: 20,
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    ></img>
                  </div>
                </div>
                <div
                  onClick={() => this.openMealInfo(this.props.meal)}
                  className={`p-2 ${
                    !this.props.checkoutMode && "cursor-pointer"
                  }`}
                  style={{
                    width: `calc(100% - ${
                      this.props.checkoutMode ? "80px" : "60px"
                    } - ${this.props.checkoutMode ? "0px" : "45px"})`,
                  }}
                >
                  <div className={`mb-1 ${this.props.compact && "d-none"}`}>
                    <IonGrid className="p-0">
                      <IonRow className="ion-ion-align-items-center">
                        <IonCol className="pl-0 py-0" size-xs="6">
                          <div className="d-inline-block">
                            {this.renderTopCategory(this.props.meal)}
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>

                  <div>
                    <h3
                      className={`${
                        !this.props.compact && "mt-2"
                      } mb-0 text-truncate text-dark font-weight-bold ${
                        this.props.checkoutMode &&
                        "d-flex ion-align-items-center"
                      }`}
                      style={{ fontSize: 16 }}
                    >
                      <div
                        style={{ flexGrow: this.props.checkoutMode ? "1" : "" }}
                        className={
                          this.props.checkoutMode ? "text-truncate" : ""
                        }
                      >
                        {this.props.meal.name}
                      </div>
                      {this.props.checkoutMode && (
                        <>
                          <div className="ml-1">
                            {this.props.selected > 0 ? (
                              <div
                                className="px-1 py-1 bg-dark text-white font-weight-bold border d-inline-block border-dark text-uppercase"
                                style={{
                                  fontSize: this.props.checkoutMode ? 10 : 11,
                                  lineHeight: 1,
                                  borderRadius: 3,
                                }}
                              >
                                {this.props.selected} In Box
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                    </h3>
                    <div className={`meal-info-description mt-1`}>
                      {this.convertToPlain(this.props.meal.description)}
                    </div>
                    <div className={`mt-1 ${this.props.compact && "d-none"}`}>
                      <IonRow className="">
                        <IonCol size-xs="auto" className="pr-3">
                          <p
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                            style={{ fontSize: 13 }}
                          >
                            {this.props.meal?.nutrition?.calories}
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px", lineHeight: 1.2 }}
                          >
                            Cal
                          </p>
                        </IonCol>
                        <IonCol size-xs="auto" className="pr-3 pl-0">
                          <p
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                            style={{ fontSize: 13 }}
                          >
                            {this.props.meal?.nutrition?.protein}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px", lineHeight: 1.2 }}
                          >
                            Protein
                          </p>
                        </IonCol>
                        <IonCol size-xs="auto" className="pr-3 pl-0">
                          <p
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                            style={{ fontSize: 13 }}
                          >
                            {this.props.meal?.nutrition?.carbs}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px", lineHeight: 1.2 }}
                          >
                            Carbs
                          </p>
                        </IonCol>
                        <IonCol size-xs="auto" className="pr-3 pl-0">
                          <p
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                            style={{ fontSize: 13 }}
                          >
                            {this.props.meal?.nutrition?.fat}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px", lineHeight: 1.2 }}
                          >
                            Fats
                          </p>
                        </IonCol>
                      </IonRow>
                    </div>
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </>
        ) : (
          <>
            <IonCard
              mode="ios"
              className="shadow m-0 border-[1px] border-gray-100"
            >
              <IonCardContent
                className={`text-center p-0 ${
                  !this.props.checkoutMode && "cursor-pointer"
                }`}
              >
                <div
                  style={{
                    flexGrow: 1,
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    position: "relative",
                    background: "var(--primary-superlight)",
                  }}
                  className="p-0 border-bottom"
                  onClick={() => this.openMealInfo(this.props.meal)}
                >
                  <div
                    style={{
                      position: "absolute",
                      right: "0.5rem",
                      bottom: "0.25rem",
                      background: "var(--primary-darker)",
                      height: 24,
                      width: 24,
                      borderRadius: "100%",
                    }}
                    className="text-right"
                  >
                    <img src={glutenFree} style={{ width: "24px" }}></img>
                  </div>
                  <div
                    className="meal-info-image"
                    style={{
                      width: "100%",
                      backgroundImage: `url(${
                        this.props.meal.platedImageURL
                          ? this.props.meal.platedImageURL
                          : this.props.meal.imageURL
                      })`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div
                    className=""
                    style={{
                      position: "absolute",
                      left: "0.5rem",
                      bottom: "0.5rem",
                    }}
                  >
                    {this.renderTopCategory(this.props.meal)}
                  </div>
                </div>
              </IonCardContent>
              <IonCardContent
                className={`px-2 pb-0 pt-2 ${
                  !this.props.checkoutMode && "cursor-pointer"
                }`}
                onClick={() => this.openMealInfo(this.props.meal)}
              >
                <IonRow>
                  <IonCol size-xs="12" className="text-left">
                    <div
                      className={`meal-info-container  ${
                        !this.props.checkoutMode && "cursor-pointer"
                      }`}
                    >
                      <h2
                        className="mb-1 meal-info-title text-dark font-weight-bold text-center"
                        style={{ fontSize: 18 }}
                      >
                        {this.props.meal.name}
                      </h2>
                      <div className="meal-info-description text-center">
                        {this.convertToPlain(this.props.meal.description)}
                      </div>

                      {/*<p style={{fontSize: '14px', lineHeight: 1.25}} className="mb-2 text-dark">example description summary goes here with some more words</p>*/}
                    </div>
                  </IonCol>
                </IonRow>
              </IonCardContent>
              <IonCardContent className="px-2 pt-0 pb-2">
                <IonRow className="ion-justify-content-center ion-align-items-center">
                  <IonCol size-xs="12" size-xl="auto" className="py-xl-0">
                    <div
                      className={`${
                        !this.props.checkoutMode && "cursor-pointer"
                      }`}
                      onClick={() => this.openMealInfo(this.props.meal)}
                    >
                      <IonRow className="ion-justify-content-center ion-justify-content-xl-left">
                        <IonCol
                          size-xs="auto"
                          className="py-0 pl-0 pr-3 text-center text-xl-left"
                        >
                          <p
                            style={{ fontSize: 16 }}
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                          >
                            {this.props.meal.nutrition.calories}
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px" }}
                          >
                            Cal
                          </p>
                        </IonCol>
                        <IonCol
                          size-xs="auto"
                          className="py-0 pl-0 pr-3 text-center text-xl-left"
                        >
                          <p
                            style={{ fontSize: 16 }}
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                          >
                            {this.props.meal.nutrition.protein}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px" }}
                          >
                            Protein
                          </p>
                        </IonCol>
                        <IonCol
                          size-xs="auto"
                          className="border-right py-0 pl-0 pr-3 text-center text-xl-left"
                        >
                          <p
                            style={{ fontSize: 16 }}
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                          >
                            {this.props.meal.nutrition.carbs}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px" }}
                          >
                            Carbs
                          </p>
                        </IonCol>
                        <IonCol
                          size-xs="auto"
                          className="pl-0 pr-0 py-0 text-center text-xl-left"
                        >
                          <p
                            style={{ fontSize: 16 }}
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                          >
                            {this.props.meal.nutrition.fat}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px" }}
                          >
                            Fats
                          </p>
                        </IonCol>
                      </IonRow>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </>
        )}
      </>
    );
  }
}

export default MealOptionCardNoSelection;
