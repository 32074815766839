import StringUtils from "./StringUtils";

export default class NumberUtils {
  static getCouponPromotionalPrice(plan: any, coupon: any) {
    const unitPrice =
      plan?.unitPrice + (plan?.deliveryFee ? plan?.deliveryFee : 0);

    let out = unitPrice;

    if (coupon?.couponUsage == "tiered" && coupon?.couponTiers?.length) {
      let couponTier = coupon.couponTiers[0];

      if (
        couponTier?.discountType &&
        (couponTier.flatDiscount || couponTier.percentDiscount)
      ) {
        if (couponTier?.discountType == "Flat") {
          out = unitPrice - couponTier.flatDiscount;
        }

        if (couponTier?.discountType == "Percent") {
          out = unitPrice * (1 - couponTier.percentDiscount);
        }
      }
    } else {
      if (
        coupon?.discountType &&
        (coupon.flatDiscount || coupon.percentDiscount)
      ) {
        if (coupon.discountType == "Flat") {
          out = unitPrice - coupon.flatDiscount;
        }

        if (coupon.discountType == "Percent") {
          out = unitPrice * (1 - coupon.percentDiscount);
        }
      }
    }

    let payload = {
      planPrice: StringUtils.centsToCurrency(out),
      mealPrice: StringUtils.centsToCurrency(out / plan?.quantity),
    };

    return payload;
  }
}
