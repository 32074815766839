import {
  IonContent,
  IonHeader,
  IonPage,
  IonSkeletonText,
  IonTabBar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import UpcomingOrderCardSkeleton from "../AccountManagement/UpcomingOrderCard/UpcomingOrderCardSkeleton";
import { Capacitor } from "@capacitor/core";
import ReviewOrderCardSkeleton from "../AccountManagement/ReviewOrderCard/ReviewOrderCardSkeleton";

const MobileSkeletonLoader: React.FC = () => {
  const mode = Capacitor.getPlatform() == "android" ? "md" : "ios";

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="pln-native-toolbar">
          <IonTitle>Home</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ maxWidth: 1200, margin: "auto" }} className="ion-padding">
          <div className="mb-4 mt-4 pb-2">
            <IonSkeletonText
              style={{
                height: 24,
                width: "80%",
                maxWidth: 220,

                borderRadius: 6,
              }}
              animated={true}
            ></IonSkeletonText>
            <IonSkeletonText
              style={{
                height: 12,
                width: "50%",
                maxWidth: 160,
                marginTop: 12,
                borderRadius: 6,
              }}
              animated={true}
            ></IonSkeletonText>
            <div className="py-1"></div>
            <IonSkeletonText
              style={{
                height: 22,
                width: "50%",
                maxWidth: 100,
                borderRadius: 6,
              }}
              className="mt-4 mb-0"
              animated={true}
            ></IonSkeletonText>
          </div>
          <div className="mt--1">
            <UpcomingOrderCardSkeleton
              nativeMode={true}
            ></UpcomingOrderCardSkeleton>
            <ReviewOrderCardSkeleton
              nativeMode={true}
            ></ReviewOrderCardSkeleton>
          </div>
        </div>
      </IonContent>
      <IonTabBar slot="bottom" className="pln-native-tabbar"></IonTabBar>
    </IonPage>
  );
};

export default MobileSkeletonLoader;
