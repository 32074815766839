import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import API from "../../lib/API";

import sesameArtboard from "../../assets/img/backgrounds/sesame-artboard.jpg";
import porkArtboard from "../../assets/img/backgrounds/pork_scalloped.jpg";
import breakfastArtboard from "../../assets/img/backgrounds/breakfast.jpg";
import grainArtboard from "../../assets/img/backgrounds/grain_dairy.jpg";
import plantArtboard from "../../assets/img/backgrounds/plant.jpg";
import gfArtboard from "../../assets/img/backgrounds/gf.jpg";
import leanArtboard from "../../assets/img/backgrounds/pbmm.jpg";
import proteinArtboard from "../../assets/img/backgrounds/protein.jpg";

import sesameArtboardWebp from "../../assets/img/backgrounds/sesame-artboard.webp";
import porkArtboardWebp from "../../assets/img/backgrounds/pork_scalloped.webp";
import breakfastArtboardWebp from "../../assets/img/backgrounds/breakfast.webp";
import grainArtboardWebp from "../../assets/img/backgrounds/grain_dairy.webp";
import plantArtboardWebp from "../../assets/img/backgrounds/plant.webp";
import gfArtboardWebp from "../../assets/img/backgrounds/gf.webp";
import leanArtboardWebp from "../../assets/img/backgrounds/pbmm.webp";
import proteinArtboardWebp from "../../assets/img/backgrounds/protein.webp";
import WindowUtils from "../../lib/WindowUtils";

const MealTopicSlider: React.FC = () => {
  const topicSlider = useRef<any>(null);

  const webp = WindowUtils.supportFormatWebp();

  const topic = useMemo(() => {
    if (topicSlider?.current) {
      topicSlider.current.startAutoplay();
    }
  }, [topicSlider?.current]);

  return (
    <>
      <IonSlides
        style={{ height: 450 }}
        ref={topicSlider}
        pager={true}
        options={{
          speed: 1200,
          autoplay: {
            delay: 6000,
          },
          breakpoints: {
            // when window width is >= 320px

            // when window width is >= 480px
            540: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            // when window width is >= 640px
            1100: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          },
          grabCursor: true,

          loop: true,
        }}
        mode="ios"
      >
        <IonSlide>
          <div
            style={{
              width: 280,
              height: 320,
              borderRadius: 6,
              border: "1px solid var(--light)",
              background: `url(${
                webp ? sesameArtboardWebp : sesameArtboard
              }) center center / cover`,
              position: "relative",
              boxShadow: "0px 5px 8px 0px rgba(33,37,41,0.1)",
            }}
          >
            <div
              className="p-3 text-left"
              style={{ position: "absolute", bottom: 0 }}
            >
              <h1
                style={{ fontSize: 30 }}
                className="mb-1 mt-0 text-white font-weight-bold"
              >
                Lifestyle
              </h1>
              <p
                className="m-0 text-white font-weight-500"
                style={{ fontSize: 16 }}
              >
                Whole foods that are perfectly portioned to help you crush your
                health goals
              </p>
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div
            style={{
              width: 280,
              height: 320,
              borderRadius: 6,
              border: "1px solid var(--light)",
              background: `url(${
                webp ? porkArtboardWebp : porkArtboard
              }) center center / cover`,
              position: "relative",
              boxShadow: "0px 5px 8px 0px rgba(33,37,41,0.1)",
            }}
          >
            <div
              className="p-3 text-left"
              style={{ position: "absolute", bottom: 0 }}
            >
              <h1
                style={{ fontSize: 30 }}
                className=" mb-1 mt-0 text-white font-weight-bold"
              >
                Athlete
              </h1>
              <p
                className="m-0 text-white font-weight-500"
                style={{ fontSize: 16 }}
              >
                Providing you even more fuel and recovery from high-intensity
                workouts
              </p>
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div
            style={{
              width: 280,
              height: 320,
              borderRadius: 6,
              border: "1px solid var(--light)",
              background: `url(${
                webp ? breakfastArtboardWebp : breakfastArtboard
              }) center center / cover`,
              position: "relative",
              boxShadow: "0px 5px 8px 0px rgba(33,37,41,0.1)",
            }}
          >
            <div
              className="p-3 text-left"
              style={{ position: "absolute", bottom: 0 }}
            >
              <h1
                style={{ fontSize: 30 }}
                className="mb-1 mt-0 text-white font-weight-bold"
              >
                Breakfast
              </h1>
              <p
                className="m-0 text-white font-weight-500"
                style={{ fontSize: 16 }}
              >
                Delicious &amp; healthy options to jumpstart your day the right
                way.
              </p>
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div
            style={{
              width: 280,
              height: 320,
              borderRadius: 6,
              border: "1px solid var(--light)",
              background: `url(${
                webp ? grainArtboardWebp : grainArtboard
              }) center center / cover`,
              position: "relative",
              boxShadow: "0px 5px 8px 0px rgba(33,37,41,0.1)",
            }}
          >
            <div
              className="p-3 text-left"
              style={{ position: "absolute", bottom: 0 }}
            >
              <h1
                style={{ fontSize: 30, lineHeight: 1.1 }}
                className="mb-1 mt-0 text-white font-weight-bold"
              >
                Grain & <br></br>Dairy Free
              </h1>
              <p
                className="m-0 text-white font-weight-500"
                style={{ fontSize: 16 }}
              >
                Meals to meet all your dietary preferences &amp; desires.
              </p>
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div
            style={{
              width: 280,
              height: 320,
              borderRadius: 6,
              border: "1px solid var(--light)",
              background: `url(${
                webp ? plantArtboardWebp : plantArtboard
              }) center center / cover`,
              position: "relative",
              boxShadow: "0px 5px 8px 0px rgba(33,37,41,0.1)",
            }}
          >
            <div
              className="p-3 text-left"
              style={{ position: "absolute", bottom: 0 }}
            >
              <h1
                style={{ fontSize: 30, lineHeight: 1.1 }}
                className="mb-1 mt-0 text-white font-weight-bold"
              >
                Plant-Based
              </h1>
              <p
                className="m-0 text-white font-weight-500"
                style={{ fontSize: 16 }}
              >
                Protein-packed & delicious plant-based options available on all
                our menus.
              </p>
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div
            style={{
              width: 280,
              height: 320,
              borderRadius: 6,
              border: "1px solid var(--light)",
              background: `url(${
                webp ? gfArtboardWebp : gfArtboard
              }) center center / cover`,
              position: "relative",
              boxShadow: "0px 5px 8px 0px rgba(33,37,41,0.1)",
            }}
          >
            <div
              className="p-3 text-left"
              style={{ position: "absolute", bottom: 0 }}
            >
              <h1
                style={{ fontSize: 30, lineHeight: 1.1 }}
                className="mb-1 mt-0 text-white font-weight-bold"
              >
                Gluten Free
              </h1>
              <p
                className="m-0 text-white font-weight-500"
                style={{ fontSize: 16 }}
              >
                Rest easy knowing that our entire menu is gluten free &
                ready-to-heat!
              </p>
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div
            style={{
              width: 280,
              height: 320,
              borderRadius: 6,
              border: "1px solid var(--light)",
              background: `url(${
                webp ? leanArtboardWebp : leanArtboard
              }) center center / cover`,
              position: "relative",
              boxShadow: "0px 5px 8px 0px rgba(33,37,41,0.1)",
            }}
          >
            <div
              className="p-3 text-left"
              style={{ position: "absolute", bottom: 0 }}
            >
              <h1
                style={{ fontSize: 30, lineHeight: 1.1 }}
                className="mb-1 mt-0 text-white font-weight-bold"
              >
                Lean Cheats
              </h1>
              <p
                className="m-0 text-white font-weight-500"
                style={{ fontSize: 16 }}
              >
                The perfect protein-packed treats & sweets to keep you on track.
              </p>
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div
            style={{
              width: 280,
              height: 320,
              borderRadius: 6,
              border: "1px solid var(--light)",
              background: `url(${
                webp ? proteinArtboardWebp : proteinArtboard
              }) center center / cover`,
              position: "relative",
              boxShadow: "0px 5px 8px 0px rgba(33,37,41,0.15)",
            }}
          >
            <div
              className="p-3 text-left"
              style={{ position: "absolute", bottom: 0 }}
            >
              <h1
                style={{ fontSize: 30, lineHeight: 1.1 }}
                className="mb-1 mt-0 text-white font-weight-bold"
              >
                Shakes & Supplements
              </h1>
              <p
                className="m-0 text-white font-weight-500"
                style={{ fontSize: 16 }}
              >
                Mix in our In-Store protein shakes &amp; supplements.
              </p>
            </div>
          </div>
        </IonSlide>
      </IonSlides>
    </>
  );
};

export default MealTopicSlider;
