import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";

import "./SchedulingForm.css";

import moment from "moment-timezone";
import _ from "underscore";
import { chevronBack, chevronForward } from "ionicons/icons";
import SchedulingHourButton from "../SchedulingHourButton/SchedulingHourButton";
import API from "../../../lib/API";
import WindowUtils from "../../../lib/WindowUtils";
import Event from "../../../lib/Event";
import PubSub from "../../../lib/PubSub";
import StringUtils from "../../../lib/StringUtils";

const SchedulingForm: React.FC<{
  appointmentType?: any;
  onChange?: any;
  value?: any;
  fields?: any;
  title?: any;
  requiredFields?: any;
  bgWhite?: boolean;
  initialValue?: any;
}> = ({
  appointmentType = null,
  onChange = null,
  value = null,
  fields = [],
  requiredFields = [],
  title = "Enter Your Information Below",
  bgWhite = false,
  initialValue = null,
}) => {
  const [email, setEmail] = useState<any>("");
  const [notes, setNotes] = useState<any>("");
  const [name, setName] = useState<any>("");
  const [goal, setGoal] = useState<any>("");
  const [phone, setPhone] = useState("");
  const [phoneReadable, setPhoneReadable] = useState("");

  useEffect(() => {
    let vals: any = {
      phone: phone,
      email: email,
      notes: notes,
      name: name,
      goal: goal,
    };

    //console.log(vals); //debug

    if (typeof onChange == "function") {
      try {
        if (JSON.stringify(value) == JSON.stringify(vals)) {
          return;
        }
      } catch (e) {}

      onChange(vals);
    }
  }, [name, email, phone, goal, notes, onChange, value]);

  useEffect(() => {
    // console.log(initialValue);

    if (!initialValue) {
      return;
    }

    if (initialValue?.phone) {
      const res = StringUtils.formatPhoneNumberWhileTyping(initialValue?.phone);

      setPhone(initialValue?.phone);
      setPhoneReadable(res?.formatted);
    }

    if (initialValue?.email) {
      setEmail(initialValue?.email);
    }

    if (initialValue?.name) {
      setName(initialValue?.name);
    }

    if (initialValue?.goal) {
      setGoal(initialValue?.goal);
    }

    if (initialValue?.notes) {
      setNotes(initialValue?.notes);
    }
  }, [initialValue]);

  return (
    <>
      <div
        style={{
          borderRadius: 6,
          border: "1px solid var(--midlighter)",
        }}
        className={`p-3 ${bgWhite && "bg-white"}`}
      >
        <h4 className="text-dark mt-0 mb-3 text-center">{title}</h4>
        <IonGrid className="p-0">
          <IonRow>
            {fields?.includes("name") ? (
              <>
                <IonCol
                  size-xs="12"
                  className={`px-0 ${fields?.length > 1 && "mb-2"}`}
                >
                  <IonItem
                    color="primary"
                    className={`pln-input-web small`}
                    mode="md"
                    fill="outline"
                  >
                    <IonLabel position="floating">
                      Name{requiredFields?.includes("name") && "*"}
                    </IonLabel>
                    <IonInput
                      autocapitalize="none"
                      inputMode="text"
                      color="primary"
                      type="text"
                      name="name"
                      autocomplete="name"
                      value={name}
                      placeholder="Name"
                      required={true}
                      clearInput={true}
                      onIonChange={(event: any) => {
                        setName(event?.detail?.value);
                      }}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </>
            ) : null}
            {fields?.includes("email") ? (
              <>
                <IonCol
                  size-xs="12"
                  className={`px-0 ${fields?.length > 1 && "mb-2"}`}
                >
                  <IonItem
                    color="primary"
                    className={`pln-input-web small`}
                    mode="md"
                    fill="outline"
                  >
                    <IonLabel position="floating">
                      Email{requiredFields?.includes("email") && "*"}
                    </IonLabel>
                    <IonInput
                      autocapitalize="none"
                      inputMode="email"
                      color="primary"
                      type="email"
                      name="email"
                      autocomplete="email"
                      value={email}
                      placeholder="Email"
                      required={true}
                      clearInput={true}
                      onIonChange={(event: any) => {
                        setEmail(event?.detail?.value);
                      }}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </>
            ) : null}
            {fields?.includes("phone") ? (
              <>
                <IonCol
                  size-xs="12"
                  className={`px-0 ${fields?.length > 1 && "mb-2"}`}
                >
                  <IonItem
                    color="primary"
                    className={`pln-input-web small`}
                    mode="md"
                    fill="outline"
                  >
                    <IonLabel position="floating">
                      Phone{requiredFields?.includes("phone") && "*"}
                    </IonLabel>
                    <IonInput
                      autocapitalize="none"
                      name="phone"
                      inputMode="tel"
                      color="primary"
                      type="tel"
                      autocomplete="tel"
                      value={phoneReadable}
                      placeholder="Phone"
                      required={true}
                      clearInput={true}
                      onIonChange={(event: any) => {
                        let result = StringUtils.formatPhoneNumberWhileTyping(
                          event?.detail?.value
                        );

                        setPhone(result?.value);
                        setPhoneReadable(result?.formatted);
                      }}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </>
            ) : null}
            {fields?.includes("goal") ? (
              <>
                <IonCol
                  size-xs="12"
                  className={`px-0 ${fields?.length > 1 && "mb-2"}`}
                >
                  <p className="mt-0 mb-2 text-dark font-weight-bold">
                    What Can We Help You Achieve?
                    {requiredFields?.includes("goal") && "*"}
                  </p>
                  <IonItem
                    color="primary"
                    className={`pln-input-web small`}
                    mode="md"
                    fill="outline"
                  >
                    <IonLabel>
                      Goal{requiredFields?.includes("goal") && "*"}
                    </IonLabel>
                    <IonSelect
                      interface="action-sheet"
                      color="primary"
                      value={goal}
                      placeholder="Goal"
                      mode="ios"
                      name="goal"
                      onIonChange={(event: any) => {
                        setGoal(event?.detail?.value);
                      }}
                    >
                      <IonSelectOption value="Weight Management">
                        Weight Management
                      </IonSelectOption>
                      <IonSelectOption value="Muscle Gain">
                        Muscle Gain
                      </IonSelectOption>
                      <IonSelectOption value="Convenience">
                        Convenience
                      </IonSelectOption>
                      <IonSelectOption value="Optimize Health">
                        Optimize Health
                      </IonSelectOption>
                      <IonSelectOption value="Referred by Physician">
                        Referred by Physician
                      </IonSelectOption>
                      <IonSelectOption value="Other Goals">
                        Other Goals
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </>
            ) : null}
            {fields?.includes("notes") ? (
              <>
                <IonCol size-xs="12" className={`px-0`}>
                  <p className="mt-0 mb-2 text-dark text-capitalize font-weight-bold">
                    Anything else you'd like to share?
                    {requiredFields?.includes("notes") && "*"}
                  </p>
                  <IonItem
                    color="primary"
                    className={`pln-input-web textarea small`}
                    mode="md"
                    fill="outline"
                  >
                    <IonLabel position="floating">
                      Comments{requiredFields?.includes("notes") && "*"}
                    </IonLabel>
                    <IonTextarea
                      autocapitalize="none"
                      inputMode="text"
                      color="primary"
                      name="notes"
                      value={notes}
                      placeholder="Comments"
                      required={true}
                      rows={3}
                      onIonChange={(event: any) => {
                        setNotes(event?.detail?.value);
                      }}
                    ></IonTextarea>
                  </IonItem>
                </IonCol>
              </>
            ) : null}
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default SchedulingForm;
