import { IonButton, IonContent, IonPage, IonIcon } from "@ionic/react";
import { useHistory, useParams } from "react-router";
import "./Page404.css";

import React from "react";
import Footer from "../../../../components/Checkout/Footer";
import Navbar from "../../../../components/WebApp/Navbar/Navbar";
import { chevronForward } from "ionicons/icons";
import SEOHeaders from "../../../../components/SEOHeaders";

const Page404: React.FC = () => {
  const history = useHistory();

  return (
    <IonPage>
      <SEOHeaders title="404"></SEOHeaders>
      <Navbar
        showBackButton={false}
        showMenuButton={false}
        pageName={""}
      ></Navbar>
      <IonContent>
        <div
          style={{
            height: "calc(100vh - 140px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="text-center p-4">
            <h1 className="font-weight-bold">
              Oops! We couldn't find this page.
            </h1>
            <p className="lead-sm">
              Well this is a little bit embarrassing... We couldn't find the
              page you're looking for. In the meantime, let's get you back home.
            </p>
            <IonButton
              className="pln-button-web mt-4"
              onClick={() => {
                window.open("/", "_self");
              }}
              color="primary"
              mode="ios"
            >
              Return Home <IonIcon slot="end" icon={chevronForward}></IonIcon>
            </IonButton>
          </div>
        </div>
        <Footer></Footer>
      </IonContent>
    </IonPage>
  );
};

export default Page404;
