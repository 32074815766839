import { Redirect, Route, useHistory, useParams } from "react-router-dom";
import {
  IonApp,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  setupIonicReact,
  useIonLoading,
} from "@ionic/react";
import React from "react";
import { IonReactRouter } from "@ionic/react-router";
import { ellipse, square, triangle } from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "../../theme/variables.css";
import "../../theme/pln/splitpane.css";
import Menu from "../../components/Menu";
import Start from "../../pages/web/checkout/Start/Start";
import Plans from "../../pages/web/checkout/Plans/Plans";
import Stores from "../../pages/web/checkout/Stores/Stores";
import Box from "../../pages/web/checkout/Box/Box";
import Checkout from "../../pages/web/checkout/Checkout/Checkout";
import { useEffect, useMemo, useState } from "react";
import API from "../../lib/API";
import StorageUtils from "../../lib/StorageUtils";
import PubSub from "../../lib/PubSub";
import Event from "../../lib/Event";
import CheckoutComplete from "../../pages/web/checkout/CheckoutComplete/CheckoutComplete";
import Availability from "../../pages/web/scheduler/Availability/Availability";
import Details from "../../pages/web/scheduler/Details/Details";
import Confirmation from "../../pages/web/scheduler/Confirmation/Confirmation";
import ToastProvider from "../../providers/ToastProvider/ToastProvider";
import Page404 from "../../pages/web/authentication/404/Page404";
import LocationsV2 from "../../pages/web/scheduler/Locations/LocationsV2";

setupIonicReact();

const SchedulerRedirect: React.FC = () => {
  const { storeID } = useParams<{ storeID: string }>();
  return (
    <>
      <Redirect to={`/schedule/${storeID}/nc-consult`} />
    </>
  );
};

const SchedulerRouting: React.FC<any> = (props) => {
  const { storeID } = useParams<{ storeID: string }>();

  return (
    <>
      {storeID == "appointments" ? (
        <Confirmation {...props}></Confirmation>
      ) : (
        <Availability {...props}></Availability>
      )}
    </>
  );
};

const WebScheduler: React.FC = () => {
  console.log("WEB APP");

  const [leadLoading, setLeadLoading] = useState(false);

  const history = useHistory();

  useMemo(() => {
    if (leadLoading) {
      return;
    }

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    let leadID = urlParams.get("leadID");
    let customerID = urlParams.get("customerID");
    let name = urlParams.get("name");
    let email = urlParams.get("email");
    let phone = urlParams.get("phone");

    if (leadID) {
      StorageUtils.set("scheduler_leadID", leadID);
    }

    if (customerID) {
      console.log("IN URL", customerID);

      StorageUtils.set("scheduler_customerID", customerID);
    }

    if (name) {
      StorageUtils.set("scheduler_name", name);
    }

    if (email) {
      StorageUtils.set("scheduler_email", email);
    }

    if (phone) {
      StorageUtils.set("scheduler_phone", phone);
    }
  }, []);

  const toastProvider = useMemo(() => {
    return <ToastProvider />;
  }, []);

  return (
    <>
      <IonRouterOutlet animated={false} id="main">
        <Route path="/schedule" component={LocationsV2} exact={true}></Route>

        <Route
          path="/schedule/:storeID"
          component={SchedulerRedirect}
          exact={true}
        ></Route>
        <Route
          path="/schedule/:storeID/:appointmentType"
          component={SchedulerRouting}
          exact={true}
        ></Route>
        <Route
          path="/schedule/:storeID/:appointmentType/:dateTime"
          component={Details}
          exact={true}
        ></Route>
        <Route component={Page404}></Route>
      </IonRouterOutlet>
      {toastProvider}
    </>
  );
};

export default WebScheduler;
