import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonModal,
  IonPage,
  IonPopover,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import "./UpcomingOrderCard.css";

import moment from "moment-timezone";
import _ from "underscore";
import {
  checkbox,
  checkboxOutline,
  checkmarkCircle,
  chevronBack,
  chevronForward,
  closeOutline,
  ellipsisVertical,
  fileTrayFullOutline,
  playCircleOutline,
  playOutline,
  refreshCircleOutline,
  timeOutline,
} from "ionicons/icons";
import StringUtils from "../../../lib/StringUtils";
import API from "../../../lib/API";
import StorageUtils from "../../../lib/StorageUtils";
import plnSymbol from "../../../assets/img/logo/pln_symbol.svg";
import Constants from "../../../lib/Constants";
import Event from "../../../lib/Event";
import PubSub from "../../../lib/PubSub";
import MealOption from "./MealOption";
import MealOptionViewAll from "./MealOptionViewAll";
import MealDetailModal from "../../MealDetailModal";
import DateUtils from "../../../lib/DateUtils";
import { useHistory } from "react-router";
import { Capacitor } from "@capacitor/core";

const UpcomingOrderCardSkeleton: React.FC<{ nativeMode?: boolean }> = ({
  nativeMode = false,
}) => {
  const mealSkeletion = (
    <>
      <div
        style={{
          minWidth: 280,
          borderRadius: 6,

          display: "inline-block",
        }}
      >
        <div
          style={{
            background: "var(--midlighter)",
            height: 160,
            border: "1px solid var(--midlighter)",
            borderRadius: 6,
            position: "relative",
          }}
        ></div>
        <div className="pt-3" style={{ whiteSpace: "pre-wrap" }}>
          <h1
            style={{ fontSize: 18, lineHeight: 1.3 }}
            className="font-weight-bold text-dark text-truncate"
          >
            <IonSkeletonText
              style={{ height: 18, width: "70%" }}
              animated={true}
            ></IonSkeletonText>
          </h1>
          <p className="mb-2 text-medium mealoption-info-description">
            <IonSkeletonText
              animated={true}
              style={{ height: 30 }}
            ></IonSkeletonText>
          </p>
        </div>
      </div>
    </>
  );

  return (
    <>
      <IonCard
        mode={
          !nativeMode ? "ios" : Capacitor.getPlatform() == "ios" ? "ios" : "md"
        }
        className="mx-0 mt-0 mb-3 border shadow"
      >
        <IonCardHeader className="text-muted font-weight-500">
          <IonRow className="ion-align-items-center">
            <IonCol className="pl-0 py-0">
              <IonCardSubtitle className="text-muted text-uppercase m-0">
                <IonSkeletonText
                  style={{ width: 120 }}
                  animated={true}
                ></IonSkeletonText>
              </IonCardSubtitle>
              <IonCardTitle
                style={{ fontSize: "1.6rem", paddingTop: "0.5rem" }}
              >
                <IonSkeletonText
                  style={{ width: "40%", height: 19 }}
                  animated={true}
                ></IonSkeletonText>
              </IonCardTitle>
            </IonCol>
            <IonCol className="pr-0 py-0" size-xs="auto"></IonCol>
          </IonRow>
        </IonCardHeader>
        <IonCardContent
          className="pt-3 px-0"
          style={{ borderTop: "1px solid var(--midlighter)" }}
        >
          <p
            className="m-0 px-4 text-dark font-weight-bold"
            style={{ fontSize: 18 }}
          >
            <IonSkeletonText
              style={{ width: 150, height: 18 }}
              animated={true}
            ></IonSkeletonText>
          </p>
          <div
            className="mt-4 ml-4"
            style={{
              overflowX: "hidden",
              overflowY: "hidden",
              whiteSpace: "nowrap",
              display: "flex",
              paddingBottom: 0,
            }}
          >
            <div className="mr-3">{mealSkeletion}</div>
            <div className="mr-3">{mealSkeletion}</div>
            <div className="mr-3">{mealSkeletion}</div>
            <div className="mr-3">{mealSkeletion}</div>
          </div>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default UpcomingOrderCardSkeleton;
