import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";
import "./SchedulingCalendar.css";

import moment from "moment-timezone";
import _, { select, times } from "underscore";
import { chevronBack, chevronForward } from "ionicons/icons";
import WindowUtils from "../../../lib/WindowUtils";
import PubSub from "../../../lib/PubSub";
import Event from "../../../lib/Event";
import API from "../../../lib/API";
import DateUtils from "../../../lib/DateUtils";

const SchedulingCalendarV2: React.FC<{
  store?: any;
  onDateSelected?: any;
  appointmentType?: any;
  value?: any;
}> = ({
  appointmentType = null,
  store = null,
  onDateSelected = null,
  value = null,
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedDateObject, setSelectedDateObject] = useState<any>(null);
  const [minDate, setMinDate] = useState(moment().startOf("day").toDate());
  const [maxDate, setMaxDate] = useState(
    moment().add(60, "days").endOf("day").toDate()
  );
  const [listener, setListener] = useState<any>(null);

  const [dateRange, setDateRange] = useState<any>([]);

  const calculateDateRange = async (startDate: any, store: any) => {
    let range = 4;

    let dates = [];

    if (!store || !startDate) {
      return;
    }

    for (let i = 0; i < range; i++) {
      let timeslots = await getHourlyTimeslots(
        store,
        moment(startDate).add(i, "days").startOf("day").toDate()
      );

      dates.push({
        date: moment(startDate).add(i, "days").startOf("day").toDate(),
        timeslots: timeslots,
      });
    }

    let startDateTimeslots = _.find(dates, (d) => {
      return (
        moment(d?.date).format("YYYY-MM-DD") ==
        moment(startDate).format("YYYY-MM-DD")
      );
    });

    if (startDateTimeslots?.timeslots?.length) {
      setSelectedDate(moment(startDate).startOf("day").toDate());
      setSelectedDateObject(startDateTimeslots);
    } else {
      let s = startDate;

      while (!startDateTimeslots?.timeslots?.length) {
        s = moment(s).add(1, "days").startOf("day").toDate();

        let ts = _.find(dates, (d) => {
          return (
            moment(d?.date).format("YYYY-MM-DD") ==
            moment(s).format("YYYY-MM-DD")
          );
        });

        if (ts?.timeslots?.length) {
          setSelectedDate(moment(s).startOf("day").toDate());
          setSelectedDateObject(ts);

          break;
        }

        if (moment(s).toDate() > moment(maxDate).toDate()) {
          setSelectedDate(new Date());

          break;
        }
      }
    }

    setDateRange(dates);
    setLoading(false);
  };

  const onSelectTime = (time: any) => {
    if (typeof onDateSelected == "function") {
      let dateString = moment(selectedDate).format("YYYY-MM-DD");

      dateString += "T" + time;

      onDateSelected(moment(dateString).toDate());
    }
  };

  const onChange = (date: any, event: any) => {
    setSelectedDate(date?.date);
    setSelectedDateObject(date);
  };

  /**
   * Fetches existing appointments for the date selected
   *
   * @param store
   * @param appointmentType
   * @param date
   * @returns
   */
  const getExistingAppointments = async (
    store: any,
    appointmentType: any,
    date: any
  ) => {
    return new Promise((resolve, reject) => {
      API.getExistingAppointments(
        store?._id,
        appointmentType?.APPOINTMENT_TYPE,
        date.toISOString()
      ).then(
        (data: any) => {
          const appointments = data?.data?.data?.appointments
            ? data.data.data.appointments
            : [];

          resolve(appointments);
        },
        (e: any) => {
          reject(e);
        }
      );
    });
  };

  const getHourlyTimeslots = async (store: any, date: any) => {
    if (!date) {
      return [];
    }

    let day = date.getDay();

    if (day == 0) {
      day = "Sunday";
    } else if (day == 1) {
      day = "Monday";
    } else if (day == 2) {
      day = "Tuesday";
    } else if (day == 3) {
      day = "Wednesday";
    } else if (day == 4) {
      day = "Thursday";
    } else if (day == 5) {
      day = "Friday";
    } else if (day == 6) {
      day = "Saturday";
    }

    if (!store?.operations?.hours?.standard?.length) {
      return [];
    }

    let hours = _.findWhere(store?.operations?.hours?.standard, {
      day,
    });

    if (!hours || hours?.closed || !hours.opensAt || !hours.closesAt) {
      return [];
    }

    let opensAtInt: any = parseInt(hours.opensAt?.replace(":")) * 60;
    let closesAtInt: any = parseInt(hours.closesAt?.replace(":")) * 60;

    let now = store?.timezone ? moment.tz(store?.timezone) : moment();

    let chosenDate = store?.timezone
      ? moment.tz(date?.toISOString(), store?.timezone)
      : moment(date?.toISOString());

    let opensAt = store?.timezone
      ? moment(hours?.opensAt, "HH:mm").tz(store?.timezone)
      : moment(hours?.opensAt, "HH:mm");

    if (chosenDate?.format("YYYY-MM-DD") == now?.format("YYYY-MM-DD")) {
      if (now?.toDate() <= opensAt?.toDate()) {
        opensAtInt = store?.timezone
          ? moment(hours?.opensAt, "HH:mm").tz(store?.timezone)
          : moment(hours?.opensAt, "HH:mm").add(30, "minutes");
      } else {
        if (now.minutes() >= 20) {
          now = now
            .hour(now.hour() + 1)
            .minutes(0)
            .seconds(0);
        } else {
          now = now.minutes(30).seconds(0);
        }

        opensAtInt = now;
      }
    } else {
      opensAtInt = store?.timezone
        ? moment(hours?.opensAt, "HH:mm").tz(store?.timezone).add(30, "minutes")
        : moment(hours?.opensAt, "HH:mm").add(30, "minutes");
    }

    closesAtInt = moment(hours?.closesAt, "HH:mm").subtract(60, "minutes");

    let out = [];

    let counter = moment(opensAtInt.toISOString());

    while (counter.toDate() <= closesAtInt.toDate()) {
      const dateVal = store?.timezone
        ? moment.tz(counter.toDate(), store?.timezone)
        : moment(counter.toDate());

      dateVal
        .day(chosenDate.day())
        .month(chosenDate.month())
        .year(chosenDate.year());

      out.push({
        label: counter.format("h:mm A"),
        value: counter.format("HH:mm"),
        date: dateVal.toDate(),
        status: "available",
      });

      counter.add(30, "minutes");
    }

    let existing: any = [];

    try {
      existing = await getExistingAppointments(store, appointmentType, date);
      //let existing2 = await getExistingAppointments(store, "ec-consult", date);

      //existing = existing.concat(existing2);
    } catch (e: any) {}

    //console.log("existing", store, date, existing);

    for (let i = 0; i < existing?.length; i++) {
      const startTime = store?.timezone
        ? moment
            .tz(existing[i]?.startsAt, store?.timezone)
            .seconds(0)
            .millisecond(0)
            .format("HH:mm")
        : moment(existing[i]?.startsAt)
            .seconds(0)
            .millisecond(0)
            .format("HH:mm");

      let match = _.filter(out, (item) => {
        return item?.value == startTime;
      });

      let allowedCount = store?.appointmentsPerSlot
        ? store?.appointmentsPerSlot
        : 1;

      if (match?.length >= allowedCount) {
        out = out?.map((item: any) => {
          if (item?.value == startTime) {
            item.status = "unavailable";
          }

          return item;
        });
      }
    }

    return out;
  };

  useEffect(() => {
    if (value != selectedDate) {
      setSelectedDate(value);
    }
  }, [value]);

  useEffect(() => {
    let startDate = new Date();

    if (store && !DateUtils.storeIsLive(store)) {
      if (store?.operations?.storeOpening?.openingDate) {
        startDate = moment(store?.operations?.storeOpening?.openingDate)
          .startOf("day")
          .toDate();

        setMinDate(startDate);

        console.log("SET MIN DATE 1");

        setMaxDate(moment(startDate).add(21, "days").endOf("day").toDate());
      } else if (store?.operations?.storeOpening?.firstBillDate) {
        startDate = moment(store?.operations?.storeOpening?.firstBillDate)
          .startOf("day")
          .toDate();

        setMinDate(startDate);

        console.log("SET MIN DATE 2");

        setMaxDate(moment(startDate).add(21, "days").endOf("day").toDate());
      }
    }

    if (startDate < new Date()) {
      startDate = new Date();

      console.log("SET MIN DATE 3");

      setMinDate(startDate);
      setMaxDate(moment(startDate).add(21, "days").endOf("day").toDate());
    }

    if (!selectedDate) {
      setSelectedDate(startDate);
    }

    if (store) {
      calculateDateRange(selectedDate ? selectedDate : startDate, store);
    }
  }, [store]);

  /**
   * Check if the store has hours on a specific day
   */
  const storeHasHours = useCallback(
    (day: any) => {
      if (!store) {
        return false;
      }

      if (day == 0) {
        day = "Sunday";
      } else if (day == 1) {
        day = "Monday";
      } else if (day == 2) {
        day = "Tuesday";
      } else if (day == 3) {
        day = "Wednesday";
      } else if (day == 4) {
        day = "Thursday";
      } else if (day == 5) {
        day = "Friday";
      } else if (day == 6) {
        day = "Saturday";
      }

      if (!store?.operations?.hours?.standard?.length) {
        return false;
      }

      let hours = _.findWhere(store?.operations?.hours?.standard, {
        day,
      });

      if (!hours || hours?.closed) {
        return false;
      }

      return true;
    },
    [store]
  );

  /**
   * Disable specific dates based on the store's hours
   */
  const disableDate = useCallback(
    ({ date, view }: any) => {
      if (!store) {
        return true;
      }

      const now = store?.timezone
        ? moment.tz(store?.timezone)?.startOf("day")
        : moment()?.startOf("day");
      const checkingDate = store?.timezone
        ? moment.tz(date, store?.timezone)
        : moment(date);

      // If the date is in the past, disable it
      if (checkingDate.isBefore(now)) {
        return true;
      }

      // Only allow booking 60 days in advance
      if (checkingDate.isAfter(moment().add(60, "days"))) {
        return true;
      }

      // If the store is closed on this day, disable it
      if (!storeHasHours(checkingDate.format("dddd"))) {
        return true;
      }

      const openingDate = moment(store?.operations?.storeOpening?.openingDate);

      if (checkingDate.isBefore(openingDate)) {
        return true;
      }

      return false;
    },
    [store]
  );

  return (
    <>
      <div className="flex justify-center items-center">
        <div>
          <button
            className={`rounded-lg p-1 border ${
              loading ||
              moment(selectedDate).format("YYYY-MM-DD") ==
                moment(minDate)?.format("YYYY-MM-DD")
                ? "border-gray-100 bg-gray-50 text-gray-400 cursor-not-allowed"
                : "border-gray-100 text-gray-900"
            }`}
            disabled={
              loading ||
              moment(selectedDate).format("YYYY-MM-DD") ==
                moment(minDate)?.format("YYYY-MM-DD")
            }
            onClick={() => {
              let newDate = moment(selectedDate)
                .subtract(4, "days")
                .startOf("day")
                .toDate();

              if (moment(newDate).toDate() <= moment(minDate).toDate()) {
                newDate = moment(minDate).toDate();
              }

              calculateDateRange(newDate, store);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              className="h-8 w-8"
              stroke-linejoin="round"
            >
              <path d="m15 18-6-6 6-6" />
            </svg>
          </button>
        </div>
        <div className="grow text-center">
          {loading ? (
            <>
              <IonSkeletonText
                style={{ width: 200, height: 30 }}
                animated={true}
                className="rounded-lg mx-auto"
              ></IonSkeletonText>
            </>
          ) : (
            <h2 className="m-0 text-xl font-bold text-gray-900">
              {dateRange?.length
                ? moment(dateRange[0].date)?.format("MMM DD")
                : ""}{" "}
              -{" "}
              {dateRange?.length
                ? moment(dateRange[dateRange.length - 1].date)?.format("MMM DD")
                : ""}
            </h2>
          )}
        </div>
        <div>
          <button
            className={`rounded-lg p-1 border ${
              loading ||
              moment(selectedDate).format("YYYY-MM-DD") ==
                moment(maxDate)?.format("YYYY-MM-DD")
                ? "border-gray-100 bg-gray-50 text-gray-400 cursor-not-allowed"
                : "border-gray-100 text-gray-900"
            }`}
            disabled={
              loading ||
              moment(selectedDate).format("YYYY-MM-DD") ==
                moment(maxDate)?.format("YYYY-MM-DD")
            }
            onClick={() => {
              let newDate = moment(selectedDate)
                .add(4, "days")
                .startOf("day")
                .toDate();

              if (moment(newDate).toDate() >= moment(maxDate).toDate()) {
                newDate = moment(maxDate).subtract(4, "days").toDate();
              }

              calculateDateRange(newDate, store);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="h-8 w-8"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </button>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-x-3 mt-3">
        {loading ? (
          <>
            <IonSkeletonText
              animated={true}
              className={`rounded-lg h-[126px] p-[0.25rem] sm:p-3 text-center`}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              className={`rounded-lg h-[126px] p-[0.25rem] sm:p-3 text-center`}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              className={`rounded-lg h-[126px] p-[0.25rem] sm:p-3 text-center`}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              className={`rounded-lg h-[126px] p-[0.25rem] sm:p-3 text-center`}
            ></IonSkeletonText>
          </>
        ) : (
          <>
            {dateRange?.map((date: any, index: any) => (
              <div
                key={index}
                onClick={() => {
                  if (!date?.timeslots?.length) {
                    return;
                  }

                  onChange(date, null);
                }}
              >
                <div
                  className={`rounded-lg px-[0.25rem] py-[0.5rem] ${
                    date?.timeslots?.length
                      ? `border-[1px] text-gray-900 cursor-pointer ${
                          moment(date?.date)
                            .startOf("day")
                            .isSame(moment(selectedDate).startOf("day"))
                            ? "shadow-sm border-orange-500 bg-orange-50 hover:border-orange-500 hover:bg-orange-50"
                            : "hover:shadow-sm hover:border-orange-500 hover:bg-orange-50 border-gray-100"
                        }  transition-all cursor-pointer`
                      : "border border-gray-100 bg-gray-50 text-gray-400 cursor-not-allowed"
                  } sm:p-3 text-center`}
                >
                  <p className="m-0 text-xs sm:text-sm">
                    {moment(date?.date)?.format("dddd")}
                  </p>
                  <p className="mt-1 text-base font-bold">
                    {moment(date?.date)?.format("MMM")}
                  </p>
                  <p className="mt-0 mb-1 text-2xl font-bold">
                    {moment(date?.date)?.format("D")}
                  </p>
                  <p className="m-0 text-xs">
                    {date?.timeslots?.length} Available
                  </p>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {selectedDate ? (
        <div className="mt-[2rem]">
          <h2 className="sm:text-lg text-[18px] text-center font-bold text-gray-900 mb-[0.25rem]">
            Available Times For {moment(selectedDate).format("dddd, MMM Do")}
          </h2>
          <p className="mb-[2rem] mt-0 sm:text-base text-sm text-center text-gray-900">
            Select a time below to schedule your appointment
          </p>
          {selectedDateObject?.timeslots?.length ? (
            <>
              <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 items-center gap-4">
                {selectedDateObject?.timeslots?.map(
                  (timeslot: any, index: any) => (
                    <div
                      onClick={() => {
                        if (timeslot?.status == "unavailable") {
                          return;
                        }

                        onSelectTime(timeslot?.value);
                      }}
                      key={index}
                      className={`p-2 text-center rounded-lg border-[1px]  font-weight-500  ${
                        timeslot?.status == "unavailable"
                          ? "border-gray-100 bg-gray-50 text-gray-300 cursor-not-allowed"
                          : "border-gray-100 hover:border-orange-500 hover:bg-orange-50 cursor-pointer hover:shadow-sm"
                      } transition-all`}
                    >
                      {timeslot?.label}
                    </div>
                  )
                )}
              </div>
            </>
          ) : (
            <>
              <h2 className="text-lg text-center font-bold text-gray-900 mb-3">
                No Available Times
              </h2>
              <p className="text-center text-gray-900">
                There are no available times for this date. Please select
                another date.
              </p>
            </>
          )}
        </div>
      ) : (
        <div className="mt-[2rem]">
          <h2 className="text-lg text-center font-bold text-gray-900 mb-3">
            Select A Date To View Available Times
          </h2>
        </div>
      )}
    </>
  );
};

export default SchedulingCalendarV2;
