import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonModal,
  IonPage,
  IonPopover,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";
import "./SchedulingCalendar.css";

import moment from "moment-timezone";
import _ from "underscore";
import { chevronBack, chevronForward, timeOutline } from "ionicons/icons";
import SchedulingCalendar from "./SchedulingCalendar";
import SchedulingHours from "../SchedulingHours/SchedulingHours";
import SchedulingForm from "../SchedulingForm/SchedulingForm";
import StringUtils from "../../../lib/StringUtils";
import API from "../../../lib/API";
import StorageUtils from "../../../lib/StorageUtils";
import AppointmentInformation from "../AppointmentInformation/AppointmentInformation";
import plnSymbol from "../../../assets/img/logo/pln_symbol.svg";
import Constants from "../../../lib/Constants";
import Event from "../../../lib/Event";
import PubSub from "../../../lib/PubSub";
import { Capacitor } from "@capacitor/core";

const InlineReschedulingModal: React.FC<{
  existingAppointment?: any;
  buttonText?: any;
  presentingElement?: any;
  forceStack?: boolean;
  onSave?: any;
  nativeMode?: boolean;
}> = ({
  existingAppointment = null,
  buttonText = "Reschedule",
  presentingElement = null,
  forceStack = false,
  onSave = null,
  nativeMode = false,
}) => {
  const modalRef = useRef<HTMLIonModalElement>(null);
  const [consultationDate, setConsultationDate] = useState<any>(null);
  const [consultationDateTime, setConsultationDateTime] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [appointment, setAppointment] = useState<any>(null);
  const [appointmentType, setAppointmentType] = useState<any>(null);
  const [error, setErrorMessage] = useState("");
  const [formValues, setFormValues] = useState<any>({
    name: "",
    email: "",
    phone: "",
    notes: "",
    goal: "",
  });

  useEffect(() => {
    PubSub.subscribe(Event.MODAL.RESCHEDULING_MODAL, (evt: any) => {
      if (evt?.type == "close") {
        return modalRef?.current?.dismiss();
      }

      setConsultationDate(null);
      setConsultationDateTime(null);
      modalRef?.current?.present();
    });
  }, []);

  const setError = (msg: any) => {
    if (!msg) {
      setErrorMessage(msg);

      return;
    }

    setErrorMessage(msg);

    setTimeout(() => {
      setError("");
    }, 5000);
  };

  const reschedule = async () => {
    setSubmitting(true);

    const dateTime = consultationDateTime;

    API.rescheduleAppointment(existingAppointment?._id, dateTime)
      .then(
        async (data: any) => {
          setAppointment(data?.data?.data?.appointment);

          if (typeof onSave == "function") {
            onSave(data?.data?.data?.appointment);

            modalRef?.current?.dismiss();
          }
        },
        (err) => {
          setError(
            `Unable to reschedule: ${
              err?.response?.data?.message
                ? err?.response?.data?.message
                : "Unknown error occurred."
            } Please try again`
          );
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (!existingAppointment) {
      return;
    }

    const type = existingAppointment?.appointmentType;

    const aType = Constants.APPOINTMENT_TYPES[type];

    setAppointmentType(aType);
  }, [existingAppointment]);

  return (
    <>
      <IonModal ref={modalRef} canDismiss={true} mode="ios">
        <IonHeader>
          <IonToolbar
            mode={
              !nativeMode
                ? "ios"
                : Capacitor.getPlatform() == "ios"
                ? "ios"
                : "md"
            }
            className={
              nativeMode ? "modal-toolbar-native" : "box-modal-toolbar-web"
            }
            style={{ borderBottom: "none" }}
          >
            <IonButtons slot="start">
              <IonButton
                color="dark"
                onClick={() => {
                  modalRef?.current?.dismiss();
                }}
              >
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Reschedule</IonTitle>

            <IonButtons slot="end">
              <IonButton
                color={"primary"}
                onClick={() => {
                  reschedule();
                }}
                disabled={!consultationDate || submitting}
              >
                {submitting ? (
                  <IonSpinner name="dots"></IonSpinner>
                ) : (
                  <>{buttonText ? buttonText : "Save"}</>
                )}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="ion-padding">
            <p
              className="mt-0 mb-2 text-medium font-weight-500"
              style={{ fontSize: 14 }}
            >
              <span style={{ position: "relative", top: 3 }} className="pr-1">
                <IonIcon icon={timeOutline}></IonIcon>
              </span>

              {appointmentType?.DURATION_READABLE}
            </p>
            <h4 className="text-dark mt-0">{appointmentType?.NAME}</h4>
            <p className="m-0 text-medium" style={{ lineHeight: 1.5 }}>
              {appointmentType?.DESCRIPTION}
            </p>
          </div>
          <div className="ion-padding">
            <IonGrid className="p-0">
              <IonRow>
                {error ? (
                  <IonCol size="12">
                    <p className="text-danger mb-3 mt-0">{error}</p>
                  </IonCol>
                ) : null}
                <IonCol
                  size-xs="12"
                  size-sm="12"
                  size-md={forceStack ? "12" : "7"}
                  size-lg={forceStack ? "12" : "8"}
                  className={`pl-0 pr-0 ${forceStack ? "" : "pr-md-2"}`}
                >
                  <SchedulingCalendar
                    store={existingAppointment?.store}
                    appointmentType={appointmentType}
                    value={consultationDate}
                    onDateSelected={(date: any) => {
                      setConsultationDate(date);

                      if (consultationDate !== date) {
                        setConsultationDateTime(null);
                      }
                    }}
                  ></SchedulingCalendar>
                </IonCol>
                <IonCol
                  className={`pr-0 pl-0 ${
                    forceStack ? "" : "pl-md-2 mt-md-0"
                  } mt-3`}
                  size-xs="12"
                  size-sm="12"
                  size-md={forceStack ? "12" : "5"}
                  size-lg={forceStack ? "12" : "4"}
                >
                  <SchedulingHours
                    maxHeight={forceStack ? null : 398}
                    store={existingAppointment?.store}
                    appointmentType={appointmentType}
                    showContinueButton={false}
                    onContinue={() => {}}
                    value={consultationDateTime}
                    onDateSelected={(date: any) => {
                      const time = moment(date?.toISOString());

                      const dat = moment(consultationDate?.toISOString())
                        .hours(time.hours())
                        .minutes(time.minutes())
                        .seconds(0)
                        .milliseconds(0);

                      setConsultationDateTime(date ? dat.toDate() : null);
                    }}
                    viewingDate={consultationDate}
                  ></SchedulingHours>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default InlineReschedulingModal;
