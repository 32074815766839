import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSlide,
  IonSlides,
  IonSpinner,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { useParams } from "react-router";

import plnSymbol from "../../../assets/img/logo/pln_symbol.svg";
import Navbar from "../../../components/WebApp/Navbar/Navbar";
import PubSub from "../../../lib/PubSub";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  calendar,
  calendarClearOutline,
  checkmarkCircle,
  chevronForward,
  create,
  createOutline,
  ellipsisVertical,
  pencil,
  pencilSharp,
  settings,
} from "ionicons/icons";

import consultImage from "../../../assets/img/brand/consultation.jpg";
import referralImage from "../../../assets/img/brand/give20get20.jpg";
import StorageUtils from "../../../lib/StorageUtils";
import API from "../../../lib/API";
import StringUtils from "../../../lib/StringUtils";
import moment from "moment";
import Constants from "../../../lib/Constants";
import Event from "../../../lib/Event";
import InlineSchedulingModal from "../../../components/Scheduler/SchedulingCalendar/InlineSchedulingModal";
import InlineReschedulingModal from "../../../components/Scheduler/SchedulingCalendar/InlineReschedulingModal";
import AppointmentInformation from "../../../components/Scheduler/AppointmentInformation/AppointmentInformation";
import DateUtils from "../../../lib/DateUtils";
import UpcomingOrderCard from "../../../components/AccountManagement/UpcomingOrderCard/UpcomingOrderCard";
import ReviewOrderCard from "../../../components/AccountManagement/ReviewOrderCard/ReviewOrderCard";
import _ from "underscore";
import MealDetailContent from "../../../components/MealDetailContent";
import SEOHeaders from "../../../components/SEOHeaders";
import { Capacitor } from "@capacitor/core";
import ToastProvider from "../../../providers/ToastProvider/ToastProvider";

const UnsubscribePage: React.FC<{ nativeMode: boolean }> = ({
  nativeMode = false,
}) => {
  const { mealID } = useParams<{ mealID: string }>();
  const [meal, setMeal] = useState<any>(null);

  const [email, setEmail] = useState<string>("");
  const [birdContactID, setBirdContactID] = useState<string>("");
  const [forceUnsubscribe, setForceUnsubscribe] = useState<boolean>(false);
  const [unsubscribeAll, setUnsubscribeAll] = useState<boolean>(false);
  const [promotions, setPromotions] = useState<boolean>(true);
  const [news, setNews] = useState<boolean>(true);
  const [account, setAccount] = useState<boolean>(true);
  const [franchising, setFranchising] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const pageRef = useRef();
  const [present] = useIonToast();

  /**
   * Save the email preferences
   *
   * @returns
   */
  const savePreferences = async () => {
    if (!email) {
      return;
    }

    let payload: any = {
      email: email,
      promotional: promotions,
      news: news,
      account: account,
      franchising: franchising,
      unsubscribeAll: false,
    };

    setSubmitting(true);

    if (payload) {
      API.manageEmailPreferences(payload)
        .then(
          (data: any) => {
            setSubmitting(false);

            present({
              message: "Your preferences have been updated",
              duration: 1500,
              position: "bottom",
              icon: checkmarkCircle,
              color: "success",
              layout: "baseline",
              mode: "ios",
              buttons: [
                {
                  text: "OK",
                  role: "cancel",
                },
              ],
            });

            let preferences = data?.data?.data?.subscription;

            setPromotions(preferences?.promotional);
            setNews(preferences?.news);
            setAccount(preferences?.account);
            setFranchising(preferences?.franchising);
            setUnsubscribeAll(preferences?.unsubscribeAll);
          },
          (e) => {}
        )
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  /**
   * Save the email preferences
   *
   * @returns
   */
  const unsubscribeFromAll = async (email: string) => {
    if (!email) {
      return;
    }

    let payload: any = {
      email,
      promotional: false,
      news: false,
      account: false,
      franchising: false,
      unsubscribeAll: true,
    };

    setSubmitting(true);

    if (payload) {
      API.manageEmailPreferences(payload)
        .then(
          (data: any) => {
            setSubmitting(false);

            present({
              message: "Your have been unsubscribed from all emails",
              duration: 1500,
              position: "bottom",
              icon: checkmarkCircle,
              color: "success",
              layout: "baseline",
              mode: "ios",
              buttons: [
                {
                  text: "OK",
                  role: "cancel",
                },
              ],
            });

            let preferences = data?.data?.data?.subscription;

            setPromotions(preferences?.promotional);
            setNews(preferences?.news);
            setAccount(preferences?.account);
            setFranchising(preferences?.franchising);
            setUnsubscribeAll(preferences?.unsubscribeAll);
          },
          (e) => {}
        )
        .finally(() => {
          setSubmitting(false);
          setForceUnsubscribe(false);
        });
    }
  };

  /**
   * Resubscribe to all emails
   *
   * @returns
   */
  const resubscribe = async () => {
    if (!email) {
      return;
    }

    let payload: any = {
      email,
      promotional: true,
      news: true,
      account: true,
      franchising: true,
      unsubscribeAll: false,
    };

    setSubmitting(true);

    if (payload) {
      API.manageEmailPreferences(payload)
        .then(
          (data: any) => {
            setSubmitting(false);

            present({
              message: "Your have been resubscribed to emails",
              duration: 1500,
              position: "bottom",
              icon: checkmarkCircle,
              color: "success",
              layout: "baseline",
              mode: "ios",
              buttons: [
                {
                  text: "OK",
                  role: "cancel",
                },
              ],
            });

            let preferences = data?.data?.data?.subscription;

            setPromotions(preferences?.promotional);
            setNews(preferences?.news);
            setAccount(preferences?.account);
            setFranchising(preferences?.franchising);
            setUnsubscribeAll(preferences?.unsubscribeAll);
          },
          (e) => {}
        )
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const loadPreferences = async (email = "") => {
    if (!email) {
      return;
    }

    let payload: any = {
      email: email,
    };

    if (payload) {
      API.getEmailPreferences(payload).then(
        (data: any) => {
          if (data?.data?.data?.subscription) {
            let sub = data?.data?.data?.subscription;

            setPromotions(sub?.promotional);
            setNews(sub?.news);
            setAccount(sub?.account);
            setFranchising(sub?.franchising);
            setUnsubscribeAll(sub?.unsubscribeAll);
          } else {
            setPromotions(true);
            setNews(true);
            setAccount(true);
            setFranchising(true);
            setUnsubscribeAll(false);
          }
        },
        (e) => {
          setPromotions(true);
          setNews(true);
          setAccount(true);
          setFranchising(true);
          setUnsubscribeAll(false);
        }
      );
    }
  };

  const loadPage = async () => {
    // get email from url params
    let urlEmail: any = new URLSearchParams(window.location.search).get(
      "email"
    );

    let unsubscribe: any = new URLSearchParams(window.location.search).get(
      "unsubscribe"
    );

    if (unsubscribe == "true" && urlEmail) {
      setEmail(urlEmail);

      setTimeout(() => {
        setForceUnsubscribe(true);

        unsubscribeFromAll(urlEmail);
      }, 100);

      return;
    }

    if (urlEmail) {
      setEmail(urlEmail);

      loadPreferences(urlEmail);
    }
  };

  useEffect(() => {
    loadPage();
  }, []);

  return (
    <IonPage ref={pageRef}>
      {nativeMode ? (
        <>
          <IonHeader>
            <IonToolbar className="pln-native-toolbar">
              <IonTitle>{"Email Preferences"}</IonTitle>
              <IonButtons slot="start">
                <IonBackButton></IonBackButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        </>
      ) : (
        <Navbar
          showBackButton={false}
          actionButton={<></>}
          showMenuButton={false}
          pageName={""}
        ></Navbar>
      )}

      <IonContent fullscreen>
        {forceUnsubscribe ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              zIndex: 98,
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="rounded-lg w-[300px] border-[1px] text-center border-gray-100 shadow-sm bg-white p-4">
              <IonSpinner name="dots" />
              <h2 className="text-lg font-weight-bold text-gray-900">
                Unsubscribing
              </h2>
              <p className="m-0">Unsubscribing you from all emails.</p>
            </div>
          </div>
        ) : null}

        <div
          className={`${forceUnsubscribe ? "blur" : ""}`}
          style={{ maxWidth: 600, margin: "auto" }}
        >
          <h1 className="text-2xl  mt-[2rem] text-center font-weight-bold text-gray-900">
            Manage Your Email Preferences
          </h1>
          <div className="mt-5 rounded-lg border-[1px] border-gray-100 shadow-sm bg-white">
            <div className="p-4">
              <h2 className="text-lg font-weight-bold text-gray-900">Email:</h2>
              <input
                className="w-full mt-2 p-2 border bg-white border-gray-200 rounded-lg focus:outline-orange-500"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            {unsubscribeAll ? (
              <>
                <hr className="my-0 border-gray-100"></hr>
                <div className="p-4">
                  <h2 className="text-lg font-weight-bold text-gray-900">
                    Resubscribe To Emails
                  </h2>
                  <p className="m-0">
                    If you would like to start receiving emails from us again,
                    click the button below.
                  </p>
                  <button
                    onClick={() => {
                      if (!email || submitting) {
                        return;
                      }

                      resubscribe();
                    }}
                    disabled={!email || submitting}
                    className={`mt-4 ${
                      email && !submitting
                        ? "bg-orange-500 text-white cursor-pointer"
                        : "bg-gray-100 text-gray-500 cursor-not-allowed"
                    } font-weight-bold w-full px-4 py-2 rounded-lg h-[40px]`}
                  >
                    {submitting ? (
                      <IonSpinner name="dots" />
                    ) : (
                      "Resubscribe To Emails"
                    )}
                  </button>
                </div>
              </>
            ) : (
              <>
                <hr className="my-0 border-gray-100"></hr>
                <div className="p-4">
                  <h2 className="text-lg font-weight-bold text-gray-900">
                    Email Preferences
                  </h2>
                  <p className="m-0">
                    Select the types of emails you would like to receive from
                    us.
                  </p>
                  <div className="mt-3 relative flex items-center">
                    <div className="flex h-6 items-center">
                      <input
                        id="promotions"
                        name="promotions"
                        type="checkbox"
                        aria-describedby="promotions-description"
                        className="h-5 w-5 rounded border-gray-100 text-orange-500 accent-orange-500 focus:ring-orange-500"
                        checked={promotions}
                        onChange={(e) => setPromotions(e.target.checked)}
                      />
                    </div>
                    <div className="ml-3">
                      <label
                        htmlFor="promotions"
                        className="font-bold text-base text-gray-900"
                      >
                        Promotions
                      </label>
                      <p
                        id="promotions-description"
                        className="text-gray-900 text-sm"
                      >
                        Special offers, discounts, and promotions
                      </p>
                    </div>
                  </div>
                  <div className="mt-3 relative flex items-center">
                    <div className="flex h-6 items-center">
                      <input
                        id="news"
                        name="news"
                        type="checkbox"
                        aria-describedby="news-description"
                        className="h-5 w-5 rounded border-gray-100 text-orange-500 accent-orange-500 focus:ring-orange-500"
                        checked={news}
                        onChange={(e) => setNews(e.target.checked)}
                      />
                    </div>
                    <div className="ml-3">
                      <label
                        htmlFor="news"
                        className="font-bold text-base text-gray-900"
                      >
                        News & Updates
                      </label>
                      <p
                        id="news-description"
                        className="text-gray-900 text-sm"
                      >
                        News, updates, and announcements from PLN
                      </p>
                    </div>
                  </div>
                  <div className="mt-3 relative flex items-center">
                    <div className="flex h-6 items-center">
                      <input
                        id="account"
                        name="account"
                        type="checkbox"
                        aria-describedby="account-description"
                        className="h-5 w-5 rounded border-gray-100 text-orange-500 accent-orange-500 focus:ring-orange-500"
                        checked={account}
                        onChange={(e) => setAccount(e.target.checked)}
                      />
                    </div>
                    <div className="ml-3">
                      <label
                        htmlFor="account"
                        className="font-bold text-base text-gray-900"
                      >
                        Account Notifications
                      </label>
                      <p
                        id="account-description"
                        className="text-gray-900 text-sm"
                      >
                        Account notifications and updates, including receipts &
                        confirmation emails
                      </p>
                    </div>
                  </div>
                  <div className="mt-3 relative flex items-center">
                    <div className="flex h-6 items-center">
                      <input
                        id="franchising"
                        name="franchising"
                        type="checkbox"
                        aria-describedby="franchising-description"
                        className="h-5 w-5 rounded border-gray-100 text-orange-500 accent-orange-500 focus:ring-orange-500"
                        checked={franchising}
                        onChange={(e) => setFranchising(e.target.checked)}
                      />
                    </div>
                    <div className="ml-3">
                      <label
                        htmlFor="franchising"
                        className="font-bold text-base text-gray-900"
                      >
                        Franchising Opportunities
                      </label>
                      <p
                        id="franchising-description"
                        className="text-gray-900 text-sm"
                      >
                        Information about franchising opportunities with PLN
                      </p>
                    </div>
                  </div>
                  <button
                    disabled={!email || submitting}
                    className={`mt-4 ${
                      email && !submitting
                        ? "bg-orange-500 text-white cursor-pointer"
                        : "bg-gray-100 text-gray-500 cursor-not-allowed"
                    } font-weight-bold w-full px-4 py-2 rounded-lg h-[40px]`}
                    onClick={() => {
                      if (!email || submitting) {
                        return;
                      }

                      savePreferences();
                    }}
                  >
                    {submitting ? (
                      <IonSpinner name="dots" />
                    ) : (
                      "Save Preferences"
                    )}
                  </button>
                </div>
                <hr className="my-0 border-gray-100"></hr>
                <div className="p-4">
                  <h2 className="text-lg font-weight-bold text-gray-900">
                    Unsubscribe from All Emails
                  </h2>
                  <p className="m-0">
                    If you would like to stop receiving all emails from us,
                    click the button below.
                  </p>
                  <button
                    onClick={() => {
                      if (!email || submitting) {
                        return;
                      }

                      unsubscribeFromAll(email);
                    }}
                    className={`mt-4 ${
                      email && !submitting
                        ? "border border-primary bg-white text-orange-500 cursor-pointer"
                        : "text-gray-400 bg-white cursor-not-allowed"
                    } font-weight-bold w-full px-4 py-2 rounded-lg h-[40px]`}
                  >
                    Unsubscribe From All Emails
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default UnsubscribePage;
