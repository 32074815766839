import {
  Redirect,
  Route,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";

import { Suspense, lazy } from "react";

import { IonReactRouter } from "@ionic/react-router";
import { ellipse, square, triangle } from "ionicons/icons";
import AppUrlListener from "./AppUrlListener";

import { Capacitor } from "@capacitor/core";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/tailwind.css";

import "./theme/pln/core.css";

import API from "./lib/API";
import posthog from "posthog-js";
import WindowUtils from "./lib/WindowUtils";
import StorageUtils from "./lib/StorageUtils";

import ToastProvider from "./providers/ToastProvider/ToastProvider";
import { useEffect, useMemo } from "react";
import MealInformationPage from "./pages/web/MealInformationPage/MealInformationPage";
import Page404 from "./pages/web/authentication/404/Page404";
import SEOHeaders from "./components/SEOHeaders";
import WebAppSkeletonLoader from "./components/Skeletons/WebAppSkeletonLoader";
import MobileSkeletonLoader from "./components/Skeletons/MobileSkeletonLoader";

import WebScheduler from "./views/web/WebScheduler";
import WebAppAuthentication from "./views/web/WebAppAuthentication";
import WebAppCheckout from "./views/web/WebAppCheckout";
import { SplashScreen } from "@capacitor/splash-screen";
import { Keyboard } from "@capacitor/keyboard";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Device } from "@capacitor/device";
import { Provider } from "@rollbar/react";
import UnsubscribePage from "./pages/web/UnsubscribePage/UnsubscribePage";

/*import MobileApp from "./views/mobile/MobileApp";
import WebApp from "./views/web/WebApp";*/

const WebApp = lazy(() => import("./views/web/WebApp"));
const MobileApp = lazy(() => import("./views/mobile/MobileApp"));

/*
const WebAppAuthentication = lazy(
  () => import("./views/web/WebAppAuthentication")
);
const WebAppCheckout = lazy(() => import("./views/web/WebAppCheckout"));
const WebScheduler = lazy(() => import("./views/web/WebScheduler"));*/

setupIonicReact();

if (Capacitor.isNativePlatform()) {
  API.setEnvironment(API.ENV_PRODUCTION);

  Device.getInfo().then((info) => {
    console.log(info);
  });

  if (Capacitor.getPlatform() == "android") {
    //StatusBar.setStyle({ style: Style.Light });
    //StatusBar.setBackgroundColor({ color: "#D7DBDF" });
  }
} else if (window?.location?.href?.includes("localhost")) {
  API.setEnvironment(API.ENV_DEV);
} else {
  API.setEnvironment(API.ENV_PRODUCTION);
}

WindowUtils.resizeListener();
StorageUtils.init();

const hideSplashScreen = async () => {
  // Hide the splash (you should do this on app launch)
  await SplashScreen.hide();
};

hideSplashScreen();

const App: React.FC = () => {
  const win: any = window;
  const location = win?.location;

  const invalidURL = (url: any) => {
    return (
      !url.includes("login") &&
      !url.includes("schedule") &&
      !url.includes("start") &&
      !url.includes("meal-information") &&
      !url.includes("manage") &&
      !url.includes("native") &&
      !url?.includes("unsubscribe") &&
      url != "/"
    );
  };

  const rollbarConfig = {
    accessToken: `9dda4ef1cb1a481c9b8dd043f86a3bdb`,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment:
        Capacitor.isNativePlatform() || !location?.href?.includes("localhost")
          ? "production"
          : "development",
      client: {
        javascript: {
          source_map_enabled: true,
        },
      },
    },
  };

  return (
    <Provider config={rollbarConfig}>
      <IonApp>
        <SEOHeaders></SEOHeaders>
        <Suspense
          fallback={
            <div>
              <IonReactRouter>
                <AppUrlListener></AppUrlListener>
                <IonRouterOutlet>
                  <Route path="/manage">
                    {Capacitor.isNativePlatform() ? (
                      <MobileSkeletonLoader></MobileSkeletonLoader>
                    ) : (
                      <WebAppSkeletonLoader></WebAppSkeletonLoader>
                    )}
                  </Route>

                  <Route path="/" exact>
                    <Redirect to={"/manage"} />
                  </Route>
                  <Route
                    path={`${invalidURL(location?.pathname) ? "*" : "/404"}`}
                    component={Page404}
                  ></Route>
                </IonRouterOutlet>
              </IonReactRouter>
            </div>
          }
        >
          <IonReactRouter>
            <AppUrlListener></AppUrlListener>
            <IonRouterOutlet>
              <Route path="/start">
                <WebAppCheckout></WebAppCheckout>
              </Route>
              <Route path="/schedule">
                <WebScheduler></WebScheduler>
              </Route>
              <Route path="/login">
                <WebAppAuthentication></WebAppAuthentication>
              </Route>
              <Route path="/manage">
                {Capacitor.isNativePlatform() ? (
                  <MobileApp></MobileApp>
                ) : (
                  <WebApp></WebApp>
                )}
              </Route>
              <Route path="/meal-information/:mealID">
                <MealInformationPage
                  nativeMode={Capacitor.isNativePlatform()}
                ></MealInformationPage>
              </Route>
              <Route path="/unsubscribe">
                <UnsubscribePage
                  nativeMode={Capacitor.isNativePlatform()}
                ></UnsubscribePage>
              </Route>

              <Route path="/" exact>
                <Redirect to={"/manage"} />
              </Route>

              <Route
                path={`${invalidURL(location?.pathname) ? "*" : "/404"}`}
                component={Page404}
              ></Route>
            </IonRouterOutlet>
          </IonReactRouter>
        </Suspense>
      </IonApp>
    </Provider>
  );
};

export default App;
