import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
  useIonLoading,
} from "@ionic/react";
import { useParams } from "react-router";
import "./MealInformationPage.css";

import plnSymbol from "../../../assets/img/logo/pln_symbol.svg";
import Navbar from "../../../components/WebApp/Navbar/Navbar";
import PubSub from "../../../lib/PubSub";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  calendar,
  calendarClearOutline,
  chevronForward,
  create,
  createOutline,
  ellipsisVertical,
  pencil,
  pencilSharp,
  settings,
} from "ionicons/icons";

import consultImage from "../../../assets/img/brand/consultation.jpg";
import referralImage from "../../../assets/img/brand/give20get20.jpg";
import StorageUtils from "../../../lib/StorageUtils";
import API from "../../../lib/API";
import StringUtils from "../../../lib/StringUtils";
import moment from "moment";
import Constants from "../../../lib/Constants";
import Event from "../../../lib/Event";
import InlineSchedulingModal from "../../../components/Scheduler/SchedulingCalendar/InlineSchedulingModal";
import InlineReschedulingModal from "../../../components/Scheduler/SchedulingCalendar/InlineReschedulingModal";
import AppointmentInformation from "../../../components/Scheduler/AppointmentInformation/AppointmentInformation";
import DateUtils from "../../../lib/DateUtils";
import UpcomingOrderCard from "../../../components/AccountManagement/UpcomingOrderCard/UpcomingOrderCard";
import ReviewOrderCard from "../../../components/AccountManagement/ReviewOrderCard/ReviewOrderCard";
import _ from "underscore";
import MealDetailContent from "../../../components/MealDetailContent";
import SEOHeaders from "../../../components/SEOHeaders";
import { Capacitor } from "@capacitor/core";

const MealInformationPage: React.FC<{ nativeMode: boolean }> = ({
  nativeMode = false,
}) => {
  const { mealID } = useParams<{ mealID: string }>();
  const [meal, setMeal] = useState<any>(null);
  const [present, dismiss] = useIonLoading();

  const [loading, setLoading] = useState<boolean>(false);
  const pageRef = useRef();

  const loadPage = async () => {
    setLoading(true);

    const authorizationToken = await StorageUtils.get(
      "user_authorizationToken"
    );

    if (authorizationToken) {
      API.setAuthToken(authorizationToken);
    }

    API.getMealByID(mealID)
      .then(
        (res: any) => {
          const meal = res?.data?.data?.meal;

          setMeal(meal);
        },
        (e: any) => {}
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadPage();
  }, []);

  return (
    <IonPage ref={pageRef}>
      {nativeMode ? (
        <>
          <IonHeader>
            <IonToolbar className="pln-native-toolbar">
              <IonTitle>{meal ? meal?.name : "Loading"}</IonTitle>
              <IonButtons slot="start">
                <IonBackButton></IonBackButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        </>
      ) : (
        <Navbar
          showBackButton={false}
          actionButton={<></>}
          showMenuButton={false}
          pageName={""}
        ></Navbar>
      )}

      <IonContent fullscreen className="">
        <div style={{ maxWidth: 1000, margin: "auto" }}>
          <MealDetailContent
            presentingElement={pageRef}
            meal={meal}
            showReview={true}
            skeleton={loading}
            showReturnToApp={!Capacitor.isNativePlatform()}
          ></MealDetailContent>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MealInformationPage;
