import { Capacitor } from "@capacitor/core";
import moment from "moment";
import API from "./API";
import PubSub from "./PubSub";
import Event from "./Event";
import _ from "underscore";

export default class PaymentUtils {
  static APPLE_PAY_MERCHANT_ID: string = "merchant.com.projectleannation.app";

  static GOOGLE_PAY_API_VERSION: any = {
    apiVersion: 2,
    apiVersionMinor: 0,
  };

  /**
   * Checks is a store supports google pay and the current browser session allows it.
   *
   * @param store
   * @returns
   */
  static googlePaySupported(store: any = null) {
    if (Capacitor.isNativePlatform()) {
    } else {
    }

    return true;
  }

  /**
   * Checks if the store supports apple pay and the current browser allows it.
   *
   * @param store
   * @returns
   */
  static applePaySupported(store: any = null) {
    const win: any = window;

    if (Capacitor.isNativePlatform()) {
    } else {
      if (!win?.hasOwnProperty("ApplePaySession") || !win?.ApplePaySession) {
        return false;
      }

      try {
        let can = win.ApplePaySession.canMakePayments();

        return can;
      } catch (e: any) {
        //console.error(e);

        return false;
      }
    }

    return true;
  }

  /**
   * Checks if the store/browser allows express checkout options
   *
   * @param store
   * @returns
   */
  static storeSupportsExpressCheckout(store: any = null) {
    return false; //TODO: debug

    return this.applePaySupported(store) || this.googlePaySupported(store);
  }

  static async performApplePayMerchantValidation(validationURL: any = null) {
    return new Promise((resolve, reject) => {
      API.applePayMerchantVerification(validationURL).then(
        (data: any) => {
          //  console.log(data?.data?.data?.paymentSession);

          resolve(data?.data?.data?.paymentSession);
        },
        (error: any) => {
          console.error(error);

          reject(error);
        }
      );
    });
  }

  static finalizeApplePayCheckout(
    paymentData: any = null,
    checkoutSession: any = null
  ) {
    return new Promise(function (resolve, reject) {
      //   console.log('starting function sendPaymentToken()');
      //  console.log(paymentData?.paymentData);

      let objJsonStr = JSON.stringify(paymentData?.paymentData);
      //console.log(objJsonStr);
      let objJsonB64 = window.btoa(objJsonStr);
      //console.log(objJsonB64);

      // TODO: perform full checkout here

      let opaqueData = {
        dataDesc: "COMMON.APPLE.INAPP.PAYMENT",
        dataValue: objJsonB64,
      };

      /* Send Payment token to Payment Gateway, here its defaulting to True just to mock that part */

      API.applePayTest(opaqueData).then(
        (data: any) => {
          console.log(data);
        },
        (e) => {
          console.error(e);
        }
      );

      return resolve(true);
    });
  }

  static async startApplePayCheckout(store: any, checkoutSession: any = null) {
    if (!this.applePaySupported(store)) {
      return;
    }

    const win: any = window;

    const plan: any = checkoutSession.plan;

    if (Capacitor.isNativePlatform()) {
      // Start Apple Pay Native Checkout
    } else {
      // Start Apple Pay For Web Checkout
      let payRequest: any = {
        countryCode: "US",
        currencyCode: "USD",
        supportedNetworks: ["visa", "masterCard", "amex", "discover"],
        lineItems: [
          {
            amount: "1.50",
            type: "pending",
            label: "Lifestyle 12 Meal Weekly Plan",
            paymentTiming: "recurring",
            recurringPaymentStartDate: moment("2023-04-20").toDate(),
            recurringPaymentIntervalUnit: "day",
            recurringPaymentIntervalCount: 7,
            recurringPaymentEndDate: moment("2024-04-22").toDate(),
          },
        ],
        recurringPaymentRequest: {
          paymentDescription: "Weekly 12-Meal Plan. Pause & cancel anytime.",
          billingAgreement: `Pause & cancel anytime online & in-app. With your promotion HFN30OFF, you're receiving $15 off each of your first two orders. Regular price: $114.00. Order payments are processed weekly on Saturday, with your first order scheduled for pickup on April 22, 2023.`,
          managementURL: "https://account.projectleannation.com",
          tokenNotificationURL:
            "https://apiv2.projectleannation.com/public/webhooks/apple/onsubscriptioncancel?email=",
          regularBilling: {
            amount: "1.50",
            type: "pending",
            label: "Lifestyle 12 Meal Weekly Plan",
            paymentTiming: "recurring",
            recurringPaymentStartDate: moment("2023-04-20").toDate(),
            recurringPaymentIntervalUnit: "day",
            recurringPaymentIntervalCount: 7,
            recurringPaymentEndDate: moment("2024-04-22").toDate(),
          },
        },
        requiredBillingContactFields: [
          "email",
          "name",
          "phone",
          "postalAddress",
        ],
        requiredShippingContactFields: ["email", "phone"],
        supportedCountries: ["US"],
        merchantCapabilities: [
          "supports3DS",
          "supportsCredit",
          "supportsDebit",
        ], // Make sure NOT to include supportsEMV here
        total: { label: "Lifestyle 12 Meal Weekly Plan", amount: "1.50" },
      };

      //  console.log(payRequest);

      let paySession: ApplePaySession = new win.ApplePaySession(14, payRequest);

      paySession.onvalidatemerchant = (event) => {
        //    console.log(event);

        this.performApplePayMerchantValidation(event?.validationURL).then(
          (merchantSession) => {
            //    console.log('starting merchant validation completion.');

            paySession.completeMerchantValidation(merchantSession);
          },
          (e) => {
            console.error(e);
          }
        );
      };

      paySession.onpaymentmethodselected = (event) => {
        console.log("starting onpaymentmethodselected");
        console.log(event);

        let lineItem: any = {
          amount: "1.50",
          type: "pending",
          label: "Lifestyle 12 Meal Weekly Plan",
          paymentTiming: "recurring",
          recurringPaymentStartDate: moment("2023-04-20")
            .toDate()
            .toISOString(),
          recurringPaymentIntervalUnit: "day",
          recurringPaymentIntervalCount: 7,
          recurringPaymentEndDate: moment("2024-04-22").toDate().toISOString(),
          paymentDescription: "Weekly 12-Meal Plan. Pause & cancel anytime.",
          billingAgreement: `Lifestyle 12-Meal Weekly Plan. Pause & cancel anytime. Using the promotion HFN30OFF, you'll receive $15 off each of your first two orders. Payment is processed weekly on Saturday, with your first payment starting on April 22, 2023. `,
          managementURL: "https://account.projectleannation.com",
        };

        let newLineItems = [lineItem];

        paySession.completePaymentMethodSelection(lineItem, newLineItems);
      };

      paySession.onpaymentauthorized = (event) => {
        //   console.log('starting session.onpaymentauthorized');
        //   console.log(event);

        let billingContact: any = event.payment.billingContact;
        let shippingContact: any = event.payment.shippingContact;

        let address1: any = billingContact?.addressLines?.length
          ? billingContact.addressLines[0]
          : "";
        let address2: any =
          billingContact?.addressLines?.length >= 2
            ? billingContact.addressLines[1]
            : "";
        let city = billingContact?.locality;
        let state = billingContact?.administrativeArea;
        let zip = billingContact?.postalCode;
        let name = billingContact?.givenName + " " + billingContact?.familyName;

        let shippingAddress1 = address1;
        let shippingAddress2 = address2;
        let shippingCity = city;
        let shippingState = state;
        let shippingZip = zip;

        let contactInformation: any = {
          email: checkoutSession?.contactInformation?.email
            ? checkoutSession?.contactInformation?.email
            : shippingContact.emailAddress,
          phone: checkoutSession?.contactInformation?.phone
            ? checkoutSession?.contactInformation?.phone
            : shippingContact.phoneNumber,
          name: name,
          billingAddress: {
            address1,
            address2,
            city,
            state,
            zip,
          },
          shippingAddress: {
            name,
            address1: shippingAddress1,
            address2: shippingAddress2,
            city: shippingCity,
            state: shippingState,
            zip: shippingZip,
          },
        };

        checkoutSession.contactInformation = contactInformation;

        let promise = this.finalizeApplePayCheckout(
          event.payment.token,
          checkoutSession
        );
        promise.then((success: any) => {
          var status;
          if (success) {
            status = ApplePaySession.STATUS_SUCCESS;
            //  console.log('Apple Pay Payment SUCCESS ');
          } else {
            status = ApplePaySession.STATUS_FAILURE;
          }
          //console.log("result of sendPaymentToken() function =  " + success);
          paySession.completePayment(status);
        });
      };

      paySession.oncancel = function (event) {
        // console.log('starting session.cancel');
        //  console.log(event);
      };

      paySession.begin();
    }
  }

  static initGooglePayInstance(
    store: any = null,
    ref: any = null,
    checkoutSession: any = {}
  ) {
    if (!store) {
      return;
    }

    let baseRequest: any = {
      apiVersion: 2,
      apiVersionMinor: 0,
    };

    const isReadyToPayRequest = Object.assign({}, baseRequest);
    isReadyToPayRequest.allowedPaymentMethods = [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["AMEX", "DISCOVER", "MASTERCARD", "VISA"],
          allowPrepaidCards: false,
          billingAddressRequired: true,
          billingAddressParameters: {
            format: "FULL",
            phoneNumberRequired: true,
          },
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "authorizenet",
            gatewayMerchantId:
              store?.operations?.billingAccount?.gatewayMerchantID,
          },
        },
      },
    ];

    /*isReadyToPayRequest.allowedPaymentMethods = ['CARD', 'TOKENIZED_CARD'];
        isReadyToPayRequest.cardRequirements = {
            allowedCardNetworks: [
                'AMEX',
                'DISCOVER', 'MASTERCARD',
                "VISA"
            ],
            billingAddressRequired: true,
            billingAddressFormat: 'FULL',
        };
        isReadyToPayRequest.phoneNumberRequired = true;
        isReadyToPayRequest.paymentMethodTokenizationParameters = {
            tokenizationType: 'PAYMENT_GATEWAY',
            parameters: {
                'gateway': 'authorizenet',
                "gatewayMerchantId": store?.operations?.billingAccount?.gatewayMerchantID
            }
        };*/

    const win: any = window;
    const paymentsClient: any = win.googlePayClient;

    if (!paymentsClient) {
      return;
    }

    paymentsClient
      .isReadyToPay(isReadyToPayRequest)
      .then((response: any) => {
        //     console.log(response);

        if (response.result) {
          // add a Google Pay payment button

          const button = paymentsClient.createButton({
            onClick: () => {
              this.startGooglePayCheckout(store, checkoutSession);
            },
            allowedPaymentMethods: ["CARD"],
            buttonType: "plain",
            buttonSizeMode: "fill",
          }); // same payment methods as for the loadPaymentData() API call

          ref.current.innerHTML = "";

          ref?.current?.appendChild(button);
        }
      })
      .catch((err: any) => {
        // show error in developer console for debugging
        console.error(err);
      });
  }

  static async startGooglePayCheckout(store: any, checkoutSession: any = {}) {
    //   console.log('starting google pay checkout');

    let baseRequest: any = {
      emailRequired: true,
      apiVersion: 2,
      apiVersionMinor: 0,
    };

    const paymentDataRequest: any = Object.assign({}, baseRequest);

    paymentDataRequest.allowedPaymentMethods = [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["AMEX", "DISCOVER", "MASTERCARD", "VISA"],
          allowPrepaidCards: false,
          billingAddressRequired: true,
          billingAddressParameters: {
            format: "FULL",
            phoneNumberRequired: true,
          },
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "authorizenet",
            gatewayMerchantId:
              store?.operations?.billingAccount?.gatewayMerchantID,
          },
        },
      },
    ];

    paymentDataRequest.transactionInfo = {
      totalPriceStatus: "ESTIMATED",
      totalPrice: "1.00",
      currencyCode: "USD",
      countryCode: "US",
      totalPriceLabel: "Subscription Total",
    };

    paymentDataRequest.merchantInfo = {
      merchantName: "Project LeanNation",
      //merchantId: '',
    };

    /*const paymentDataRequest: any = Object.assign({}, baseRequest);

        paymentDataRequest.allowedPaymentMethods = ['CARD', 'TOKENIZED_CARD'];
        paymentDataRequest.cardRequirements = {
            allowedCardNetworks: [
                'AMEX',
                'DISCOVER', 'MASTERCARD',
                "VISA"
            ],
            billingAddressRequired: true,
            billingAddressFormat: 'FULL',
        };
        paymentDataRequest.phoneNumberRequired = true;
        paymentDataRequest.paymentMethodTokenizationParameters = {
            tokenizationType: 'PAYMENT_GATEWAY',
            parameters: {
                'gateway': 'authorizenet',
                "gatewayMerchantId": store?.operations?.billingAccount?.gatewayMerchantID
            }
        };

        paymentDataRequest.transactionInfo = {
            totalPriceStatus: 'ESTIMATED',
            totalPrice: '1.00',
            currencyCode: 'USD',
            countryCode: 'US',
            totalPriceLabel: 'Subscription Total',

        };

        paymentDataRequest.merchantName = 'Project LeanNation';*/

    const win: any = window;
    const paymentsClient: any = win.googlePayClient;

    paymentsClient
      .loadPaymentData(paymentDataRequest)
      .then((paymentData: any) => {
        // if using gateway tokenization, pass this token without modification
        let paymentToken: any = paymentData;

        //  console.log(paymentToken);

        this.finalizeGooglePayCheckout(paymentData, checkoutSession);
      })
      .catch((err: any) => {
        // show error in developer console for debugging
        console.error(err);
      });
  }

  /**
   * Finalizes Google Pay checkout session
   *
   * @param paymentData
   * @param checkoutSession
   * @returns
   */
  static finalizeGooglePayCheckout(
    paymentData: any = null,
    checkoutSession: any = null
  ) {
    return new Promise(function (resolve, reject) {
      //     console.log('starting function sendPaymentToken()');

      const token = paymentData;

      //   console.log(token); //debug

      //let objJsonStr = JSON.stringify(token);

      //console.log(objJsonStr);

      let objJsonB64: any = null;

      //console.log(objJsonStr);
      try {
        objJsonB64 = window.btoa(token);
        //     console.log(objJsonB64);
      } catch (e) {
        console.error(e);
      }

      // TODO: perform full checkout here

      let opaqueData = {
        dataDesc: "COMMON.GOOGLE.INAPP.PAYMENT",
        dataValue: objJsonB64,
      };

      //  console.log(opaqueData);

      /* Send Payment token to Payment Gateway, here its defaulting to True just to mock that part */

      API.applePayTest(opaqueData).then(
        (data: any) => {
          //     console.log(data);
        },
        (e) => {
          console.error(e);
        }
      );

      return resolve(true);
    });
  }

  static async loadStripeClient(email: any) {
    let stripeOptions = {
      cssSrc: "https://fonts.googleapis.com/css?family=Karla",
      loader: "always",
    };

    API.getStripeCheckoutIntent(email)
      .then(
        (data: any) => {
          //console.log(data.data);

          let opts: any = _.clone(stripeOptions);

          opts.clientSecret = data.data?.data.clientSecret;

          if (typeof window !== "undefined") {
            let win: any = window;

            win.STRIPE_OPTIONS = opts;
            win.STRIPE_CUSTOMER_ID = data.data.data.stripeCustomerID;

            //console.log("STRIPE RESTORE", win.STRIPE_OPTIONS, win.STRIPE_CUSTOMER_ID);
          }

          PubSub.publish(Event.STRIPE_CLIENT_LOADED, {
            stripeOptions: opts,
            stripeCustomerID: data.data.data.stripeCustomerID,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {});
  }
}
