import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonModal,
  IonPage,
  IonPopover,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";
import "./SchedulingCalendar.css";

import moment from "moment-timezone";
import _ from "underscore";
import { chevronBack, chevronForward, timeOutline } from "ionicons/icons";
import SchedulingCalendar from "./SchedulingCalendar";
import SchedulingHours from "../SchedulingHours/SchedulingHours";
import SchedulingForm from "../SchedulingForm/SchedulingForm";
import StringUtils from "../../../lib/StringUtils";
import API from "../../../lib/API";
import StorageUtils from "../../../lib/StorageUtils";
import AppointmentInformation from "../AppointmentInformation/AppointmentInformation";
import plnSymbol from "../../../assets/img/logo/pln_symbol.svg";
import Constants from "../../../lib/Constants";
import Event from "../../../lib/Event";
import PubSub from "../../../lib/PubSub";
import { Capacitor } from "@capacitor/core";

const InlineSchedulingModal: React.FC<{
  store?: any;
  appointmentType?: any;
  existingAppointment?: any;
  forceType?: boolean;
  forceStack?: boolean;
  fields?: any;
  buttonText?: any;
  requiredFields?: any;
  lead?: any;
  customer?: any;
  presentingElement?: any;
  onSave?: any;
  onCancel?: any;
  title?: any;
  clearOnClose?: any;
  nativeMode?: any;
  id?: any;
  event?: any;
}> = ({
  appointmentType = null,
  existingAppointment = null,
  store = null,
  forceType = false,
  forceStack = false,
  fields = ["name", "email", "phone", "goal", "notes"],
  buttonText = "Schedule",
  requiredFields = [],
  lead = null,
  customer = null,
  presentingElement = null,
  onSave = null,
  onCancel = "",
  title = "Schedule Consultation",
  clearOnClose = false,
  nativeMode = false,
  id = null,
  event = "",
}) => {
  const modalRef = useRef<HTMLIonModalElement>(null);
  const [consultationDate, setConsultationDate] = useState<any>(null);
  const [consultationDateTime, setConsultationDateTime] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [appointment, setAppointment] = useState<any>(null);
  const [error, setErrorMessage] = useState("");
  const [formValues, setFormValues] = useState<any>({
    name: "",
    email: "",
    phone: "",
    notes: "",
    goal: "",
  });

  useEffect(() => {
    PubSub.subscribe(
      event ? event : Event.MODAL.SCHEDULING_MODAL,
      (evt: any) => {
        console.log(evt);

        if (evt?.type == "close") {
          return modalRef?.current?.dismiss();
        }

        if (id && evt?.id == id) {
          modalRef?.current?.present();
        }
      }
    );
  }, []);

  useEffect(() => {
    if (!modalRef?.current) {
      return;
    }

    modalRef?.current?.onDidDismiss().then(() => {
      if (clearOnClose) {
        setAppointment(null);
        setConsultationDate(null);
        setConsultationDateTime(null);
      }
    });
  }, [modalRef?.current]);

  const setError = (msg: any) => {
    if (!msg) {
      setErrorMessage(msg);

      return;
    }

    setErrorMessage(msg);

    setTimeout(() => {
      setError("");
    }, 5000);
  };

  const schedule = async () => {
    setSubmitting(true);

    let phone = formValues?.phone
      ? StringUtils.formatPhoneForServer(formValues?.phone)
      : "";

    let email = formValues?.email ? formValues?.email : "";
    let name = formValues?.name ? formValues?.name : "";

    if (!email && lead?.email) {
      email = lead?.email;
    }

    if (!email && customer?.email) {
      email = customer?.email;
    }

    if (!name && lead?.name) {
      name = lead?.name;
    }

    if (!name && customer?.name) {
      name = customer?.name;
    }

    if (!phone && lead?.phone) {
      phone = lead?.phone;
    }

    if (!phone && customer?.phone) {
      phone = customer?.phone;
    }

    let payload: any = {
      leadID: lead?._id ? lead?._id : null,
      customerID: customer?._id ? customer?._id : null,
      appointmentType: appointmentType?.APPOINTMENT_TYPE,
      dateTime: consultationDateTime,
      name: name,
      email: email,
      phone: phone,
      notes: formValues?.notes ? formValues?.notes : "",
      goal: formValues?.goal ? formValues?.goal : "",
      forceAppointmentType: forceType ? true : false,
      captchaToken:
        "Uot9isGEnMoyGRFRzyaorxGUR3reqddSUVRv2Acn3tsGuHmITP8Cka72rTipj60ogaW",
    };

    API.scheduleAppointment(store?._id, payload)
      .then(
        async (data: any) => {
          // console.log(data?.data?.data);

          setAppointment(data?.data?.data?.appointment);

          /*await StorageUtils.set(
            "checkout_appointment",
            data?.data?.data?.appointment
          );*/

          if (typeof onSave == "function") {
            onSave(data?.data?.data?.appointment);
          }
        },
        (err) => {
          setError(
            `Unable to schedule: ${
              err?.response?.data?.message
                ? err?.response?.data?.message
                : "Unknown error occurred."
            } Please try again`
          );
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const hasAllFields = () => {
    let hasAll = true;

    if (!requiredFields?.length) {
      return true;
    }

    requiredFields?.forEach((field: any) => {
      if (!formValues[field]) {
        hasAll = false;
      }
    });

    return hasAll;
  };

  return (
    <>
      <IonModal
        ref={modalRef}
        canDismiss={true}
        mode={
          !nativeMode ? "ios" : Capacitor.getPlatform() == "ios" ? "ios" : "md"
        }
        className="modal-inlinescheduler"
      >
        <IonHeader>
          <IonToolbar
            mode={
              !nativeMode
                ? "ios"
                : Capacitor.getPlatform() == "ios"
                ? "ios"
                : "md"
            }
            className={
              nativeMode ? "modal-toolbar-native" : "box-modal-toolbar-web"
            }
            style={{ borderBottom: "none" }}
          >
            {!appointment && (
              <IonButtons slot="start">
                <IonButton
                  color="dark"
                  onClick={() => {
                    modalRef?.current?.dismiss();

                    setConsultationDate(null);

                    setConsultationDateTime(null);
                  }}
                >
                  Cancel
                </IonButton>
              </IonButtons>
            )}
            <IonTitle>{title ? title : "Schedule Consultation"}</IonTitle>

            <IonButtons slot="end">
              <IonButton
                color={appointment ? "dark" : "primary"}
                onClick={() => {
                  if (appointment) {
                    modalRef?.current?.dismiss();

                    setAppointment(null);

                    setConsultationDate(null);

                    setConsultationDateTime(null);

                    return;
                  }

                  schedule();
                }}
                disabled={
                  !hasAllFields() ||
                  !consultationDate ||
                  !consultationDateTime ||
                  submitting
                }
              >
                {submitting ? (
                  <IonSpinner name="dots"></IonSpinner>
                ) : (
                  <>
                    {appointment ? (
                      <>Done</>
                    ) : (
                      <>{buttonText ? buttonText : "Schedule"}</>
                    )}
                  </>
                )}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="ion-padding">
            <div className="border p-3 mb-4 mt-1" style={{ borderRadius: 6 }}>
              <IonRow className="ion-align-items-center">
                <IonCol size-xs="auto" className="py-0 pl-0">
                  <img
                    src={plnSymbol}
                    alt="PLN Symbol"
                    width="40px"
                    height="40px"
                    style={{
                      height: 40,
                      width: 40,
                      position: "relative",
                      top: 2,
                      marginTop: 1,
                      marginBottom: 1,
                    }}
                  ></img>
                </IonCol>
                <IonCol size-xs="" className="py-0 pr-0 pl-2">
                  <h5 className="text-dark m-0 font-weight-bold">
                    {store?.name?.replace("Project LeanNation", "PLN").trim()}
                  </h5>
                  <p className="m-0" style={{ fontSize: 14 }}>
                    {store?.location?.address?.formattedAddress}
                  </p>
                </IonCol>
              </IonRow>
            </div>
            <p
              className="mt-0 mb-2 text-medium font-weight-500"
              style={{ fontSize: 14 }}
            >
              <span style={{ position: "relative", top: 3 }} className="pr-1">
                <IonIcon icon={timeOutline}></IonIcon>
              </span>

              {appointmentType.DURATION_READABLE}
            </p>
            <h4 className="text-dark mt-0">{appointmentType.NAME}</h4>
            <p className="m-0 text-medium" style={{ lineHeight: 1.5 }}>
              {appointmentType.DESCRIPTION}
            </p>
          </div>
          <div className="ion-padding">
            <IonGrid className="p-0">
              {appointment ? (
                <>
                  <AppointmentInformation
                    appointment={appointment}
                    onChange={(appointment: any) => {
                      setAppointment(appointment);
                    }}
                    forceStack={forceStack}
                    onCancel={(appointment: any) => {
                      if (typeof onCancel == "function") {
                        onCancel(appointment);
                      }
                    }}
                  ></AppointmentInformation>
                </>
              ) : (
                <>
                  <IonRow>
                    {error ? (
                      <IonCol size="12">
                        <p className="text-danger mb-3 mt-0">{error}</p>
                      </IonCol>
                    ) : null}
                    <IonCol
                      size-xs="12"
                      size-sm="12"
                      size-md={forceStack ? "12" : "7"}
                      size-lg={forceStack ? "12" : "8"}
                      className={`pl-0 pr-0 ${forceStack ? "" : "pr-md-2"}`}
                    >
                      <SchedulingCalendar
                        store={store}
                        appointmentType={appointmentType}
                        value={consultationDate}
                        onDateSelected={(date: any) => {
                          setConsultationDate(date);

                          if (consultationDate !== date) {
                            setConsultationDateTime(null);
                          }
                        }}
                      ></SchedulingCalendar>
                    </IonCol>
                    <IonCol
                      className={`pr-0 pl-0 ${
                        forceStack ? "" : "pl-md-2 mt-md-0"
                      } mt-3`}
                      size-xs="12"
                      size-sm="12"
                      size-md={forceStack ? "12" : "5"}
                      size-lg={forceStack ? "12" : "4"}
                    >
                      <SchedulingHours
                        maxHeight={forceStack ? null : 398}
                        store={store}
                        appointmentType={appointmentType}
                        showContinueButton={false}
                        onContinue={() => {}}
                        value={consultationDateTime}
                        onDateSelected={(date: any) => {
                          const mom = moment(consultationDate);

                          const time = moment(date);

                          mom
                            .hours(time.hours())
                            .minutes(time.minutes())
                            .seconds(0)
                            .milliseconds(0);

                          setConsultationDateTime(mom?.toDate());
                        }}
                        viewingDate={consultationDate}
                      ></SchedulingHours>
                    </IonCol>
                    <IonCol size-xs="12" className="px-0 mt-3">
                      <SchedulingForm
                        appointmentType={appointmentType}
                        title="Additional Information"
                        fields={fields}
                        requiredFields={requiredFields}
                        onChange={(vals: any) => {
                          setFormValues(vals);

                          // console.log(vals);
                        }}
                        value={formValues}
                      ></SchedulingForm>
                    </IonCol>
                  </IonRow>
                </>
              )}
            </IonGrid>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default InlineSchedulingModal;
