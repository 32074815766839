/**
 * Basic Pub/Sub protocol
 * 
 * Author: Brandon Hudson
 * Created: 12-15-2019
 */

class PubSub {
    static registry: any = {}

    /**
     * Publish to a topic
     * 
     * @param name 
     * @param data 
     */
    static publish(name: any = '', data: any = null) {
        if (!this.registry[name]) return;
        this.registry[name].forEach((func: any) => {
            func.call(null, data);
        });
    }

    /**
     * Subscribe to a topic
     * 
     * @param name 
     * @param fn 
     */
    static subscribe(name: any = '', fn: any = null) {
        if (!this.registry[name]) {
            this.registry[name] = [fn];
        } else {
            this.registry[name].push(fn);
        }
    }
}

if (typeof window !== 'undefined') {
    const win: any = window;

    win.PubSub = PubSub;
}

export default PubSub;
