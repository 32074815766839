import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlide,
  IonSlides,
  IonAccordionGroup,
  IonAccordion,
  IonCol,
  IonRow,
  IonGrid,
} from "@ionic/react";
import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router";

import chicken from "../assets/img/icons/protein_chicken.svg";
import beef from "../assets/img/icons/protein_beef.svg";
import pork from "../assets/img/icons/protein_pork.svg";
import turkey from "../assets/img/icons/protein_turkey.svg";
import plant from "../assets/img/icons/protein_plant.svg";
import egg from "../assets/img/icons/protein_egg.svg";
import other from "../assets/img/icons/protein_other.svg";
import fish from "../assets/img/icons/protein_fish.svg";

import chickenSelected from "../assets/img/icons/protein_chicken_selected.svg";
import beefSelected from "../assets/img/icons/protein_beef_selected.svg";
import porkSelected from "../assets/img/icons/protein_pork_selected.svg";
import turkeySelected from "../assets/img/icons/protein_turkey_selected.svg";
import plantSelected from "../assets/img/icons/protein_plant_selected.svg";
import eggSelected from "../assets/img/icons/protein_egg_selected.svg";
import otherSelected from "../assets/img/icons/protein_other_selected.svg";
import fishSelected from "../assets/img/icons/protein_fish_selected.svg";

const CategoryFilterButton: React.FC<{
  category: string;
  selected?: boolean;
  onClick?: any;
  prefix?: string;
}> = ({ category = "", selected = false, onClick = null, prefix = "" }) => {
  return (
    <>
      <div
        style={{
          width: "100%",

          border: selected
            ? "2px solid var(--primary)"
            : "2px solid var(--midlighter)",
          borderRadius: 6,
          background: selected ? "var(--primary-ultralight)" : "var(--white)",
        }}
        className={`protein-filter-button p-2 text-center d-flex ion-align-items-center ion-justify-content-center cursor-pointer ${
          selected && "selected"
        }`}
        onClick={() => {
          if (typeof onClick == "function") {
            onClick(category, !selected);
          }
        }}
      >
        <div>
          <h2
            className={`${
              selected ? "text-primary" : "text-medium"
            } font-weight-bold m-0`}
            style={{ fontSize: 16 }}
          >
            {prefix}
            {category}
          </h2>
        </div>
      </div>
    </>
  );
};

export default CategoryFilterButton;
