export default class MapUtils {
    static calculateDistanceInMiles(
        lat1: number,
        lon1: number,
        lat2: number,
        lon2: number,
    ) {
        const toRad = (Value: number) => {
            return (Value * Math.PI) / 180;
        };

        var R = 6371; // km
        var dLat = toRad(lat2 - lat1);
        var dLon = toRad(lon2 - lon1);
        var lat1 = toRad(lat1);
        var lat2 = toRad(lat2);

        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var kilometers = R * c;

        // console.log(kilometers);


        // conversion factor
        const factor = 0.621371

        // calculate miles
        const miles = kilometers * factor

        return miles;
    }
}