import Event from "./Event";
import PubSub from "./PubSub";
import _ from "underscore";

export default class WindowUtils {
  static getDimensions() {
    if (window) {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height,
      };
    }

    return null;
  }

  static resizeListener() {
    setTimeout(() => {
      if (window) {
        window.addEventListener("resize", (e) => {
          const data = this.getDimensions();

          //console.log('HERE RESIZE');

          PubSub.publish(Event.WINDOW_RESIZE, data);
        });
      }
    }, 0);
  }

  static setQueryParam(key = "", value: any = null) {
    let out = typeof value == "object" ? JSON.stringify(value) : value;

    if (!key) {
      return;
    }

    if ("URLSearchParams" in window && window?.location) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, out);
      window.location.search = searchParams.toString();
    }
  }

  static setQueryParams(keyValuePairs: any = {}) {
    if ("URLSearchParams" in window && window?.location) {
      var searchParams = new URLSearchParams(window.location.search);

      let keys = _.keys(keyValuePairs);

      for (let i = 0; i < keys?.length; i++) {
        const key = keys[i];

        if (!key) {
          continue;
        }

        const value = keyValuePairs[key];

        let out = typeof value == "object" ? JSON.stringify(value) : value;

        searchParams.set(key, out);
      }

      window.location.search = searchParams.toString();
    }
  }

  static clearQueryParams() {
    if ("URLSearchParams" in window && window?.location) {
      var searchParams = new URLSearchParams();
      window.location.search = searchParams.toString();
    }
  }

  static getQueryParam(key: any = "") {
    if ("URLSearchParams" in window && window?.location) {
      var searchParams = new URLSearchParams(window.location.search);

      return searchParams.get(key);
    }

    return null;
  }

  static logError(err: any = "") {
    const win: any = window;

    // console.log('logging error', err?.stack);

    if (win?.Rollbar) {
      //     console.log(err?.stack);

      // Callback functions
      win.Rollbar.error(err, function (e: any, data: any) {
        if (e) {
          console.log("Error while reporting error to Rollbar: ", e);
        } else {
          console.log(
            "Error successfully reported to Rollbar. UUID:",
            data.result.uuid
          );
        }
      });
    } else {
      console.error("Rollbar not defined: ", err);
    }
  }

  static supportFormatWebp() {
    var elem = document.createElement("canvas");

    if (!!(elem.getContext && elem.getContext("2d"))) {
      // was able or not to get WebP representation
      return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
    } else {
      // very old browser like IE 8, canvas not supported
      return false;
    }
  }
}
