import PubSub from "../../lib/PubSub";
import React from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export const geocodeResult = (address: any) => {
  let result: any = null;

  return new Promise((resolve, reject) => {
    geocodeByAddress(address)
      .then((results: any) => {
        result = results[0];

        getLatLng(result);
      })
      .then((latLng: any) => {
        console.log("Success", latLng);

        resolve({ result, geographic: latLng });
      })
      .catch((error: any) => reject(error));
  });
};

export default class LocationSearchInput extends React.Component<any> {
  state: any = {
    address: "",
  };

  componentDidMount(): void {
    PubSub.subscribe("locationSearchClear", () => {
      this.setState({ address: "" });
    });
  }

  handleChange = (address: any) => {
    this.setState({ address });

    if (typeof this.props.onChange == "function") {
      this.props.onChange({ address });
    }
  };

  handleSelect = (address: any) => {
    let result: any = null;

    geocodeByAddress(address)
      .then((results: any) => {
        result = results[0];

        return getLatLng(result);
      })
      .then((latLng: any) => {
        console.log("Success", latLng);

        if (typeof this.props.onSelect == "function") {
          this.props.onSelect({ result, geographic: latLng });
        }
      })
      .catch((error: any) => console.error("Error", error));
  };

  render() {
    return (
      <>
        {typeof window?.google?.maps?.places?.Autocomplete == "function" &&
        window?.google?.maps?.places?.Autocomplete ? (
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }: any) => (
              <div className="relative">
                <label
                  htmlFor="name"
                  className="absolute -top-[9px] sm:-top-[12px] rounded-sm left-2 inline-block bg-white px-1.5 text-sm sm:text-base font-medium text-gray-800 focus:text-orange-500"
                >
                  Enter Your{" "}
                  <span className="hidden md:inline">City Or Postal Code</span>
                  <span className="inline md:hidden">Location</span>
                  <span className="text-red-600 ml-[2px]">*</span>
                </label>
                <input
                  type="text"
                  autoComplete="postal-code"
                  name="location"
                  id="location"
                  className="block w-full text-base sm:text-xl px-3 rounded border-0 py-3 sm:py-4 text-gray-900 shadow-sm outline-0 border-[1px] border-gray-100 placeholder:text-gray-400 transition-all focus:border-orange-500 sm:leading-6"
                  placeholder="New York, NY"
                  {...getInputProps({
                    placeholder: "Search Nearby",
                  })}
                />
                <div
                  className={`absolute w-[86vw] -left-[16vw] sm:left-auto sm:w-full ${
                    loading || suggestions?.length
                      ? "rounded border border-gray-100 bg-white shadow top-[66px] z-[999] max-h-[15rem] overflow-y-auto"
                      : ""
                  }`}
                >
                  {loading && (
                    <div className="p-[0.75rem] text-base md:text-lg font-normal">
                      Searching...
                    </div>
                  )}
                  {!loading &&
                    suggestions.map((suggestion: any, i: any) => {
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          key={i}
                          {...getSuggestionItemProps(suggestion, {
                            className:
                              "p-[0.75rem] text-base md:text-lg font-normal",
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        ) : (
          <div className="relative">
            <label
              htmlFor="name"
              className="absolute rounded -top-[9px] sm:-top-[12px] left-2 inline-block bg-white px-1.5 text-sm sm:text-base font-medium text-gray-400"
            >
              Enter Your{" "}
              <span className="hidden md:inline">City Or Postal Code</span>
              <span className="inline md:hidden">Location</span>
              <span className="ml-[2px]">*</span>
            </label>
            <div className="px-3 bg-gray-25 sm:px-4 text-gray-500 block w-full text-base sm:text-xl rounded border-0 py-3 sm:py-4 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 transition-all focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:leading-6">
              Loading...
            </div>
          </div>
        )}
      </>
    );
  }
}
