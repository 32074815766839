import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

interface SEOHeadersProps {
  title?: string;
  description?: string;
  imageUrl?: string;
  url?: string;
  canonical?: string;
  type?: string;
  metaTags?: Array<any>;
  jsonldSchema?: any;
}

const SEOHeaders: React.FC<SEOHeadersProps> = ({
  title = "Sign Up & Manage Your Account",
  description = "Sign up for one of our meal plans and manage your subscription with ease.",

  imageUrl = "https://s3.amazonaws.com/cdn.projectleannation.com/assets/public-site/seo_primary_banner.jpg",
  url = "",
}) => {
  const win: any = window;
  const doc: any = document;

  const pageURL = useMemo(() => {
    if (url) {
      return url;
    }

    return win?.location?.href;
  }, [win?.location?.href, url]);

  const pageTitle = `${title} - Project LeanNation`;

  if (doc?.title != pageTitle) {
    setTimeout(() => {
      document.title = pageTitle;
    }, 1);
  }

  /**
   * Generates the JSON-LD Schema.org tags for the page
   *
   * @returns
   */
  const generateJSONLD = () => {
    return {
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: title,
      description: description,
      url: pageURL,
      image: imageUrl,
    };
  };

  return (
    <Helmet>
      {/* Basic HTML Meta tags */}
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="healthy meal plans, chef-prepared meals, customized meal plans, nutritional meals, health goals, inbody scanning, personalized goal & nutrition coaching, macro calculator, meal delivery, education and accountability"
      />

      {/* Canonical URL */}
      <link rel="canonical" href={pageURL} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={pageURL} />
      <meta property="og:title" content={`${title} - Project LeanNation`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:type" content="website" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@plnrochester" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />

      {/* JSON-LD Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(generateJSONLD())}
      </script>
    </Helmet>
  );
};

export default SEOHeaders;
