import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import "./Navbar.css";

import plnSymbol from "../../../assets/img/logo/pln_symbol.svg";
import {
  chatboxEllipses,
  chevronBackOutline,
  helpCircleOutline,
  menuOutline,
} from "ionicons/icons";
import PubSub from "../../../lib/PubSub";
import Event from "../../../lib/Event";
import moment from "moment-timezone";
import _ from "underscore";
import StringUtils from "../../../lib/StringUtils";
import StorageUtils from "../../../lib/StorageUtils";
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
} from "@novu/notification-center";

const Navbar: React.FC<{
  pageName?: any;
  showBackButton?: boolean;
  showMenuButton?: boolean;
  onGoBack?: any;
  onOpenMenu?: any;
  store?: any;
  allowNavigation?: boolean;
  actionButton?: any;
}> = ({
  onOpenMenu = null,
  showBackButton = false,
  pageName = null,
  onGoBack = null,
  showMenuButton = true,
  store = null,
  allowNavigation = true,
  actionButton = null,
}) => {
  const [coupon, setCoupon] = useState(false);
  const [couponData, setCouponData] = useState<any>(null);
  const [showCoupon, setShowCoupon] = useState(true);
  const [isOpen, setIsOpen] = useState<any>(false);
  const [openingTime, setOpeningTime] = useState<any>(null);
  const [closingTime, setClosingTime] = useState<any>(null);
  const [openingTimeString, setOpeningTimeString] = useState<any>(null);
  const [closingTimeString, setClosingTimeString] = useState<any>(null);
  const history = useHistory();

  const todayHours = useMemo(() => {
    let today = moment().format("dddd");

    if (!store?.operations?.hours?.standard?.length) {
      return null;
    }

    const todayHours = _.findWhere(store?.operations?.hours?.standard, {
      day: today,
    });

    return todayHours ? todayHours : null;
  }, [store]);

  useEffect(() => {
    PubSub.subscribe(Event.CHECKOUT.COUPON_UPDATED, (data: any) => {
      const coupon = data?.coupon;
      // console.log("COUPON", coupon);

      const forceShow = data?.forceShow;

      if (coupon && couponData?.code == coupon?.code) {
        return;
      }

      setCouponData(coupon);

      if (
        coupon &&
        (sessionStorage.getItem("checkout_show_coupon") !== "false" ||
          forceShow)
      ) {
        setCoupon(true);
        setShowCoupon(true);
      } else {
        setCoupon(false);
      }
    });

    StorageUtils.get("checkout_coupon").then(async (data) => {
      if (sessionStorage.getItem("checkout_show_coupon") == "false") {
        return setShowCoupon(false);
      }

      const referral = await StorageUtils.get("checkout_referral");

      if (data) {
        setCouponData(data);
        setCoupon(true);
      } else if (referral) {
        setCouponData(referral);
        setCoupon(true);
      }
    });
  }, []);

  useEffect(() => {
    if (
      !todayHours ||
      todayHours?.closed ||
      !todayHours?.opensAt ||
      !todayHours?.closesAt
    ) {
      setIsOpen(false);
      setOpeningTime(null);
      setClosingTime(null);
      setOpeningTimeString(null);
      setClosingTimeString(null);

      return;
    }

    const now = new Date();

    const { opensAt, closesAt } = todayHours;

    const todayDateString = moment().format("YYYY-MM-DD");

    const openingTime = store?.timezone
      ? moment.tz(`${todayDateString}T${opensAt}:00`, store?.timezone)
      : moment(`${todayDateString}T${opensAt}:00`);

    const closingTime = store?.timezone
      ? moment.tz(`${todayDateString}T${closesAt}:00`, store?.timezone)
      : moment(`${todayDateString}T${closesAt}:00`);

    const isOpen = now >= openingTime.toDate() && now <= closingTime.toDate();

    setIsOpen(isOpen);
    setOpeningTime(openingTime);
    setClosingTime(closingTime);
    setOpeningTimeString(openingTime.format("h:mma"));
    setClosingTimeString(closingTime.format("h:mma"));
  }, [todayHours, store]);

  const renderCouponTerms = (
    coupon: any,
    truncated: boolean = true,
    muted: boolean = false
  ) => {
    if (!coupon) {
      return "";
    }

    if (coupon?.couponUsage == "tiered") {
      let tierStrings = [];

      for (let i = 0; i < coupon?.couponTiers?.length; i++) {
        const tier = coupon?.couponTiers[i];

        let intro =
          i == 0
            ? "your first"
            : i == coupon?.couponTiers?.length - 1
            ? `${tier.maxNumberUses == 9999 ? "all " : ""}your following`
            : "your next";
        let value = "";
        let count = `${
          tier.maxNumberUses != 9999 ? tier.maxNumberUses + " " : ""
        }orders`;

        if (tier.maxNumberUses == 1) {
          count = "order";
        }

        if (tier.discountType == "Flat") {
          value = StringUtils.centsToCurrency(tier?.flatDiscount);
        } else {
          value = tier?.percentDiscount * 100 + "%";
        }

        tierStrings.push(
          <>
            {i == coupon?.couponTiers?.length - 1 ? "and " : ""}
            <span
              className={`${
                !muted &&
                "font-weight-bold text-uppercase text-white text-underline"
              }`}
            >
              {value}&nbsp;off
            </span>
            &nbsp;
            {intro}&nbsp;{count}
          </>
        );
      }

      return (
        <>
          {!truncated && <>You'll receive&nbsp;</>}
          {tierStrings?.map((string, i) => (
            <>
              {string}
              {i != tierStrings?.length - 1 ? ", " : ""}
            </>
          ))}
          .
        </>
      );
    } else {
      return (
        <>
          {!truncated && <>You'll receive</>}
          <span className="font-weight-bold text-uppercase text-white text-underline">
            {" "}
            {coupon?.discountType == "Flat"
              ? StringUtils.centsToCurrency(coupon?.flatDiscount)
              : coupon?.percentDiscount * 100 + "%"}{" "}
            off
          </span>{" "}
          {coupon?.removeFromSubscriptionAfterUses == -1
            ? "your subscription."
            : ""}
          {coupon?.removeFromSubscriptionAfterUses == 1
            ? "your first order."
            : ""}
          {coupon?.removeFromSubscriptionAfterUses > 1
            ? `each of your first ${coupon?.removeFromSubscriptionAfterUses} boxes.`
            : ""}
        </>
      );
    }
  };

  const getIcon = (iconName = "") => {
    let fill = "var(--dark)";

    let icon: any = null;

    if (iconName == "welcome") {
      icon = (
        <svg
          style={{
            height: 24,

            marginRight: 6,
            position: "relative",
          }}
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M12,3L20,9V21H15V14H9V21H4V9L12,3Z" />
        </svg>
      );
    }

    if (iconName == "plans") {
      icon = (
        <svg
          style={{
            height: 25,
            marginRight: 6,
            position: "relative",
          }}
          fill={fill}
          viewBox="0 0 24 24"
        >
          <path d="M20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20M4,6V18H20V6H4M6,9H18V11H6V9M6,13H16V15H6V13Z" />
        </svg>
      );
    }

    if (iconName == "meals") {
      icon = (
        <svg
          style={{
            height: 22,
            marginRight: 6,
            position: "relative",
            top: 1,
          }}
          fill={fill}
          viewBox="0 0 24 24"
        >
          <title>silverware-fork-knife</title>
          <path d="M11,9H9V2H7V9H5V2H3V9C3,11.12 4.66,12.84 6.75,12.97V22H9.25V12.97C11.34,12.84 13,11.12 13,9V2H11V9M16,6V14H18.5V22H21V2C18.24,2 16,4.24 16,6Z" />
        </svg>
      );
    }

    if (iconName == "checkout") {
      icon = (
        <svg
          style={{
            height: 22,
            marginRight: 6,
            position: "relative",
          }}
          fill={fill}
          viewBox="0 0 24 24"
        >
          <title>cart</title>
          <path d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z" />
        </svg>
      );
    }

    return <>{icon}</>;
  };

  return (
    <IonHeader className="pln-navbar">
      <div className="border-b-[1px] border-gray-50">
        <div className={`px-2 bg-white py-2`}>
          <div
            style={{
              maxWidth: 1200,
              margin: "auto",
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            {showMenuButton ? (
              <>
                <div className="d-block d-md-none" style={{ width: 50 }}>
                  <IonMenuButton mode="ios" color="dark"></IonMenuButton>
                </div>
                <div
                  className="d-none d-md-flex"
                  style={{
                    width: 50,
                    height: 50,
                    // margin: "auto",

                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IonMenuButton mode="ios" color="dark"></IonMenuButton>
                </div>
              </>
            ) : null}
            {!showBackButton && !showMenuButton ? (
              <>
                <div style={{ width: 50 }}></div>
              </>
            ) : null}
            {showBackButton ? (
              <>
                <div className="d-block d-md-none" style={{ width: 50 }}>
                  <IonButton
                    mode="md"
                    fill="clear"
                    className="pln-navbar-button"
                    color="dark"
                    onClick={() => {
                      if (typeof onGoBack == "function") {
                        onGoBack();
                      }
                    }}
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={chevronBackOutline}
                    ></IonIcon>
                  </IonButton>
                </div>
                <div
                  className="d-none d-md-flex"
                  style={{
                    width: 50,
                    height: 50,
                    // margin: "auto",

                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IonButton
                    fill="clear"
                    className="pln-navbar-button ml-0"
                    color="dark"
                    onClick={() => {
                      if (typeof onGoBack == "function") {
                        onGoBack();
                      }
                    }}
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={chevronBackOutline}
                    ></IonIcon>
                  </IonButton>
                </div>
              </>
            ) : null}

            <div
              style={{
                flexGrow: 1,
                textAlign: "center",
              }}
            >
              {pageName ? (
                <>
                  <h3
                    className="text-dark font-weight-bold"
                    style={{ marginTop: 8, marginBottom: 8 }}
                  >
                    <div className="d-flex align-items-center ion-justify-content-center">
                      <div
                        style={{
                          lineHeight: 1.2,
                          maxWidth: "calc(100% - 100px)",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {pageName}
                      </div>
                    </div>
                  </h3>
                </>
              ) : (
                <img
                  src={plnSymbol}
                  alt="PLN Symbol"
                  width="40px"
                  height="40px"
                  style={{
                    height: 40,
                    width: 40,
                    position: "relative",
                    top: 2,
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                ></img>
              )}
            </div>

            <div
              style={{
                textAlign: "right",
                width: 50,
                display: "flex",
                justifyContent: "end",
                paddingRight: "0.5rem",
              }}
            >
              {actionButton ? <>{actionButton}</> : null}
            </div>
          </div>
        </div>
      </div>
    </IonHeader>
  );
};

export default Navbar;
