import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";
import "./SchedulingCalendar.css";

import moment from "moment-timezone";
import _ from "underscore";
import { chevronBack, chevronForward } from "ionicons/icons";

const SchedulingCalendar: React.FC<{
  store?: any;
  onDateSelected?: any;
  appointmentType?: any;
  value?: any;
}> = ({
  appointmentType = null,
  store = null,
  onDateSelected = null,
  value = null,
}) => {
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [minDate, setMinDate] = useState(moment().startOf("day").toDate());
  const [maxDate, setMaxDate] = useState(
    moment().add(60, "days").endOf("day").toDate()
  );

  const onChange = (date: any, event: any) => {
    setSelectedDate(date);

    if (typeof onDateSelected == "function") {
      onDateSelected(date);
    }
  };

  useEffect(() => {
    if (value != selectedDate) {
      setSelectedDate(value);
    }
  }, [value]);

  /**
   * Check if the store has hours on a specific day
   */
  const storeHasHours = useCallback(
    (day: any) => {
      if (!store) {
        return false;
      }

      if (day == 0) {
        day = "Sunday";
      } else if (day == 1) {
        day = "Monday";
      } else if (day == 2) {
        day = "Tuesday";
      } else if (day == 3) {
        day = "Wednesday";
      } else if (day == 4) {
        day = "Thursday";
      } else if (day == 5) {
        day = "Friday";
      } else if (day == 6) {
        day = "Saturday";
      }

      if (!store?.operations?.hours?.standard?.length) {
        return false;
      }

      let hours = _.findWhere(store?.operations?.hours?.standard, {
        day,
      });

      if (!hours || hours?.closed) {
        return false;
      }

      return true;
    },
    [store]
  );

  /**
   * Disable specific dates based on the store's hours
   */
  const disableDate = useCallback(
    ({ date, view }: any) => {
      if (!store) {
        return true;
      }

      const now = store?.timezone
        ? moment.tz(store?.timezone)?.startOf("day")
        : moment()?.startOf("day");
      const checkingDate = store?.timezone
        ? moment.tz(date, store?.timezone)
        : moment(date);

      // If the date is in the past, disable it
      if (checkingDate.isBefore(now)) {
        return true;
      }

      // Only allow booking 60 days in advance
      if (checkingDate.isAfter(moment().add(60, "days"))) {
        return true;
      }

      // If the store is closed on this day, disable it
      if (!storeHasHours(checkingDate.format("dddd"))) {
        return true;
      }

      const openingDate = moment(store?.operations?.storeOpening?.openingDate);

      if (checkingDate.isBefore(openingDate)) {
        return true;
      }

      return false;
    },
    [store]
  );

  return (
    <>
      <Calendar
        onClickDay={onChange}
        minDate={minDate}
        maxDate={maxDate}
        value={selectedDate}
        minDetail={"month"}
        tileDisabled={disableDate}
        nextLabel={
          <span style={{ position: "relative", top: 2, left: 1 }}>
            <IonIcon icon={chevronForward}></IonIcon>
          </span>
        }
        prevLabel={
          <span style={{ position: "relative", top: 2, left: -1 }}>
            <IonIcon icon={chevronBack}></IonIcon>
          </span>
        }
      />
    </>
  );
};

export default SchedulingCalendar;
