import { Capacitor } from "@capacitor/core";
import { Drivers, Storage } from "@ionic/storage";
import moment from "moment";

export default class StorageUtils {
  static _storage: Storage;

  static async init() {
    const drivers = Capacitor.isNativePlatform()
      ? [Drivers.IndexedDB, Drivers.LocalStorage]
      : [Drivers.IndexedDB, Drivers.LocalStorage];

    const storage = new Storage({
      name: "plndb",
      driverOrder: drivers,
    });

    await storage.create();

    this._storage = storage;

    return storage;
  }

  static async get(key: string, asString = false) {
    let val: any = await this._storage.get(key);

    if (asString) {
      return val;
    }

    if (val === null || val === undefined || val === "") {
      return null;
    }

    if (val === "null") {
      return null;
    }

    try {
      const json = JSON.parse(val);

      return json;
    } catch (e: any) {}

    if (val === "false") {
      return false;
    }

    if (val === "true") {
      return true;
    }

    try {
      let date = moment(val);

      if (date?.isValid()) {
        return date.toDate();
      }
    } catch (e) {}

    if (!isNaN(parseFloat(val)) && val?.indexOf(".") >= 0) {
      return parseFloat(val);
    }

    if (!isNaN(parseInt(val)) && val != "" && !val.includes("+")) {
      return parseInt(val);
    }

    return val;
  }

  static async set(key: string, val: any = null) {
    let out: any = val;

    if (typeof val == "object") {
      try {
        const json = JSON.stringify(val);

        out = json;
      } catch (e) {}
    }

    return await this._storage.set(key, out);
  }

  static async clear() {
    return await this._storage.clear();
  }

  static async keys() {
    return await this._storage.keys();
  }

  static async remove(key: string) {
    return await this._storage.remove(key);
  }
}
