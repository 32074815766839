import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonModal,
  IonNote,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import API from "../lib/API";

import StringUtils from "../lib/StringUtils";

import chicken from "../assets/img/icons/protein_chicken_white.svg";
import beef from "../assets/img/icons/protein_beef_white.svg";
import pork from "../assets/img/icons/protein_pork_white.svg";
import turkey from "../assets/img/icons/protein_turkey_white.svg";
import plant from "../assets/img/icons/protein_plant_white.svg";
import egg from "../assets/img/icons/protein_egg_white.svg";
import other from "../assets/img/icons/protein_other_white.svg";
import fish from "../assets/img/icons/protein_fish_white.svg";
import glutenFree from "../assets/img/icons/gluten_free.svg";
import _ from "underscore";

import myfitnesspal from "../assets/img/icons/myfitnesspal.svg";
import LinkUtils from "../lib/LinkUtils";
import {
  checkmarkCircle,
  chevronForward,
  star,
  starOutline,
} from "ionicons/icons";
import StorageUtils from "../lib/StorageUtils";
import WindowUtils from "../lib/WindowUtils";
import { Capacitor } from "@capacitor/core";
import MealOptionCardNoSelection from "./MealOptionCardNoSelection";
import MealOptionCard from "./MealOptionCard";
import MealDetailModal from "./MealDetailModal";
import Constants from "../lib/Constants";

const MealDetailContent: React.FC<{
  meal: any;
  showReview?: boolean;
  showQuantity?: boolean;
  presentingElement?: any;
  skeleton?: boolean;
  showReturnToApp?: boolean;
  nativeMode?: boolean;
  allowFavorite?: boolean;
  favorites?: any;
  onFavoriteChange?: any;
  modalID?: string;
}> = ({
  meal = null,
  showReview = false,
  showQuantity = false,
  presentingElement = null,
  skeleton = false,
  showReturnToApp = false,
  nativeMode = false,
  allowFavorite = false,
  favorites = [],
  onFavoriteChange = () => {},
  modalID = "",
}) => {
  const w: any = window;
  const settingsDesktopRef: any = useRef();
  const settingsRef: any = useRef();
  const [rating, setRating] = useState<any>(null);
  const [comments, setComments] = useState<any>("");
  const [heatingMicrowave, setHeatingMicrowave] = useState<any>(false);
  const [heatingOther, setHeatingOther] = useState<any>(false);
  const [heatingAirFryer, setHeatingAirFryer] = useState<any>(false);
  const [heatingOven, setHeatingOven] = useState<any>(false);
  const [heatingStove, setHeatingStove] = useState<any>(false);
  const [submitting, setSubmitting] = useState<any>(false);
  const [confirm, setConfirm] = useState<any>(false);
  const history = useHistory();

  /**
   * Clears the data state
   */
  const clearDataState = () => {
    setHeatingAirFryer(false);
    setHeatingMicrowave(false);
    setHeatingOven(false);
    setHeatingStove(false);
    setHeatingOther(false);
    setComments("");
    setRating(null);
  };

  /**
   * Callback to handle feedback
   *
   * @param data
   */
  const onFeedbackSuccess = (data: any) => {
    clearDataState();

    setConfirm(true);

    setTimeout(() => {
      setConfirm(false);
    }, 5000);
  };

  /**
   * Handles submitting feedback
   *
   * @returns
   */
  const submitFeedback = async () => {
    if (!rating) {
      return;
    }

    let heating = [];

    if (heatingMicrowave) {
      heating.push("Microwave");
    }

    if (heatingOther) {
      heating.push("Other");
    }

    if (heatingAirFryer) {
      heating.push("Air Fryer");
    }

    if (heatingOven) {
      heating.push("Oven");
    }

    if (heatingStove) {
      heating.push("Stove");
    }

    setSubmitting(true);

    const user = await StorageUtils.get("user_customer");

    API.submitMealFeedback(
      meal?._id,
      rating,
      heating,
      comments ? comments : "",
      user?._id ? user?._id : "",
      user?.storeID ? user?.storeID : ""
    )
      .then(
        (res: any) => {
          settingsDesktopRef?.current?.dismiss();
          settingsRef?.current?.dismiss();

          onFeedbackSuccess(res?.data?.data);
        },
        (e: any) => {
          console.error(e);
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  /**
   * Handles submitting feedback
   */
  const handleFeedback = (rating: number, openModal: boolean = false) => {
    setRating(rating);

    if (openModal) {
      const { width }: any = WindowUtils.getDimensions();

      if (width >= 768) {
        settingsDesktopRef?.current?.present();
      } else {
        settingsRef.current?.present();
      }
    }
  };

  const categories = (meal: any) => {
    if (!meal?.categories?.length) {
      return [];
    }

    return _.sortBy(meal?.categories, "name");
  };

  const getProteinIcon = (meal: any) => {
    if (!meal?.primaryProteinCategory) {
      return "";
    }

    const cat = meal?.primaryProteinCategory?.toLowerCase();

    if (cat == "chicken") {
      return chicken;
    }

    if (cat == "beef") {
      return beef;
    }

    if (cat == "pork") {
      return pork;
    }

    if (cat == "turkey") {
      return turkey;
    }

    if (cat == "fish") {
      return fish;
    }

    if (cat == "plant based") {
      return plant;
    }

    if (cat == "lean cheat") {
      return other;
    }

    if (cat == "egg") {
      return egg;
    }
  };

  const feedbackForm = useCallback(
    (desktopMode = false) => {
      return (
        <>
          <IonRow className="ion-justify-content-center pb-2">
            <IonCol sizeXs="auto" className="pr-2 pl-0 pb-0">
              <div
                className="text-left cursor-pointer"
                style={{ color: "var(--yellow)", fontSize: 40, lineHeight: 1 }}
                onClick={() => {
                  handleFeedback(1);
                }}
              >
                <IonIcon icon={rating >= 1 ? star : starOutline}></IonIcon>
              </div>
            </IonCol>
            <IonCol sizeXs="auto" className="px-2 pb-0">
              <div
                className="text-left cursor-pointer"
                style={{ color: "var(--yellow)", fontSize: 40, lineHeight: 1 }}
                onClick={() => {
                  handleFeedback(2);
                }}
              >
                <IonIcon icon={rating >= 2 ? star : starOutline}></IonIcon>
              </div>
            </IonCol>
            <IonCol sizeXs="auto" className="px-2 pb-0">
              <div
                className="text-left cursor-pointer"
                style={{ color: "var(--yellow)", fontSize: 40, lineHeight: 1 }}
                onClick={() => {
                  handleFeedback(3);
                }}
              >
                <IonIcon icon={rating >= 3 ? star : starOutline}></IonIcon>
              </div>
            </IonCol>
            <IonCol sizeXs="auto" className="px-2 pb-0">
              <div
                className="text-left cursor-pointer"
                style={{ color: "var(--yellow)", fontSize: 40, lineHeight: 1 }}
                onClick={() => {
                  handleFeedback(4);
                }}
              >
                <IonIcon icon={rating >= 4 ? star : starOutline}></IonIcon>
              </div>
            </IonCol>
            <IonCol sizeXs="auto" className="pl-2 pr-0 pb-0">
              <div
                className="text-left cursor-pointer"
                style={{ color: "var(--yellow)", fontSize: 40, lineHeight: 1 }}
                onClick={() => {
                  handleFeedback(5);
                }}
              >
                <IonIcon icon={rating >= 5 ? star : starOutline}></IonIcon>
              </div>
            </IonCol>
          </IonRow>
          <h4 className="font-weight-bold mt-4 text-dark mb-4 text-center">
            How Did You Heat It?
          </h4>
          <div className="mb-4 text-center">
            <IonGrid className="p-0">
              <IonRow className="ion-justify-content-center">
                <IonCol sizeXs="auto" className="px-0">
                  <IonChip
                    mode="ios"
                    color={heatingMicrowave ? "primary" : "dark"}
                    outline={heatingMicrowave ? false : true}
                    onClick={() => {
                      setHeatingMicrowave(!heatingMicrowave);
                    }}
                  >
                    Microwave
                  </IonChip>
                </IonCol>
                <IonCol sizeXs="auto" className="px-0">
                  <IonChip
                    mode="ios"
                    color={heatingAirFryer ? "primary" : "dark"}
                    outline={heatingAirFryer ? false : true}
                    onClick={() => {
                      setHeatingAirFryer(!heatingAirFryer);
                    }}
                  >
                    Air Fryer
                  </IonChip>
                </IonCol>
                <IonCol sizeXs="auto" className="px-0">
                  <IonChip
                    mode="ios"
                    color={heatingOven ? "primary" : "dark"}
                    outline={heatingOven ? false : true}
                    onClick={() => {
                      setHeatingOven(!heatingOven);
                    }}
                  >
                    Oven
                  </IonChip>
                </IonCol>
                <IonCol sizeXs="auto" className="px-0">
                  <IonChip
                    mode="ios"
                    color={heatingStove ? "primary" : "dark"}
                    outline={heatingStove ? false : true}
                    onClick={() => {
                      setHeatingStove(!heatingStove);
                    }}
                  >
                    Stove
                  </IonChip>
                </IonCol>
                <IonCol sizeXs="auto" className="px-0">
                  <IonChip
                    mode="ios"
                    color={heatingOther ? "primary" : "dark"}
                    outline={heatingOther ? false : true}
                    onClick={() => {
                      setHeatingOther(!heatingOther);
                    }}
                  >
                    Other
                  </IonChip>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <h4 className="font-weight-bold mt-4 text-dark mb-4 text-center">
            Additional Feedback
          </h4>
          <textarea
            placeholder="Comments"
            style={{
              width: "calc(100% - 1px)",
              height: 100,
              borderRadius: 6,
              border: "1px solid var(--light)",
              padding: 10,
              fontSize: 18,
              resize: "none",
              color: "var(--dark)",
              background: "white",
            }}
            value={comments}
            onChange={(e: any) => {
              setComments(e?.target?.value);
            }}
          ></textarea>
          {!desktopMode && (
            <IonButton
              className={`pln-button-web mt-4`}
              expand="block"
              color={"primary"}
              disabled={!rating || submitting}
              onClick={() => {
                submitFeedback();
              }}
            >
              {submitting ? <IonSpinner name="dots"></IonSpinner> : "Submit"}
            </IonButton>
          )}
        </>
      );
    },
    [
      rating,
      heatingOther,
      heatingAirFryer,
      heatingMicrowave,
      heatingOven,
      heatingStove,
      comments,
      submitting,
    ]
  );

  return (
    <>
      <div className="d-block d-md-none">
        {skeleton ? (
          <>
            <div
              style={{
                height: 240,
                width: "100%",
                position: "relative",
                background: "var(--midlighter)",
                overflow: "hidden",
                borderBottom: "1px solid var(--midlighter)",
              }}
              className=""
            ></div>
          </>
        ) : (
          <>
            <div
              style={{
                height: 240,
                width: "100%",

                position: "relative",
                background: "var(--primary-lighter)",
                overflow: "hidden",

                borderBottom: "1px solid var(--midlighter)",
              }}
              className=""
            >
              <img
                src={
                  meal?.platedImageURL ? meal?.platedImageURL : meal?.imageURL
                }
                alt={meal?.name}
                style={{
                  width: "100%",
                  maxWidth: 500,
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  top: "50%",
                }}
              ></img>
              <div
                style={{
                  position: "absolute",
                  left: "0.5rem",
                  bottom: "0.5rem",
                  background: "var(--primary-darker)",
                  borderRadius: "100%",
                  width: 35,
                  height: 35,
                }}
                className="text-right "
              >
                <img src={getProteinIcon(meal)} style={{ width: "35px" }}></img>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: "0.5rem",
                  bottom: "0.5rem",
                  background: "var(--primary-darker)",
                  borderRadius: "100%",
                  width: 30,
                  height: 30,
                }}
                className="text-right "
              >
                <img src={glutenFree} style={{ width: "30px" }}></img>
              </div>
            </div>
          </>
        )}
        {showQuantity && meal?.quantity > 0 && (
          <>
            <div
              className="px-1 py-1 text-white font-weight-bold text-uppercase"
              style={{
                fontSize: 14,
                lineHeight: 1,
                borderRadius: 3,
                position: "absolute",
                right: "0.5rem",
                top: "0.5rem",
                background: "var(--primary-darker)",
              }}
            >
              {meal?.quantity} In Box
            </div>
          </>
        )}
      </div>

      <div className="mx-3 mt-3 d-none d-md-block">
        {skeleton ? (
          <div
            style={{
              height: 280,
              width: "100%",
              borderRadius: 6,
              position: "relative",
              background: "var(--midlighter)",
              overflow: "hidden",

              border: "1px solid var(--midlighter)",
            }}
            className=""
          ></div>
        ) : (
          <div
            style={{
              height: 280,
              width: "100%",
              borderRadius: 6,
              position: "relative",
              background: "var(--primary-lighter)",
              overflow: "hidden",
              boxShadow: "0px 3px 10px 0px rgba(150, 59, 38, 0.3)",
              border: "1px solid var(--primary-light)",
            }}
            className=""
          >
            <img
              src={meal?.platedImageURL ? meal?.platedImageURL : meal?.imageURL}
              alt={meal?.name}
              style={{
                width: "100%",
                maxWidth: 500,
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, -50%)",
                top: "50%",
              }}
            ></img>
            <div
              style={{
                position: "absolute",
                left: "0.5rem",
                bottom: "0.5rem",
                background: "var(--primary-darker)",
                borderRadius: "100%",
                width: 40,
                height: 40,
              }}
              className="text-right "
            >
              <img src={getProteinIcon(meal)} style={{ width: "40px" }}></img>
            </div>
            <div
              style={{
                position: "absolute",
                right: "0.5rem",
                bottom: "0.5rem",
                background: "var(--primary-darker)",
                borderRadius: "100%",
                width: 30,
                height: 30,
              }}
              className="text-right "
            >
              <img src={glutenFree} style={{ width: "30px" }}></img>
            </div>
            {showQuantity && meal?.quantity > 0 && (
              <>
                <div
                  className="px-1 py-1 text-white font-weight-bold text-uppercase"
                  style={{
                    fontSize: 14,
                    lineHeight: 1,
                    borderRadius: 3,
                    position: "absolute",
                    right: "0.5rem",
                    top: "0.5rem",
                    background: "var(--primary-darker)",
                  }}
                >
                  {meal?.quantity} In Box
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div className="px-3 pt-3 mt-0 mt-md-2">
        {!skeleton && (
          <div className="flex items-center mb-2">
            <div
              style={{
                borderRadius: 4,
                background: `${
                  _.findWhere(meal?.categories, { name: "Lean Cheats" }) != null
                    ? "#FBDB40"
                    : ""
                }${
                  meal?.planType?.name == "Lifestyle" &&
                  !_.findWhere(meal?.categories, { name: "Lean Cheats" })
                    ? "var(--success)"
                    : ""
                }${
                  meal?.planType?.name == "Athlete" &&
                  !_.findWhere(meal?.categories, { name: "Lean Cheats" })
                    ? "var(--primary)"
                    : ""
                }`,
                color: `${
                  !_.findWhere(meal?.categories, {
                    name: "Lean Cheats",
                  })
                    ? "var(--white)"
                    : "var(--dark)"
                }`,
              }}
              className={`py-1 mr-auto shadow-sm px-2 d-inline-block font-weight-bold text-uppercase`}
            >
              {_.findWhere(meal?.categories, { name: "Lean Cheats" })
                ? "Lean Cheats"
                : meal?.planType?.name}
            </div>

            {allowFavorite && (
              <>
                <div
                  className={`px-2 h-[32px] ${
                    favorites?.includes(meal?._id)
                      ? "bg-red-500 border-red-500"
                      : "bg-white border-gray-100"
                  } cursor-pointer flex items-center gap-x-2 rounded border-[1px] font-bold text-uppercase`}
                  onClick={() => {
                    onFavoriteChange(meal, !favorites?.includes(meal?._id));
                  }}
                >
                  <div
                    className={`${
                      favorites?.includes(meal?._id)
                        ? "text-white"
                        : "text-gray-300"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="h-4 w-4"
                    >
                      <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
                    </svg>
                  </div>
                  <div
                    className={`${
                      favorites?.includes(meal?._id)
                        ? "text-white"
                        : "text-gray-700"
                    }`}
                  >
                    {favorites?.includes(meal?._id) ? (
                      "Favorite"
                    ) : (
                      <span>Add to favorites</span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <h2 style={{ fontSize: 22 }} className="m-0 font-weight-bold">
          {skeleton ? (
            <IonSkeletonText
              animated={true}
              style={{ width: "60%", height: 18 }}
            ></IonSkeletonText>
          ) : (
            meal?.name
          )}
        </h2>
        <p className="mt-1 mb-2">
          {skeleton ? (
            <IonSkeletonText
              className="mt-3"
              animated={true}
              style={{ width: "100%", height: 30 }}
            ></IonSkeletonText>
          ) : (
            StringUtils.htmlToText(meal?.description)
          )}
        </p>
        {meal?.retired ? (
          <div className="my-3 bg-yellow-50 border-[1px] border-yellow-500 rounded-lg p-2">
            <p className="m-0">
              {skeleton ? (
                <IonSkeletonText
                  className="mt-3"
                  animated={true}
                  style={{ width: "100%", height: 30 }}
                ></IonSkeletonText>
              ) : (
                <>
                  <span className="font-weight-bold">Limited Time Only:</span>{" "}
                  This item is seasonal and is subject to first come, first
                  serve availability for a limited time only.&nbsp;
                  {meal?.swapForMeal ? (
                    <>
                      If {meal?.name} is unavailable, you'll receive{" "}
                      {meal?.swapForMeal?.name} instead.
                    </>
                  ) : (
                    <>
                      If {meal?.name} is unavailable, you'll receive an
                      alternative item from your order.
                    </>
                  )}
                </>
              )}
            </p>
            {meal?.swapForMeal ? (
              <>
                <div className="rounded-lg border-[1px] border-yellow-500 overflow-hidden bg-white mt-2">
                  <MealOptionCard
                    meal={meal?.swapForMeal}
                    mobile={true}
                    compact={true}
                    modalID={"swapfor-modal"}
                  />
                </div>
              </>
            ) : null}
          </div>
        ) : null}

        {skeleton ? (
          <IonSkeletonText
            className="mt-3"
            animated={true}
            style={{ width: "50%", height: 27 }}
          ></IonSkeletonText>
        ) : (
          <>
            <div className="flex">
              {meal?.spicy ? (
                <div
                  style={{ lineHeight: 1 }}
                  className="flex gap-x-[0.4rem] items-center h-[31px] mt-2 relative mr-2 px-2 border-[1px] border-red-200 bg-red-50 rounded text-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="140px"
                    height="185px"
                    viewBox="0 0 140 185"
                    version="1.1"
                    style={{
                      height: 14,
                      width: "auto",
                      position: "relative",
                      top: -1,
                    }}
                  >
                    <title>Meal Heat Flame</title>
                    <g
                      id="Meal-Heat-Flame"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <path
                        d="M107.681558,0.0427138334 C107.659186,0.0643153614 107.637728,0.0869484654 107.617246,0.110547115 C90.7193105,5.68520951 77.1764537,22.2532462 69.8716471,40.7059469 C62.8424875,58.4622994 61.7742218,77.9317946 69.2919377,91.4332384 C58.843956,92.1203807 50.0433588,89.4909601 45.1373211,81.8453565 C39.9927803,73.8280793 38.8693021,60.0655214 45.0084773,38.4619868 C45.11997,38.0538801 44.9963276,37.6145904 44.6918305,37.3369706 C44.3873335,37.0593508 43.9576496,36.994155 43.5914148,37.170005 C9.18969463,55.8789305 -4.47664656,92.8150112 1.27256306,125.092861 C7.02172892,157.370712 32.2868081,185 74.9602098,185 C94.77662,185 111.592939,177.831255 123.140599,164.532188 C134.688259,151.233341 140.928016,131.844035 139.887794,107.685035 C138.843459,80.1224131 125.131181,67.2739748 114.895824,54.5777429 C109.778168,48.2296489 105.567777,41.903777 103.945745,33.770038 C102.323714,25.636321 103.289933,15.5507234 108.905486,1.53852578 C109.072021,1.15188858 109.010228,0.698295065 108.747234,0.376870473 C108.48424,0.0554458807 108.068124,-0.0750500267 107.681558,0.0426697859 L107.681558,0.0427138334 Z"
                        id="Path"
                        fill="#F5365C"
                      />
                    </g>
                  </svg>
                  <div
                    style={{
                      fontSize: 14,
                      lineHeight: 1,
                      position: "relative",
                    }}
                    className="font-weight-bold text-red-900"
                  >
                    Spice
                  </div>
                </div>
              ) : null}
              {categories(meal)?.map((cat: any) => (
                <div
                  key={cat?._id}
                  className="px-2 py-1 font-weight-bold text-dark d-inline-block mt-2 mr-2"
                  style={{
                    borderRadius: 4,
                    fontSize: 14,
                    background: "var(--superlight)",
                    border: "1px solid var(--midlighter)",
                  }}
                >
                  {cat?.name}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {/**
       * {meal?.myfitnesspalURL ? (
        <>
          <div className="mb-1 px-3 pt-3">
            <IonButton
              onClick={() => {
                LinkUtils.openInNewTab(meal?.myfitnesspalURL);
              }}
              color="dark"
              className="pln-button-web mfp-button"
            >
              <IonIcon icon={myfitnesspal} slot="start" />
              View In MyFitnesspal
            </IonButton>
          </div>
        </>
      ) : null}
       */}
      {showReview && !skeleton ? (
        <>
          <div className="mt-4 mb-2 pt-2 px-3">
            <IonCard
              onClick={() => {
                handleFeedback(0, true);
              }}
              button={true}
              mode="ios"
              className="border m-0 shadow-sm"
            >
              <IonCardContent className="p-3">
                <IonGrid className="p-0">
                  <IonRow className="ion-align-items-center">
                    <IonCol sizeXs="" className="pl-0">
                      <h1
                        style={{ fontSize: 18 }}
                        className="text-dark font-weight-bold my-0"
                      >
                        Rate {meal?.name}
                      </h1>
                      <p
                        style={{ fontSize: 15 }}
                        className="mb-0 mt-1 text-muted"
                      >
                        {confirm
                          ? "Thanks for your feedback!"
                          : "Let us know what you think!"}
                      </p>
                      <div
                        className="mt-1"
                        style={{
                          fontSize: 20,
                          lineHeight: 1,
                          color: "var(--yellow)",
                        }}
                      >
                        {confirm ? (
                          <IonIcon color="success" icon={checkmarkCircle} />
                        ) : (
                          <>
                            <IonIcon icon={star} className="p-0 my-0 mr-2" />
                            <IonIcon icon={star} className="p-0 my-0 mr-2" />
                            <IonIcon icon={star} className="p-0 my-0 mr-2" />
                            <IonIcon icon={star} className="p-0 my-0 mr-2" />
                            <IonIcon icon={star} className="p-0 my-0 mr-2" />
                          </>
                        )}
                      </div>
                    </IonCol>
                    <IonCol sizeXs="auto" className="pr-0">
                      <div style={{ fontSize: 26, lineHeight: 1 }}>
                        <IonIcon color="dark" icon={chevronForward} />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          </div>
        </>
      ) : null}
      <div className="mb-1 px-3 pt-[2rem]">
        <h2
          style={{ fontSize: 22 }}
          className="text-dark font-weight-bold mb-0"
        >
          {skeleton ? (
            <IonSkeletonText
              animated={true}
              style={{ width: "40%", height: 18 }}
            ></IonSkeletonText>
          ) : (
            `Protein Source${meal?.secondaryProteinCategory ? "s" : ""}`
          )}
        </h2>
        <p
          className="mt-2 mb-1 text-base text-gray-900"
          style={{ fontSize: 17 }}
        >
          {skeleton ? (
            <>
              <IonSkeletonText
                className="mt-3"
                animated={true}
                style={{ width: "30%", height: 14 }}
              ></IonSkeletonText>
            </>
          ) : (
            <>
              {meal?.primaryProteinCategory}
              {meal?.secondaryProteinCategory
                ? `, ${meal?.secondaryProteinCategory}`
                : ""}
            </>
          )}
        </p>
      </div>
      <div className="mb-1 px-3 pt-4">
        <h2
          style={{ fontSize: 22 }}
          className="text-dark font-weight-bold mb-0"
        >
          {skeleton ? (
            <IonSkeletonText
              animated={true}
              style={{ width: "40%", height: 18 }}
            ></IonSkeletonText>
          ) : (
            "Allergens"
          )}
        </h2>
        <p
          className="mt-2 mb-1 text-base text-gray-900"
          style={{ fontSize: 17 }}
        >
          {skeleton ? (
            <>
              <IonSkeletonText
                className="mt-3"
                animated={true}
                style={{ width: "30%", height: 14 }}
              ></IonSkeletonText>
            </>
          ) : (
            <>
              {meal?.allergens?.length
                ? meal?.allergens
                    ?.map((allergen: any) => {
                      return allergen?.name;
                    })
                    ?.join(", ")
                : "No Allergens Specified"}
            </>
          )}
        </p>
      </div>
      <div className={`${skeleton && "d-none"} pr-3`}>
        <IonList mode="ios" className="ml--1">
          <IonListHeader>
            <IonLabel>Heating Instructions</IonLabel>
          </IonListHeader>
          {StringUtils.htmlToText(meal?.microwaveInstructions)?.trim() ==
          Constants.HEATING_INSTRUCTIONS_NOT_HEATED?.trim() ? (
            <IonItem lines="none">
              <IonLabel class="ion-text-wrap text-base text-gray-900">
                <div className="text-base text-gray-900">
                  This item does not need to be heated to enjoy!
                </div>
              </IonLabel>
            </IonItem>
          ) : (
            <>
              {meal?.microwaveInstructions ? (
                <IonItem>
                  <IonLabel class="ion-text-wrap text-base text-gray-900">
                    <h2
                      className="text-lg text-gray-900 font-weight-bold underline"
                      style={{ fontSize: 18 }}
                    >
                      Microwave
                    </h2>
                    <div
                      className="text-base text-gray-900"
                      style={{ fontSize: 17 }}
                      dangerouslySetInnerHTML={{
                        __html: StringUtils.htmlToText(
                          meal?.microwaveInstructions
                        )
                          ?.replace(
                            `Thawed / From The Fridge:`,
                            `</br></br><span class="font-medium">Thawed / From The Fridge:</span></br>`
                          )
                          ?.replace(
                            `From The Freezer:`,
                            `<span class="font-medium">From The Freezer:</span></br>`
                          ),
                      }}
                    ></div>
                  </IonLabel>
                </IonItem>
              ) : null}
              {meal?.airFryInstructions ? (
                <IonItem>
                  <IonLabel class="ion-text-wrap text-base text-gray-900">
                    <h2
                      className="text-lg text-gray-900 font-weight-bold underline"
                      style={{ fontSize: 18 }}
                    >
                      Air Fryer
                    </h2>
                    <div
                      className="text-base text-gray-900"
                      style={{ fontSize: 17 }}
                      dangerouslySetInnerHTML={{
                        __html: StringUtils.htmlToText(meal?.airFryInstructions)
                          ?.replace(
                            `Thawed / From The Fridge:`,
                            `</br></br><span class="font-medium">Thawed / From The Fridge:</span></br>`
                          )
                          ?.replace(
                            `From The Freezer:`,
                            `<span class="font-medium">From The Freezer:</span></br>`
                          ),
                      }}
                    ></div>
                  </IonLabel>
                </IonItem>
              ) : null}
              {meal?.ovenInstructions ? (
                <IonItem lines="none">
                  <IonLabel class="ion-text-wrap text-base text-gray-900">
                    <h2
                      className="text-lg text-gray-900 font-weight-bold underline"
                      style={{ fontSize: 18 }}
                    >
                      Oven
                    </h2>
                    <div
                      className="text-base text-gray-900"
                      style={{ fontSize: 17 }}
                      dangerouslySetInnerHTML={{
                        __html: StringUtils.htmlToText(meal?.ovenInstructions)
                          ?.replace(
                            `Thawed / From The Fridge:`,
                            `</br></br><span class="font-medium">Thawed / From The Fridge:</span></br>`
                          )
                          ?.replace(
                            `From The Freezer:`,
                            `<span class="font-medium">From The Freezer:</span></br>`
                          ),
                      }}
                    ></div>
                  </IonLabel>
                </IonItem>
              ) : null}
            </>
          )}
        </IonList>
      </div>
      <div className={`${skeleton && "d-none"} pr-3 mb-3`}>
        <IonList mode="ios" className="ml--1">
          <IonListHeader>
            <IonLabel>Nutrition Facts</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel>Serving Size</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {_.findWhere(meal?.categories, { name: "Lean Cheats" })
                ? "3 Protein Balls"
                : "1 Meal"}
              &nbsp;(~{meal?.foodWeight} oz)
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Calories</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.nutrition?.calories} CAL
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Protein</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.nutrition?.protein}g
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Carbohydrates</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.nutrition?.carbs}g
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Sugar</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.nutrition?.sugar}g
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Dietary Fiber</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.nutrition?.fiber}g
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Fat</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.nutrition?.fat}g
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Saturated Fat</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.saturatedFat}g
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Trans Fat</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.transFat}g
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Sodium</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.nutrition?.sodium}mg
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Cholesterol</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.cholesterol}mg
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Vitamin D</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.vitaminD} IU
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Potassium</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.potassium}mg
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Calcium</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.calcium}mg
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Iron</IonLabel>
            <IonLabel slot="end" className="mr-0">
              {meal?.iron}mg
            </IonLabel>
          </IonItem>
        </IonList>
      </div>
      <div className={`mb-1 px-3 pt-[1.5rem] ${skeleton && "d-none"}`}>
        <h2
          style={{ fontSize: 22 }}
          className="text-dark font-weight-bold mb-0"
        >
          Ingredients
        </h2>
        <div
          className="mt-3 mb-5 text-capitalize ingredient-list"
          style={{ lineHeight: 1.4, fontSize: 17 }}
          dangerouslySetInnerHTML={{
            __html: StringUtils.ingredientsToHTML(meal?.ingredients),
          }}
        ></div>
      </div>
      <div
        className={`px-3 pb-5 ${(skeleton || !showReturnToApp) && "d-none"}`}
      >
        <IonButton
          color="primary"
          onClick={() => {
            history.push("/manage");
          }}
          className="pln-button-web-outline"
          fill="outline"
          expand="block"
          mode="ios"
        >
          Return To App
        </IonButton>
      </div>
      <IonModal
        ref={settingsRef}
        className="upcomingorder-settings-modal"
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        mode="ios"
      >
        <div className="px-4 pt-4 pb-5" style={{ alignItems: "center" }}>
          <h4 className="font-weight-bold mt-3 text-dark mb-3 text-center">
            Review {meal?.name}
          </h4>
          {feedbackForm()}
        </div>
      </IonModal>
      <IonModal
        ref={settingsDesktopRef}
        presentingElement={presentingElement?.current}
        mode="ios"
      >
        <IonHeader>
          <IonToolbar
            mode={
              !nativeMode
                ? "ios"
                : Capacitor.getPlatform() == "ios"
                ? "ios"
                : "md"
            }
            className={
              nativeMode ? "modal-toolbar-native" : "box-modal-toolbar-web"
            }
            style={{ borderBottom: "none" }}
          >
            <IonTitle>Review {meal?.name}</IonTitle>
            <IonButtons slot="start">
              <IonButton
                color="dark"
                onClick={() => {
                  settingsDesktopRef?.current?.dismiss();
                }}
              >
                Close
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                color={rating ? "primary" : "medium"}
                disabled={!rating || submitting}
                onClick={() => {
                  submitFeedback();
                }}
              >
                {submitting ? <IonSpinner name="dots"></IonSpinner> : "Submit"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="p-4">{feedbackForm(true)}</div>
        </IonContent>
      </IonModal>
      <MealDetailModal
        id="swapfor-modal"
        presentingElement={presentingElement}
        nativeMode={nativeMode}
      ></MealDetailModal>
    </>
  );
};

export default MealDetailContent;
