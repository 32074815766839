import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import "./Navbar.css";

import plnSymbol from "../../../assets/img/logo/pln_symbol.svg";

type Props = {
  title: string;
  active?: boolean;
  disabled?: boolean;
  last?: boolean;
  iconName?: string;
  completed?: boolean;
  onClick?: any;
  clickable?: boolean;
};

const CheckoutNavbarLink: React.FC<Props> = ({
  title,
  iconName = null,
  active = false,
  disabled = false,
  last = false,
  completed = false,
  onClick = null,
  clickable = true,
}) => {
  const icon = useMemo(() => {
    let fill = "var(--medium)";

    if (disabled) {
      fill = "var(--light)";
    } else if (active) {
      fill = "var(--primary)";
    } else if (completed) {
      fill = "var(--success)";
    }

    let icon: any = null;

    if (completed) {
      return (
        <svg
          style={{
            height: 20,

            marginRight: 6,
            position: "relative",
            top: 1,
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>check-circle</title>
          <path
            fill={fill}
            d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
          />
        </svg>
      );
    }

    if (iconName == "checkmark") {
      return (
        <svg
          style={{
            height: 20,

            marginRight: 6,
            position: "relative",
            top: 1,
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>check-circle</title>
          <path
            fill={fill}
            d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
          />
        </svg>
      );
    }

    if (iconName == "calendar") {
      return (
        <svg
          style={{
            height: 19,

            marginRight: 6,
            position: "relative",
            top: 0,
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill={fill}
            d="M416 64h-16V48.45c0-8.61-6.62-16-15.23-16.43A16 16 0 00368 48v16H144V48.45c0-8.61-6.62-16-15.23-16.43A16 16 0 00112 48v16H96a64 64 0 00-64 64v12a4 4 0 004 4h440a4 4 0 004-4v-12a64 64 0 00-64-64zM476 176H36a4 4 0 00-4 4v236a64 64 0 0064 64h320a64 64 0 0064-64V180a4 4 0 00-4-4zM239.58 401.1c-12.17 9.61-28.75 14.9-46.7 14.9-27.87 0-48.48-18.16-57.66-33.7a16 16 0 0127.56-16.3c1.08 1.84 11.15 18 30.1 18 16.66 0 36.12-7.29 36.12-27.82 0-6.25-1.22-14.95-7-20.88-8.54-8.74-22.75-12.67-30.11-12.67a16 16 0 010-32c4.85 0 17.41-2.6 25.28-10.65a22 22 0 006.57-16.08c0-23.23-28.63-23.9-31.89-23.9-17.34 0-23.8 10.61-24.07 11.06a16 16 0 11-27.55-16.26c7.64-13 25.22-26.8 51.62-26.8 16.44 0 31.76 4.77 43.13 13.42 13.39 10.2 20.76 25.28 20.76 42.48A54 54 0 01240 302.35c-1.15 1.18-2.36 2.28-3.59 3.35a66.18 66.18 0 018.42 7.23c10.56 10.8 16.14 25.75 16.14 43.25.03 18.06-7.58 34.01-21.39 44.92zM368 396a16 16 0 01-32 0V256.29l-22.51 16.59a16 16 0 11-19-25.76l43.42-32a16 16 0 019.49-3.12h4.6a16 16 0 0116 16z"
          />
        </svg>
      );
    }

    if (iconName == "location") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            height: 20,

            marginRight: 6,
            position: "relative",
            top: 0,
          }}
          viewBox="0 0 512 512"
        >
          <path
            fill={fill}
            d="M258.9 48C141.92 46.42 46.42 141.92 48 258.9c1.56 112.19 92.91 203.54 205.1 205.1 117 1.6 212.48-93.9 210.88-210.88C462.44 140.91 371.09 49.56 258.9 48zM351 175.24l-82.24 186.52c-4.79 10.47-20.78 7-20.78-4.56V268a4 4 0 00-4-4H154.8c-11.52 0-15-15.87-4.57-20.67L336.76 161A10.73 10.73 0 01351 175.24z"
          />
        </svg>
      );
    }

    if (iconName == "welcome") {
      icon = (
        <svg
          style={{
            height: 20,

            marginRight: 6,
            position: "relative",
          }}
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M12,3L20,9V21H15V14H9V21H4V9L12,3Z" />
        </svg>
      );
    }

    if (iconName == "plans") {
      icon = (
        <svg
          style={{
            height: 20,
            marginRight: 6,
            position: "relative",
          }}
          fill={fill}
          viewBox="0 0 24 24"
        >
          <path d="M20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20M4,6V18H20V6H4M6,9H18V11H6V9M6,13H16V15H6V13Z" />
        </svg>
      );
    }

    if (iconName == "meals") {
      icon = (
        <svg
          style={{
            height: 20,
            marginRight: 6,
            position: "relative",
          }}
          fill={fill}
          viewBox="0 0 24 24"
        >
          <title>silverware-fork-knife</title>
          <path d="M11,9H9V2H7V9H5V2H3V9C3,11.12 4.66,12.84 6.75,12.97V22H9.25V12.97C11.34,12.84 13,11.12 13,9V2H11V9M16,6V14H18.5V22H21V2C18.24,2 16,4.24 16,6Z" />
        </svg>
      );
    }

    if (iconName == "checkout") {
      icon = (
        <svg
          style={{
            height: 20,
            marginRight: 6,
            position: "relative",
          }}
          fill={fill}
          viewBox="0 0 24 24"
        >
          <title>cart</title>
          <path d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z" />
        </svg>
      );
    }

    return <>{icon}</>;
  }, [iconName, active, disabled]);

  return (
    <div
      style={{
        fontSize: 16,
        fontWeight: 700,
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
      }}
      onClick={onClick}
      className={`${active || completed ? "text-primary" : ""} ${
        clickable !== false ? "cursor-pointer" : ""
      } ${disabled ? "text-lighter cursor-disabled" : ""} ${
        !active && !disabled ? "text-light text-primary--hover" : ""
      }`}
    >
      {icon ? icon : null}
      <div
        className={`${active ? "text-gray-900" : ""} ${
          completed ? "text-gray-900" : ""
        }`}
      >
        {title}
      </div>
      {!last && (
        <div
          className="mx-3"
          style={{
            position: "relative",
            top: -1,
            paddingTop: 2,
            width: 15,
            background: `${active ? "var(--lighter)" : ""} ${
              disabled ? "var(--lighter)" : ""
            } ${!active && !disabled ? "var(--dark)" : ""}`,
          }}
        ></div>
      )}
    </div>
  );
};

export default CheckoutNavbarLink;
