import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import Constants from "./Constants";
export default class LinkUtils {
    static async openInNewTab(link: any = '') {
        if (Capacitor.isNativePlatform()) {
            //  console.log("here");



            //console.log(link);

            Browser.open({ url: link });
        } else {
            //console.log("here2 ");

            window.open(link, "_blank");
        }
    }

    static generateGoogleMapsLinkForStore(store: any) {
        return (
            "https://www.google.com/maps/search/?api=1&query=" +
            store?.location?.address?.formattedAddress
        );
    }
}
