import { useEffect, useMemo, useState } from "react";

import "./ToastProvider.css";

import _ from "underscore";
import PubSub from "../../lib/PubSub";
import Constants from "../../lib/Constants";
import { useIonToast } from "@ionic/react";
import { checkmarkCircle, checkmarkCircleOutline } from "ionicons/icons";

const ToastProvider: React.FC<{
  disabled?: boolean;
}> = ({ disabled = false }) => {
  const [present] = useIonToast();

  const [unique, setUnique] = useState([]);

  useMemo(() => {
    PubSub.subscribe(Constants.TOAST.SHOW, (data: any) => {
      if (disabled) {
        return;
      }

      if (_.contains(unique, data?._id)) {
        return;
      }

      console.log("TOAST", data?._id);

      const uni: any = unique;

      setUnique(uni.push(data?._id));

      console.log(data);

      if (data?.template == "success") {
        present({
          message: data?.message,
          duration: 1500,
          position: data?.position ? data?.position : "bottom",
          icon: checkmarkCircle,
          color: "success",
          layout: "baseline",
          mode: "ios",
          buttons: [
            {
              text: "OK",
              role: "cancel",
            },
          ],
          onDidDismiss: () => {
            let uni: any = unique;

            console.log("DISMISS");

            uni = _.without(uni, data?._id);

            setUnique(uni);
          },
        });

        return;
      }

      present(data);
    });
  }, []);

  return (
    <>
      <div></div>
    </>
  );
};

export default ToastProvider;
