import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import API from "../../lib/API";

import sesameArtboard from "../../assets/img/backgrounds/sesame-artboard.jpg";
import porkArtboard from "../../assets/img/backgrounds/pork_scalloped.jpg";
import breakfastArtboard from "../../assets/img/backgrounds/breakfast.jpg";
import grainArtboard from "../../assets/img/backgrounds/grain_dairy.jpg";
import plantArtboard from "../../assets/img/backgrounds/plant.jpg";
import gfArtboard from "../../assets/img/backgrounds/gf.jpg";
import leanArtboard from "../../assets/img/backgrounds/pbmm.jpg";
import proteinArtboard from "../../assets/img/backgrounds/protein.jpg";
import TestimonialCard from "../TestimonialCard";

const TestimonialSlider: React.FC = () => {
  const topicSlider = useRef<any>(null);

  const topic = useMemo(() => {
    if (topicSlider?.current) {
      topicSlider.current.startAutoplay();
    }
  }, [topicSlider?.current]);

  return (
    <>
      <IonSlides
        ref={topicSlider}
        pager={true}
        style={{
          border: "1px solid var(--midlighter)",
          borderRadius: 6,
          boxShadow: "0px 5px 8px 0px rgba(33,37,41,0.1)",
        }}
        className="pln-testimonial-slider"
        options={{
          on: {
            beforeInit() {
              const swiper: any = this;
              swiper.classNames.push(
                `${swiper.params.containerModifierClass}fade`
              );
              const overwriteParams = {
                slidesPerView: 1,
                slidesPerColumn: 1,
                slidesPerGroup: 1,
                watchSlidesProgress: true,
                spaceBetween: 0,
                virtualTranslate: true,
              };
              swiper.params = Object.assign(swiper.params, overwriteParams);
              swiper.params = Object.assign(
                swiper.originalParams,
                overwriteParams
              );
            },
            setTranslate() {
              const swiper: any = this;
              const { slides }: any = swiper;
              for (let i = 0; i < slides.length; i += 1) {
                const $slideEl = swiper.slides.eq(i);
                const offset$$1 = $slideEl[0].swiperSlideOffset;
                let tx = -offset$$1;
                if (!swiper.params.virtualTranslate) tx -= swiper.translate;
                let ty = 0;
                if (!swiper.isHorizontal()) {
                  ty = tx;
                  tx = 0;
                }
                const slideOpacity = swiper.params.fadeEffect.crossFade
                  ? Math.max(1 - Math.abs($slideEl[0].progress), 0)
                  : 1 + Math.min(Math.max($slideEl[0].progress, -1), 0);
                $slideEl
                  .css({
                    opacity: slideOpacity,
                  })
                  .transform(`translate3d(${tx}px, ${ty}px, 0px)`);
              }
            },
            setTransition(duration: any) {
              const swiper: any = this;
              const { slides, $wrapperEl }: any = swiper;
              slides.transition(duration);
              if (swiper.params.virtualTranslate && duration !== 0) {
                let eventTriggered = false;
                slides.transitionEnd(() => {
                  if (eventTriggered) return;
                  if (!swiper || swiper.destroyed) return;
                  eventTriggered = true;
                  swiper.animating = false;
                  const triggerEvents = [
                    "webkitTransitionEnd",
                    "transitionend",
                  ];
                  for (let i = 0; i < triggerEvents.length; i += 1) {
                    $wrapperEl.trigger(triggerEvents[i]);
                  }
                });
              }
            },
          },
          speed: 800,
          autoplay: {
            delay: 7000,
          },
          breakpoints: {
            // when window width is >= 320px
          },
          grabCursor: true,

          loop: true,
        }}
        mode="ios"
      >
        <IonSlide>
          <TestimonialCard
            body={`"It’s the absolute best decision I’ve made for my health in a very long time."`}
            name="Dana"
          ></TestimonialCard>
        </IonSlide>
        <IonSlide>
          <TestimonialCard
            body={`"Project LeanNation helps me crush my goals and stay consistent."`}
            name="Tyler"
          ></TestimonialCard>
        </IonSlide>
        <IonSlide>
          <TestimonialCard
            body={`"Lost over 60 lbs! Food is excellent & the time savings is a life saver."`}
            name="Matt"
          ></TestimonialCard>
        </IonSlide>
        <IonSlide>
          <TestimonialCard
            body={`"Finally, I can eat a healthy meal while being a busy working mom!"`}
            name="Lindsey"
          ></TestimonialCard>
        </IonSlide>
        <IonSlide>
          <TestimonialCard
            body={`"I love the convenience! Ready in minutes & they help me stay on track with my macros!"`}
            name="Connie"
          ></TestimonialCard>
        </IonSlide>
        <IonSlide>
          <TestimonialCard
            body={`"The easiest way to eat clean consistently. No meap prep required!"`}
            name="Ashley"
          ></TestimonialCard>
        </IonSlide>

        <IonSlide>
          <TestimonialCard
            body={`"Love PLN - healthy & easy to reheat meals! Check them out - you won't be disappointed!"`}
            name="Ashley"
          ></TestimonialCard>
        </IonSlide>
      </IonSlides>
    </>
  );
};

export default TestimonialSlider;
