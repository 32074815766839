import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import "./SchedulerNavbar.css";

import plnSymbol from "../../../assets/img/logo/pln_symbol.svg";
import CheckoutNavbarLink from "../../Checkout/Navbar/CheckoutNavbarLink";
import {
  chatboxEllipses,
  chevronBackOutline,
  helpCircleOutline,
} from "ionicons/icons";
import PubSub from "../../../lib/PubSub";
import Event from "../../../lib/Event";
import moment from "moment-timezone";
import _ from "underscore";
import StringUtils from "../../../lib/StringUtils";
import StorageUtils from "../../../lib/StorageUtils";

const SchedulerNavbar: React.FC<{
  showSupportOptions?: boolean;
  onSupportChat?: any;
  onSupportCall?: any;
  activeStep?: number;
  showBackButton?: boolean;
  onGoBack?: any;
  store?: any;
  allowNavigation?: boolean;
}> = ({
  showSupportOptions = false,
  onSupportChat = null,
  onSupportCall = null,
  activeStep = 1,
  showBackButton = true,
  onGoBack = null,
  store = null,
  allowNavigation = true,
}) => {
  const { storeID, appointmentType } = useParams<{
    storeID?: string;
    appointmentType?: string;
  }>();

  const [coupon, setCoupon] = useState(false);
  const [couponData, setCouponData] = useState<any>(null);
  const [showCoupon, setShowCoupon] = useState(true);
  const [isOpen, setIsOpen] = useState<any>(false);
  const [openingTime, setOpeningTime] = useState<any>(null);
  const [closingTime, setClosingTime] = useState<any>(null);
  const [openingTimeString, setOpeningTimeString] = useState<any>(null);
  const [closingTimeString, setClosingTimeString] = useState<any>(null);
  const history = useHistory();

  const todayHours = useMemo(() => {
    let today = moment().format("dddd");

    if (!store?.operations?.hours?.standard?.length) {
      return null;
    }

    const todayHours = _.findWhere(store?.operations?.hours?.standard, {
      day: today,
    });

    return todayHours ? todayHours : null;
  }, [store]);

  useEffect(() => {
    if (
      !todayHours ||
      todayHours?.closed ||
      !todayHours?.opensAt ||
      !todayHours?.closesAt
    ) {
      setIsOpen(false);
      setOpeningTime(null);
      setClosingTime(null);
      setOpeningTimeString(null);
      setClosingTimeString(null);

      return;
    }

    const now = new Date();

    const { opensAt, closesAt } = todayHours;

    const todayDateString = moment().format("YYYY-MM-DD");

    const openingTime = store?.timezone
      ? moment.tz(`${todayDateString}T${opensAt}:00`, store?.timezone)
      : moment(`${todayDateString}T${opensAt}:00`);

    const closingTime = store?.timezone
      ? moment.tz(`${todayDateString}T${closesAt}:00`, store?.timezone)
      : moment(`${todayDateString}T${closesAt}:00`);

    const isOpen = now >= openingTime.toDate() && now <= closingTime.toDate();

    setIsOpen(isOpen);
    setOpeningTime(openingTime);
    setClosingTime(closingTime);
    setOpeningTimeString(openingTime.format("h:mma"));
    setClosingTimeString(closingTime.format("h:mma"));
  }, [todayHours, store]);

  const getIcon = (iconName = "") => {
    let fill = "var(--dark)";

    let icon: any = null;

    if (iconName == "welcome") {
      icon = (
        <svg
          style={{
            height: 24,

            marginRight: 6,
            position: "relative",
          }}
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M12,3L20,9V21H15V14H9V21H4V9L12,3Z" />
        </svg>
      );
    }

    if (iconName == "availability") {
      icon = <></>;
    }

    if (iconName == "details") {
      icon = <></>;
    }

    if (iconName == "confirmation") {
      icon = <></>;
    }

    return <>{icon}</>;
  };

  return (
    <IonHeader className="pln-navbar">
      <div className="border-b border-gray-100">
        <div className={`px-2 bg-white py-md-3 py-2`}>
          <div
            style={{
              maxWidth: 1200,
              margin: "auto",
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            {showBackButton && (
              <>
                <div className="d-block d-md-none" style={{ width: 50 }}>
                  <IonButton
                    fill="clear"
                    className="pln-navbar-button"
                    color="dark"
                    size="small"
                    onClick={() => {
                      if (typeof onGoBack == "function") {
                        onGoBack();
                      }
                    }}
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={chevronBackOutline}
                    ></IonIcon>
                  </IonButton>
                </div>
                <div
                  className="d-none d-md-flex"
                  style={{
                    width: 50,
                    height: 50,
                    // margin: "auto",

                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IonButton
                    fill="clear"
                    className="pln-navbar-button ml-0"
                    color="dark"
                    size="small"
                    onClick={() => {
                      if (typeof onGoBack == "function") {
                        onGoBack();
                      }
                    }}
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={chevronBackOutline}
                    ></IonIcon>
                  </IonButton>
                </div>
              </>
            )}
            {!showBackButton && (
              <>
                <div className="d-block d-md-none" style={{ width: 50 }}></div>
              </>
            )}

            <div
              className="d-block d-md-none"
              style={{
                flexGrow: 1,
                textAlign: "center",
              }}
            >
              {activeStep == 1 || activeStep == 4 ? (
                <img
                  src={plnSymbol}
                  alt="PLN Symbol"
                  width="40px"
                  height="40px"
                  style={{
                    height: 40,
                    width: 40,
                    position: "relative",
                  }}
                ></img>
              ) : null}
              {activeStep == 2 ? (
                <>
                  <h3
                    className="text-uppercase text-dark font-weight-bold"
                    style={{ marginTop: 10, marginBottom: 8 }}
                  >
                    <div className="d-flex align-items-center ion-justify-content-center">
                      <div style={{ lineHeight: 1 }}>
                        {getIcon("availability")}
                      </div>
                      <div style={{ lineHeight: 1.2 }}>Availability</div>
                    </div>
                  </h3>
                </>
              ) : null}
              {activeStep == 3 ? (
                <>
                  <h3
                    className="text-uppercase text-dark font-weight-bold"
                    style={{ marginTop: 10, marginBottom: 11 }}
                  >
                    <div className="d-flex align-items-center ion-justify-content-center">
                      <div style={{ lineHeight: 1 }}>{getIcon("details")}</div>
                      <div style={{ lineHeight: 1.2 }}>Schedule</div>
                    </div>
                  </h3>
                </>
              ) : null}
            </div>

            <img
              className={`d-none ml-3 ${
                showBackButton ? "d-md-none" : "d-md-block"
              }`}
              src={plnSymbol}
              alt="PLN Symbol"
              width="50px"
              height="50px"
              style={{ height: 50, width: 50 }}
            ></img>
            <div className="pl-4 d-none d-md-flex pln-checkout-steps">
              <CheckoutNavbarLink
                title="Location"
                active={activeStep == 1}
                disabled={activeStep < 1}
                completed={activeStep > 1}
                iconName="location"
                onClick={() => {
                  if (activeStep < 1 || !allowNavigation) {
                    return;
                  }

                  history.push("/schedule");
                }}
              ></CheckoutNavbarLink>
              <CheckoutNavbarLink
                iconName="calendar"
                title="Availability"
                active={activeStep == 2}
                disabled={activeStep < 2}
                completed={activeStep > 2}
                onClick={() => {
                  if (activeStep < 2 || !allowNavigation) {
                    return;
                  }

                  history.push(`/schedule/${storeID}/${appointmentType}`);
                }}
              ></CheckoutNavbarLink>
              <CheckoutNavbarLink
                title="Schedule"
                iconName="checkmark"
                active={activeStep == 3}
                disabled={activeStep < 3}
                completed={activeStep > 3}
                onClick={() => {
                  if (activeStep < 3 || !allowNavigation) {
                    return;
                  }

                  history.push(`/scheduler/${storeID}/nc-consult/confirm`);
                }}
                last={true}
              ></CheckoutNavbarLink>
            </div>
            {showSupportOptions && store?.twilioNumber ? (
              <>
                <div
                  className="d-none d-md-block mr-3"
                  style={{ flexGrow: 1, textAlign: "right" }}
                >
                  <div
                    className="mb-1"
                    style={{ fontSize: 14, color: "var(--medium)" }}
                  >
                    {isOpen ? (
                      <>
                        Today {openingTimeString} - {closingTimeString}
                      </>
                    ) : (
                      "Questions?"
                    )}
                  </div>
                  <div>
                    {isOpen ? (
                      <>
                        <div
                          className="text-primary font-weight-bold cursor-pointer"
                          style={{ display: "inline" }}
                          onClick={() => {
                            if (typeof onSupportChat == "function") {
                              onSupportChat();
                            }
                          }}
                        >
                          Start Chat
                        </div>{" "}
                        or{" "}
                        <div
                          style={{ display: "inline" }}
                          className="text-primary font-weight-bold cursor-pointer"
                          onClick={() => {
                            window.open("tel:" + store?.twilioNumber);
                          }}
                        >
                          Call
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="text-primary font-weight-bold cursor-pointer"
                          style={{ display: "inline" }}
                          onClick={() => {
                            if (typeof onSupportChat == "function") {
                              onSupportChat();
                            }
                          }}
                        >
                          Leave A Message
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {showSupportOptions && (
                  <div
                    className="d-block d-md-none"
                    style={{ textAlign: "right", width: 50 }}
                  >
                    <IonButton
                      fill="clear"
                      className="pln-navbar-button"
                      color="dark"
                      size="small"
                      id="support-button"
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={chatboxEllipses}
                      ></IonIcon>
                    </IonButton>
                    <IonPopover
                      trigger="support-button"
                      className="navbar-support-popup"
                      triggerAction="click"
                    >
                      <IonContent class="ion-padding">
                        <div
                          className="mb-1 text-right"
                          style={{ fontSize: 14, color: "var(--medium)" }}
                        >
                          {isOpen ? (
                            <>
                              Today {openingTimeString} - {closingTimeString}
                            </>
                          ) : (
                            "Questions?"
                          )}
                        </div>
                        <div className="text-right">
                          {isOpen ? (
                            <>
                              <div
                                className="text-primary font-weight-bold cursor-pointer"
                                style={{ display: "inline" }}
                                onClick={() => {
                                  if (typeof onSupportChat == "function") {
                                    onSupportChat();
                                  }
                                }}
                              >
                                Start Chat
                              </div>{" "}
                              or{" "}
                              <div
                                style={{ display: "inline" }}
                                className="text-primary font-weight-bold cursor-pointer"
                                onClick={() => {
                                  window.open("tel:" + store?.twilioNumber);
                                }}
                              >
                                Call
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="text-primary font-weight-bold cursor-pointer"
                                style={{ display: "inline" }}
                                onClick={() => {
                                  if (typeof onSupportChat == "function") {
                                    onSupportChat();
                                  }
                                }}
                              >
                                Leave A Message
                              </div>
                            </>
                          )}
                        </div>
                      </IonContent>
                    </IonPopover>
                  </div>
                )}
              </>
            ) : null}
            {!showSupportOptions && (
              <div style={{ textAlign: "right", width: 50 }}></div>
            )}
          </div>
        </div>
      </div>
    </IonHeader>
  );
};

export default SchedulerNavbar;
