/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Event from "../lib/Event";
//import FirebaseUtils from "lib/FirebaseUtils";
import PubSub from "../lib/PubSub";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import _ from "underscore";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonIcon,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
} from "@ionic/react";

import { add, close, closeOutline, remove } from "ionicons/icons";
import StringUtils from "../lib/StringUtils";
import MealDetailContent from "./MealDetailContent";
import { Capacitor } from "@capacitor/core";

class MealDetailModal extends React.Component<{
  presentingElement: any;
  showQuantity?: boolean;
  id?: string;
  showReview?: boolean;
  nativeMode?: boolean;
  allowFavorite?: boolean;
  favorites?: any;
  onFavoriteChange?: any;
}> {
  state: any = {};

  modalRef: any = React.createRef();

  componentDidMount() {
    PubSub.subscribe(Event.MEAL.OPEN_INFO, (data: any) => {
      const { id, meal } = data;

      if (
        (this.props.id && id != this.props.id) ||
        !meal ||
        meal?._id == this.state.meal?._id
      ) {
        return;
      }

      this.setState(
        {
          meal,
        },
        () => {
          if (meal) {
            this.modalRef?.current?.present();
          }
        }
      );
    });

    this.modalRef.current?.onDidDismiss().then(() => {
      this.setState({ meal: null });
    });
  }

  render() {
    return (
      <>
        <IonModal
          className="modal-mealdetail"
          ref={this.modalRef}
          canDismiss={true}
          mode={
            !this.props.nativeMode
              ? "ios"
              : Capacitor.getPlatform() == "ios"
              ? "ios"
              : "md"
          }
        >
          <IonHeader>
            <IonToolbar
              mode={
                !this.props.nativeMode
                  ? "ios"
                  : Capacitor.getPlatform() == "ios"
                  ? "ios"
                  : "md"
              }
              className={
                Capacitor.isNativePlatform() ? "" : "box-modal-toolbar-web"
              }
              style={{ borderBottom: "none" }}
            >
              <IonTitle>{this.state.meal?.name}</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  color="dark"
                  onClick={() => {
                    this.modalRef?.current?.dismiss();

                    this.setState({
                      meal: null,
                    });
                  }}
                >
                  <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <MealDetailContent
              allowFavorite={this.props.allowFavorite}
              favorites={this.props.favorites}
              onFavoriteChange={this.props.onFavoriteChange}
              meal={this.state.meal}
              showQuantity={this.props.showQuantity}
              showReview={this.props.showReview}
              nativeMode={this.props.nativeMode}
              modalID="webapp-mealselection-detailmodal"
              presentingElement={this.props.presentingElement}
            ></MealDetailContent>
          </IonContent>
        </IonModal>
      </>
    );
  }
}

export default MealDetailModal;
