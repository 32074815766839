import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonToast,
  IonBackButton,
} from "@ionic/react";
import { useParams } from "react-router";
import SchedulerNavbar from "../../../../components/Scheduler/SchedulerNavbar/SchedulerNavbar";
import "./ForgotPassword.css";

import React, { useMemo } from "react";
import Footer from "../../../../components/Checkout/Footer";
import {
  arrowBack,
  chatboxEllipses,
  chevronForward,
  eye,
  eyeOff,
  mail,
  mailUnread,
  navigateCircleOutline,
} from "ionicons/icons";

import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import plnSymbol from "../../../../assets/img/logo/pln_symbol.svg";
import carousel1 from "../../../../assets/img/backgrounds/login_carousel_1-min.jpg";
import carousel2 from "../../../../assets/img/backgrounds/login_carousel_2-min.jpg";
import carousel3 from "../../../../assets/img/backgrounds/login_carousel_3-min.jpg";
import carousel4 from "../../../../assets/img/backgrounds/login_carousel_4-min.jpg";
import { Capacitor } from "@capacitor/core";
import StringUtils from "../../../../lib/StringUtils";
import { Link } from "react-router-dom";
import SEOHeaders from "../../../../components/SEOHeaders";
import mobileLoginBG from "../../../../assets/img/backgrounds/mobileLoginBG-min.jpg";
import whiteSymbol from "../../../../assets/img/logo/pln_symbol_white.svg";
import memberTrust from "../../../../assets/img/icons/memberTrust.svg";

class ForgotPassword extends React.Component<{ history: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    slide: 1,
    mode: "select",
  };

  componentDidMount(): void {}

  componentWillUnmount(): void {}

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {}

  setError(message: string) {
    this.setState({
      error: message,
    });

    if (message) {
      setTimeout(() => {
        this.setError("");
      }, 7000);
    }
  }

  sendRecovery(method = "", value = "") {
    this.setState({
      submitting: true,
    });

    API.sendForgotPasswordVerification(method, value)
      .then(
        (data: any) => {
          const resp = data?.data?.data;

          //          console.log(resp);

          if (method == "email") {
            this.setState({
              mode: "emailconfirm",
            });
          }

          if (method == "phone") {
            this.setState({
              mode: "phoneverify",
              verificationCode: resp?.verificationToken,
            });
          }
        },
        (e) => {
          console.error(e);

          this.setError(
            `We're having trouble locating your account Please confirm the ${method} you provided is correct and try again.`
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  verifyCode() {
    this.setState({
      submitting: true,
    });

    //  console.log(this.state.code, this.state.verificationCode);

    API.verifyForgotPassword(this.state.verificationCode, this.state.code)
      .then(
        (data: any) => {
          const resp = data?.data?.data;

          this.setState({
            mode: "select",
            phone: "",
            phoneReadable: "",
            email: "",
            verificationCode: "",
            code: "",
          });

          this.props.history.push(
            `/login/reset?token=${resp?.verificationToken}`
          );
        },
        (e: any) => {
          console.error(e);

          this.setError(
            "The verification code you entered is incorrect. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    return (
      <IonPage>
        <SEOHeaders title="Request Password Reset"></SEOHeaders>
        {Capacitor.isNativePlatform() && (
          <>
            <IonHeader>
              <IonToolbar className="pln-native-toolbar">
                <IonButtons slot="start">
                  <IonBackButton />
                </IonButtons>
                <IonTitle>Reset Password</IonTitle>
              </IonToolbar>
            </IonHeader>
          </>
        )}
        <IonContent>
          <div style={{}}>
            {/** Desktop View */}
            <div
              style={{
                maxWidth: 1200,
                margin: "auto",
              }}
            >
              <IonGrid className="p-0">
                <IonRow>
                  <IonCol
                    size-xs={12}
                    size-md={6}
                    className="lg:border-l-[1px] border-gray-100"
                  >
                    <div className="p-4 flex w-full h-full items-center">
                      <div className="w-full">
                        {this.state.mode == "select" ? (
                          <>
                            {!Capacitor.isNativePlatform() ? (
                              <p
                                className="small text-gray-900 mt-4"
                                style={{ lineHeight: 1.3 }}
                              >
                                <Link to="/login" className="text-orange-500 ">
                                  <div className="inline-block relative top-[3px] pr-1">
                                    <IonIcon icon={arrowBack}></IonIcon>
                                  </div>
                                  Return to Login
                                </Link>
                              </p>
                            ) : null}
                            <h1
                              className={`text-3xl lg:text-4xl ${
                                Capacitor.isNativePlatform() ? "" : "mt-3"
                              } mb-2 font-weight-bold`}
                            >
                              Reset Password
                            </h1>

                            <div
                              className="mb-4 pb-2 text-dark"
                              style={{ fontSize: 18 }}
                            >
                              How would you like to reset your password?
                            </div>

                            <div className="mt-3">
                              {this.state.error && (
                                <div className="mb-3 text-danger">
                                  {this.state.error}
                                </div>
                              )}
                              <IonCard
                                button={true}
                                mode="ios"
                                className="shadow-sm border mx-0 mt-0 mb-3"
                                onClick={() => {
                                  this.setState({
                                    mode: "sms",
                                  });
                                }}
                              >
                                <IonCardContent className="p-3">
                                  <IonRow className="ion-align-items-center">
                                    <IonCol size-xs="auto">
                                      <div
                                        style={{
                                          color: "var(--primary)",
                                          fontSize: 32,
                                          lineHeight: 1,
                                        }}
                                      >
                                        <IonIcon
                                          icon={chatboxEllipses}
                                        ></IonIcon>
                                      </div>
                                    </IonCol>
                                    <IonCol
                                      size-xs=""
                                      className="text-left pl-3"
                                    >
                                      <h2 className="mt-0 mb-1 text-dark font-weight-bold">
                                        Text Message
                                      </h2>
                                      <p
                                        className="m-0 text-gray-900"
                                        style={{ lineHeight: 1.2 }}
                                      >
                                        Send me a text message containing a
                                        verification code.
                                      </p>
                                    </IonCol>
                                    <IonCol size-xs="auto" className="pl-2">
                                      <div
                                        style={{
                                          color: "var(--dark)",
                                          fontSize: 25,
                                          lineHeight: 1,
                                        }}
                                      >
                                        <IonIcon
                                          icon={chevronForward}
                                        ></IonIcon>
                                      </div>
                                    </IonCol>
                                  </IonRow>
                                </IonCardContent>
                              </IonCard>
                              <IonCard
                                button={true}
                                mode="ios"
                                className="shadow-sm border m-0"
                                onClick={() => {
                                  this.setState({
                                    mode: "email",
                                  });
                                }}
                              >
                                <IonCardContent className="p-3">
                                  <IonRow className="ion-align-items-center">
                                    <IonCol size-xs="auto">
                                      <div
                                        style={{
                                          color: "var(--primary)",
                                          fontSize: 32,
                                          lineHeight: 1,
                                        }}
                                      >
                                        <IonIcon icon={mail}></IonIcon>
                                      </div>
                                    </IonCol>
                                    <IonCol
                                      size-xs=""
                                      className="text-left pl-3"
                                    >
                                      <h2 className="mt-0 mb-1 text-dark font-weight-bold">
                                        Email
                                      </h2>
                                      <p
                                        className="m-0 text-gray-900"
                                        style={{ lineHeight: 1.2 }}
                                      >
                                        Send me an email containing a link to
                                        verify my identity.
                                      </p>
                                    </IonCol>
                                    <IonCol size-xs="auto" className="pl-2">
                                      <div
                                        style={{
                                          color: "var(--dark)",
                                          fontSize: 25,
                                          lineHeight: 1,
                                        }}
                                      >
                                        <IonIcon
                                          icon={chevronForward}
                                        ></IonIcon>
                                      </div>
                                    </IonCol>
                                  </IonRow>
                                </IonCardContent>
                              </IonCard>
                            </div>
                          </>
                        ) : null}
                        {this.state.mode == "email" ? (
                          <>
                            <>
                              {!Capacitor.isNativePlatform() ? (
                                <p
                                  className="small text-gray-900 mt-4"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  <Link
                                    to="/login"
                                    className="text-orange-500 "
                                  >
                                    <div className="inline-block relative top-[3px] pr-1">
                                      <IonIcon icon={arrowBack}></IonIcon>
                                    </div>
                                    Return to Login
                                  </Link>
                                </p>
                              ) : null}
                              <h1
                                className={`text-3xl lg:text-4xl ${
                                  Capacitor.isNativePlatform() ? "" : "mt-3"
                                } mb-2 font-weight-bold`}
                              >
                                Enter Your Email
                              </h1>

                              <div
                                className="mb-2 pb-2 text-dark"
                                style={{ fontSize: 18 }}
                              >
                                We'll send you an email containing a recover
                                your account.
                              </div>
                              <div
                                className="mb-4 pb-2 text-dark"
                                style={{ fontSize: 18 }}
                              >
                                <a
                                  href="#"
                                  rel="noreferrer"
                                  onClick={(e: any) => {
                                    e.preventDefault();

                                    this.setState({
                                      mode: "select",
                                    });
                                  }}
                                  className="text-orange-500"
                                >
                                  Choose A Different Method
                                </a>
                              </div>

                              <form
                                onSubmit={(e: any) => {
                                  e.preventDefault();
                                }}
                              >
                                <div className="mt-4">
                                  {this.state.error && (
                                    <div className="mb-3 text-danger">
                                      {this.state.error}
                                    </div>
                                  )}
                                  <div className="relative mb-4">
                                    <label
                                      htmlFor="email"
                                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs sm:text-sm font-medium text-gray-900"
                                    >
                                      Email
                                    </label>
                                    <input
                                      auto-capitalize="none"
                                      type="text"
                                      name="email"
                                      id="email"
                                      autoComplete="email"
                                      className="block w-full outline-0 rounded border-[1px] px-[0.75rem] py-[0.75rem] sm:px-3 sm:py-3 text-gray-900 shadow-sm ring-0 placeholder:text-gray-400 text-sm sm:text-base sm:leading-6"
                                      placeholder="your@email.com"
                                      value={this.state.email}
                                      required={true}
                                      onChange={(event: any) => {
                                        this.setState({
                                          email: event?.target?.value,
                                        });
                                      }}
                                    />
                                  </div>

                                  <IonButton
                                    type="submit"
                                    className="pln-button-web"
                                    mode="ios"
                                    expand={"block"}
                                    color="primary"
                                    disabled={
                                      !this.state.email ||
                                      !StringUtils.isValidEmail(
                                        this.state.email
                                      ) ||
                                      this.state.submitting
                                    }
                                    onClick={() => {
                                      this.sendRecovery(
                                        "email",
                                        this.state.email
                                      );
                                    }}
                                  >
                                    {this.state.submitting ? (
                                      <>
                                        <IonSpinner name="dots"></IonSpinner>
                                      </>
                                    ) : (
                                      <>Email Recovery Link</>
                                    )}
                                  </IonButton>
                                </div>
                              </form>
                            </>
                          </>
                        ) : null}
                        {this.state.mode == "emailconfirm" ? (
                          <>
                            <>
                              {!Capacitor.isNativePlatform() ? (
                                <p
                                  className="small text-gray-900 mt-4"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  <Link
                                    to="/login"
                                    className="text-orange-500 "
                                  >
                                    <div className="inline-block relative top-[3px] pr-1">
                                      <IonIcon icon={arrowBack}></IonIcon>
                                    </div>
                                    Return to Login
                                  </Link>
                                </p>
                              ) : null}
                              <h1 className="mt-3 text-3xl lg:text-4xl mb-0 font-weight-bold">
                                Check Your Email For A Recovery Link
                              </h1>
                              <p
                                className="small text-gray-900 mt-2"
                                style={{ lineHeight: 1.3 }}
                              >
                                An email containing a link to recover your
                                identity has been sent to the email
                                address&nbsp;
                                <span className="text-dark font-weight-500">
                                  {this.state.email}
                                </span>
                                . Click the link inside the email to update your
                                password.
                              </p>
                              <p className="small text-gray-900 mt-2">
                                Didn't receive it?{" "}
                                <a
                                  href="#"
                                  rel="noreferrer"
                                  onClick={(e: any) => {
                                    e.preventDefault();

                                    this.sendRecovery(
                                      "email",
                                      this.state.email
                                    );
                                  }}
                                  className="text-orange-500"
                                >
                                  {this.state.submitting
                                    ? "Resending..."
                                    : "Resend Email"}
                                </a>
                              </p>
                            </>
                          </>
                        ) : null}
                        {this.state.mode == "sms" ? (
                          <>
                            <>
                              {!Capacitor.isNativePlatform() ? (
                                <p
                                  className="small text-gray-900 mt-4"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  <Link
                                    to="/login"
                                    className="text-orange-500 "
                                  >
                                    <div className="inline-block relative top-[3px] pr-1">
                                      <IonIcon icon={arrowBack}></IonIcon>
                                    </div>
                                    Return to Login
                                  </Link>
                                </p>
                              ) : null}
                              <h1
                                className={`text-3xl lg:text-4xl ${
                                  Capacitor.isNativePlatform() ? "" : "mt-3"
                                } mb-2 font-weight-bold`}
                              >
                                Enter Your Phone Number
                              </h1>

                              <div
                                className="mb-2 pb-2 text-dark"
                                style={{ fontSize: 18 }}
                              >
                                We'll send you a text message containing a
                                verification code to recover your account.
                              </div>
                              <div
                                className="mb-4 pb-2 text-dark"
                                style={{ fontSize: 18 }}
                              >
                                <a
                                  href="#"
                                  rel="noreferrer"
                                  onClick={(e: any) => {
                                    e.preventDefault();

                                    this.setState({
                                      mode: "select",
                                    });
                                  }}
                                  className="text-orange-500"
                                >
                                  Choose A Different Method
                                </a>
                              </div>

                              <form
                                onSubmit={(e: any) => {
                                  e.preventDefault();
                                }}
                              >
                                <div className="mt-4">
                                  {this.state.error && (
                                    <div className="mb-3 text-danger">
                                      {this.state.error}
                                    </div>
                                  )}

                                  <div className="relative mb-4">
                                    <label
                                      htmlFor="phone"
                                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs sm:text-sm font-medium text-gray-900"
                                    >
                                      Phone
                                    </label>
                                    <input
                                      auto-capitalize="none"
                                      type="text"
                                      name="phone"
                                      id="phone"
                                      autoComplete="tel"
                                      className="block w-full outline-0 rounded border-[1px] px-[0.75rem] py-[0.75rem] sm:px-3 sm:py-3 text-gray-900 shadow-sm ring-0 placeholder:text-gray-400 text-sm sm:text-base sm:leading-6"
                                      placeholder="+1 555-555-5555"
                                      value={this.state.phoneReadable}
                                      required={true}
                                      onChange={(event: any) => {
                                        const res: any =
                                          StringUtils.formatPhoneNumberWhileTyping(
                                            event?.target?.value?.toString()
                                          );

                                        this.setState({
                                          phone: res?.value
                                            ?.split(" ")
                                            .join(""),
                                          phoneReadable: res?.formatted,
                                        });
                                      }}
                                    />
                                  </div>

                                  <IonButton
                                    type="submit"
                                    className="pln-button-web"
                                    mode="ios"
                                    expand={"block"}
                                    color="primary"
                                    disabled={
                                      !this.state.phone ||
                                      !StringUtils.isValidPhone(
                                        this.state.phone
                                      ) ||
                                      this.state.submitting
                                    }
                                    onClick={() => {
                                      this.sendRecovery(
                                        "phone",
                                        this.state.phone
                                      );
                                    }}
                                  >
                                    {this.state.submitting ? (
                                      <>
                                        <IonSpinner name="dots"></IonSpinner>
                                      </>
                                    ) : (
                                      <>Send Code</>
                                    )}
                                  </IonButton>
                                </div>
                              </form>
                            </>
                          </>
                        ) : null}
                        {this.state.mode == "phoneverify" ? (
                          <>
                            <>
                              {!Capacitor.isNativePlatform() ? (
                                <p
                                  className="small text-gray-900 mt-4"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  <Link
                                    to="/login"
                                    className="text-orange-500 "
                                  >
                                    <div className="inline-block relative top-[3px] pr-1">
                                      <IonIcon icon={arrowBack}></IonIcon>
                                    </div>
                                    Return to Login
                                  </Link>
                                </p>
                              ) : null}
                              <h1
                                className={`text-3xl lg:text-4xl ${
                                  Capacitor.isNativePlatform() ? "" : "mt-3"
                                } mb-2 font-weight-bold`}
                              >
                                Enter Your Verification Code
                              </h1>

                              <div
                                className="mb-2 pb-2 text-dark"
                                style={{ fontSize: 18 }}
                              >
                                A text message containing a verification code
                                has been sent to the phone number:{" "}
                                <span className="font-weight-500 text-dark">
                                  {this.state.phoneReadable}
                                </span>
                                . Enter the code below to continue.
                              </div>
                              <div
                                className="mb-4 pb-2 text-dark"
                                style={{ fontSize: 18 }}
                              >
                                Didn't receive it?{" "}
                                <a
                                  href="#"
                                  rel="noreferrer"
                                  onClick={(e: any) => {
                                    e.preventDefault();

                                    this.sendRecovery(
                                      "phone",
                                      this.state.phone
                                    );
                                  }}
                                  className="text-orange-500"
                                >
                                  {this.state.submitting
                                    ? "Resending..."
                                    : "Resend Code"}
                                </a>
                              </div>

                              <form
                                onSubmit={(e: any) => {
                                  e.preventDefault();
                                }}
                              >
                                <div className="mt-4">
                                  {this.state.error && (
                                    <div className="mb-3 text-danger">
                                      {this.state.error}
                                    </div>
                                  )}
                                  <div className="relative mb-4">
                                    <label
                                      htmlFor="verification"
                                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs sm:text-sm font-medium text-gray-900"
                                    >
                                      Verification Code
                                    </label>
                                    <input
                                      auto-capitalize="none"
                                      type="text"
                                      name="verification"
                                      id="verification"
                                      autoComplete="one-time-code"
                                      className="block w-full outline-0 rounded border-[1px] px-[0.75rem] py-[0.75rem] sm:px-3 sm:py-3 text-gray-900 shadow-sm ring-0 placeholder:text-gray-400 text-sm sm:text-base sm:leading-6"
                                      placeholder="123456"
                                      value={this.state.email}
                                      required={true}
                                      onChange={(event: any) => {
                                        this.setState({
                                          code: event?.target?.value,
                                        });
                                      }}
                                    />
                                  </div>

                                  <IonButton
                                    type="submit"
                                    className="pln-button-web"
                                    mode="ios"
                                    expand={"block"}
                                    color="primary"
                                    disabled={
                                      !this.state.code || this.state.submitting
                                    }
                                    onClick={() => {
                                      this.verifyCode();
                                    }}
                                  >
                                    {this.state.submitting ? (
                                      <>
                                        <IonSpinner name="dots"></IonSpinner>
                                      </>
                                    ) : (
                                      <>Verify</>
                                    )}
                                  </IonButton>
                                </div>
                              </form>
                            </>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </IonCol>
                  <IonCol
                    className="d-none d-md-flex p-0 right-col"
                    style={{ position: "relative" }}
                  >
                    <div className="absolute left-[0px] top-[0px] w-full">
                      <div
                        className="flex items-start justify-start pb-4"
                        style={{
                          background:
                            "linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 71%, rgba(0,0,0,0.15) 91%, rgba(0,0,0,0) 100%)",
                        }}
                      >
                        <img
                          src={whiteSymbol}
                          alt="Project LeanNation Logo"
                          className="mt-[2rem] ml-[2rem] w-[40%] max-w-[100px]"
                        ></img>
                      </div>
                    </div>
                    <div
                      className="w-full h-[100vh] bg-cover bg-center bg-norepeat border-l-[1px] border-gray-200"
                      style={{
                        backgroundImage: `url(${mobileLoginBG})`,
                      }}
                    ></div>
                    <div
                      className="absolute bottom-[0px] left-[0px] w-full p-[1.5rem] lg:p-[2rem]"
                      style={{
                        background:
                          "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 6%, rgba(0,0,0,0.5519502801120448) 29%, rgba(0,0,0,0.8) 100%)",
                      }}
                    >
                      <div className="mb-3">
                        <img
                          src={memberTrust}
                          className="w-[60%] max-w-[200px]"
                          alt="Trust"
                        />
                      </div>
                      <h1 className="text-3xl lg:text-4xl tracking-tight text-white font-bold">
                        Achieve More, Every Day
                      </h1>
                      <p className="text-white tracking-tight font-medium text-xl lg:text-2xl mt-2">
                        with tools, guidance, and prepared meals designed for
                        real progress.
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default ForgotPassword;
