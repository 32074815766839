/**
 * Basic Pub/Sub protocol
 *
 * Author: Brandon Hudson
 * Created: 12-15-2019
 */

class PostHogUtils {
  static registry: any = {};

  /**
   * Get feature flag
   *
   * @param name
   * @returns
   */
  static async getFeatureFlag(name: string) {
    return new Promise((resolve) => {
      const win: any = window;

      if (!win?.posthog?.ffEnabled) {
        win?.posthog?.onFeatureFlags(function () {
          // feature flags should be available at this point
          return resolve(win?.posthog.getFeatureFlag(name));
        });
      } else {
        return resolve(win?.posthog.getFeatureFlag(name));
      }
    });
  }
}

export default PostHogUtils;
