import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";

import "./SchedulingHours.css";

import moment from "moment-timezone";
import _ from "underscore";
import { chevronBack, chevronForward } from "ionicons/icons";
import SchedulingHourButton from "../SchedulingHourButton/SchedulingHourButton";
import API from "../../../lib/API";
import WindowUtils from "../../../lib/WindowUtils";
import Event from "../../../lib/Event";
import PubSub from "../../../lib/PubSub";

const SchedulingHours: React.FC<{
  store?: any;
  onDateSelected?: any;
  appointmentType?: any;
  value?: any;
  viewingDate?: any;
  maxHeight?: any;
  showContinueButton?: boolean;
  onContinue?: any;
  bgWhite?: boolean;
  allowModification?: boolean;
}> = ({
  appointmentType = null,
  store = null,
  onDateSelected = null,
  value = null,
  viewingDate = null,
  maxHeight = null,
  showContinueButton = null,
  onContinue = null,
  bgWhite = false,
  allowModification = true,
}) => {
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [hours, setHours] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [minDate, setMinDate] = useState(moment().startOf("day").toDate());
  const [maxDate, setMaxDate] = useState(
    moment().add(60, "days").endOf("day").toDate()
  );

  /**
   * Handles on change events.
   *
   * @param date
   */
  const onChange = (date: any) => {
    setSelectedDate(date);

    if (typeof onDateSelected == "function") {
      onDateSelected(date);
    }
  };

  useEffect(() => {
    if (value !== value) {
      setSelectedDate(value);
    }
  }, [value]);

  /**
   * Fetches existing appointments for the date selected
   *
   * @param store
   * @param appointmentType
   * @param date
   * @returns
   */
  const getExistingAppointments = async (
    store: any,
    appointmentType: any,
    date: any
  ) => {
    return new Promise((resolve, reject) => {
      API.getExistingAppointments(
        store?._id,
        appointmentType?.APPOINTMENT_TYPE,
        date.toISOString()
      ).then(
        (data: any) => {
          const appointments = data?.data?.data?.appointments
            ? data.data.data.appointments
            : [];

          resolve(appointments);
        },
        (e: any) => {
          reject(e);
        }
      );
    });
  };

  const getHourlyTimeslots = async (store: any, date: any) => {
    if (!date) {
      return [];
    }

    let day = date.getDay();

    if (day == 0) {
      day = "Sunday";
    } else if (day == 1) {
      day = "Monday";
    } else if (day == 2) {
      day = "Tuesday";
    } else if (day == 3) {
      day = "Wednesday";
    } else if (day == 4) {
      day = "Thursday";
    } else if (day == 5) {
      day = "Friday";
    } else if (day == 6) {
      day = "Saturday";
    }

    if (!store?.operations?.hours?.standard?.length) {
      return [];
    }

    let hours = _.findWhere(store?.operations?.hours?.standard, {
      day,
    });

    if (!hours || hours?.closed || !hours.opensAt || !hours.closesAt) {
      return [];
    }

    let opensAtInt: any = parseInt(hours.opensAt?.replace(":")) * 60;
    let closesAtInt: any = parseInt(hours.closesAt?.replace(":")) * 60;

    let now = store?.timezone ? moment.tz(store?.timezone) : moment();

    let chosenDate = store?.timezone
      ? moment.tz(date?.toISOString(), store?.timezone)
      : moment(date?.toISOString());

    let opensAt = store?.timezone
      ? moment(hours?.opensAt, "HH:mm").tz(store?.timezone)
      : moment(hours?.opensAt, "HH:mm");

    if (chosenDate?.format("YYYY-MM-DD") == now?.format("YYYY-MM-DD")) {
      if (now?.toDate() <= opensAt?.toDate()) {
        opensAtInt = store?.timezone
          ? moment(hours?.opensAt, "HH:mm").tz(store?.timezone)
          : moment(hours?.opensAt, "HH:mm").add(30, "minutes");
      } else {
        if (now.minutes() >= 20) {
          now = now
            .hour(now.hour() + 1)
            .minutes(0)
            .seconds(0);
        } else {
          now = now.minutes(30).seconds(0);
        }

        opensAtInt = now;
      }
    } else {
      opensAtInt = store?.timezone
        ? moment(hours?.opensAt, "HH:mm").tz(store?.timezone).add(30, "minutes")
        : moment(hours?.opensAt, "HH:mm").add(30, "minutes");
    }

    closesAtInt = moment(hours?.closesAt, "HH:mm").subtract(60, "minutes");

    let out = [];

    let counter = moment(opensAtInt.toISOString());

    while (counter.toDate() <= closesAtInt.toDate()) {
      const dateVal = store?.timezone
        ? moment.tz(counter.toDate(), store?.timezone)
        : moment(counter.toDate());

      dateVal
        .day(chosenDate.day())
        .month(chosenDate.month())
        .year(chosenDate.year());

      out.push({
        label: counter.format("h:mm A"),
        value: counter.format("HH:mm"),
        date: dateVal.toDate(),
        status: "available",
      });

      counter.add(30, "minutes");
    }

    let existing: any = [];

    try {
      existing = await getExistingAppointments(store, appointmentType, date);
    } catch (e: any) {}

    for (let i = 0; i < existing?.length; i++) {
      const startTime = store?.timezone
        ? moment
            .tz(existing[i]?.startsAt, store?.timezone)
            .seconds(0)
            .millisecond(0)
            .format("HH:mm")
        : moment(existing[i]?.startsAt)
            .seconds(0)
            .millisecond(0)
            .format("HH:mm");

      let match = _.filter(out, (item) => {
        return item?.value == startTime;
      });

      let allowedCount = store?.appointmentsPerSlot
        ? store?.appointmentsPerSlot
        : 1;

      if (match?.length >= allowedCount) {
        out = out?.map((item: any) => {
          if (item?.value == startTime) {
            item.status = "unavailable";
          }

          return item;
        });
      }
    }

    return out;
  };

  useEffect(() => {
    if (value != selectedDate) {
      setSelectedDate(value);
    }
  }, [value]);

  const viewingDateMoment = useMemo(() => {
    if (!viewingDate) {
      return null;
    }

    return store?.timezone
      ? moment.tz(viewingDate, store?.timezone)
      : moment(viewingDate);
  }, [viewingDate, store]);

  useEffect(() => {
    if (!store || !viewingDate) {
      return;
    }

    setLoading(true);

    getHourlyTimeslots(store, viewingDateMoment?.toDate()).then(
      (hours: any) => {
        setHours(hours);

        setLoading(false);
      }
    );

    // console.log("RENDER");
  }, [store, viewingDate]);

  const slots = useMemo(() => {
    const available = _.filter(hours, (hour: any) => {
      return hour?.status == "available";
    });

    return available?.length;
  }, [hours]);

  return (
    <>
      <div
        style={{
          borderRadius: 6,
          border: "1px solid var(--midlighter)",
          maxHeight: maxHeight ? maxHeight : null,
          overflowY: "auto",
          overflowX: "hidden",
        }}
        className={`p-3 scheduling-hours-container ${bgWhite && "bg-white"}`}
      >
        {selectedDate ? (
          <>
            <h5 className="m-0 text-center">
              {viewingDateMoment?.format("MMMM DD, YYYY")}
            </h5>
            <div
              className="text-center mt-3 mb-3 d-flex ion-justify-content-center ion-align-items-center"
              style={{
                height: 50,
                width: "100%",
                color: "var(--dark)",
                borderRadius: 6,

                fontWeight: "bold",
              }}
            >
              <h1 className="m-0 text-center" style={{ width: "100%" }}>
                {moment(selectedDate).format("h:mm A")}
              </h1>
            </div>
            <IonButton
              className="pln-button-web-outline"
              color="dark"
              fill="outline"
              mode="ios"
              expand="block"
              onClick={() => {
                onChange(null);
              }}
            >
              Change Time
            </IonButton>
            {showContinueButton ? (
              <IonButton
                className="pln-button-web mt-3"
                color="primary"
                mode="ios"
                expand="block"
                onClick={() => {
                  const out = moment(viewingDateMoment?.toISOString());

                  const selectedMoment = moment(selectedDate);

                  out
                    .hours(selectedMoment.hours())
                    .minutes(selectedMoment.minutes())
                    .seconds(0)
                    .milliseconds(0);

                  //    console.log(out?.toDate());

                  if (typeof onContinue == "function") {
                    onContinue(out?.toDate());
                  }
                }}
              >
                Continue
              </IonButton>
            ) : null}
          </>
        ) : (
          <>
            <p className="m-0 mb-3 text-center font-weight-500">
              Availability
              {viewingDateMoment
                ? ` For ${viewingDateMoment.format("MM/DD/YY")}`
                : ""}
              {slots ? (
                <div
                  style={{
                    fontSize: 12,
                    paddingTop: 2,
                    color: "var(--medium)",
                  }}
                >
                  {slots} timeslots available
                </div>
              ) : (
                ""
              )}
            </p>
            <div
              className="scheduling-hours-container"
              style={{
                maxHeight: maxHeight
                  ? `calc(${maxHeight}px - ${slots ? "90" : "72"}px)`
                  : "",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {loading ? (
                <>
                  <div className="mb-3">
                    <SchedulingHourButton loading={true}></SchedulingHourButton>
                  </div>
                  <div className="mb-3">
                    <SchedulingHourButton loading={true}></SchedulingHourButton>
                  </div>
                  <div className="">
                    <SchedulingHourButton loading={true}></SchedulingHourButton>
                  </div>
                </>
              ) : (
                <>
                  {hours?.length ? (
                    <>
                      {hours.map((hour: any, i: any) => (
                        <div
                          key={i}
                          className={`${i != hours?.length - 1 && "mb-3"}`}
                        >
                          <SchedulingHourButton
                            loading={false}
                            disabled={hour?.status == "unavailable"}
                            date={hour?.date}
                            onDateSelected={(date: any) => {
                              if (!allowModification) {
                                return onContinue(date);
                              }

                              onChange(date);
                            }}
                          ></SchedulingHourButton>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div
                        className="text-center d-flex ion-justify-content-center ion-align-items-center"
                        style={{
                          height: 100,
                          width: "100%",
                          border: "1px solid var(--midlighter)",
                          color: "var(--medium)",
                          borderRadius: 6,
                        }}
                      >
                        <h4
                          className="m-0 text-center"
                          style={{ width: "100%" }}
                        >
                          {viewingDateMoment
                            ? "No Availability"
                            : "Select A Date"}
                        </h4>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SchedulingHours;
