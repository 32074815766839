import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";
import { useParams } from "react-router";
import Navbar from "../../../../components/Checkout/Navbar/Navbar";
import ConversionForm from "../../../../components/ConversionForm/ConversionForm";
import "./Start.css";

import background from "../../../../assets/img/backgrounds/bg_meals_superlight_lg.jpg";
import backgroundSM from "../../../../assets/img/backgrounds/bg_meals_superlight_sm.jpg";
import backgroundWebp from "../../../../assets/img/backgrounds/bg_meals_superlight_lg.webp";
import backgroundSMWebp from "../../../../assets/img/backgrounds/bg_meals_superlight_sm_white.webp";
import personalized from "../../../../assets/img/icons/personalized.svg";
import coaching from "../../../../assets/img/icons/coaching.svg";
import inbody from "../../../../assets/img/icons/inbody.svg";
import store from "../../../../assets/img/icons/store.svg";

import { useMemo, useState } from "react";
import MealTopicSlider from "../../../../components/ConversionForm/MealTopicSlider";
import TestimonialSlider from "../../../../components/ConversionForm/TestimonialSlider";
import FAQQuestions from "../../../../components/FAQQuestions";
import Footer from "../../../../components/Checkout/Footer";
import SEOHeaders from "../../../../components/SEOHeaders";
import WindowUtils from "../../../../lib/WindowUtils";
import NavbarV2 from "../../../../components/Checkout/Navbar/NavbarV2";

const webP = WindowUtils.supportFormatWebp();

const Start: React.FC = () => {
  const logEvents = useMemo(() => {
    const w: any = window;

    if (w?.posthog) {
      w.posthog.capture("$pageview");
    }
  }, []);

  return (
    <IonPage>
      <NavbarV2
        showSupportOptions={false}
        onSupportChat={() => {}}
        onSupportCall={() => {}}
        activeStep={1}
        showBackButton={false}
      ></NavbarV2>

      <IonContent>
        <div
          className="pb-4 px-4 pt-4 pt-lg-5 pb-lg-5 d-none d-md-block"
          style={{
            background: `url(${
              webP ? backgroundWebp : background
            }) center center / cover`,
            borderBottom: "1px solid var(--lighter)",
          }}
        >
          {/** Desktop View */}
          <div style={{ maxWidth: 1200, margin: "auto" }}>
            <div style={{ maxWidth: 700, margin: "auto" }}>
              <IonCard className="pln-card-web m-0" mode="ios">
                <IonCardContent className="p-4">
                  <ConversionForm mode={"desktop"}></ConversionForm>
                </IonCardContent>
              </IonCard>
            </div>
          </div>
        </div>

        <div
          className="d-block d-md-none"
          style={{ background: "var(--ultralight)" }}
        >
          {/** Mobile View */}
          <div className="pt-4 pb-4 px-3">
            <ConversionForm></ConversionForm>
          </div>
          <div
            style={{
              height: 250,
              width: "100%",
              background: `url(${
                webP ? backgroundSMWebp : backgroundSM
              }) center top / cover`,
              borderBottom: "1px solid var(--lighter)",
            }}
          ></div>
        </div>

        <div
          style={{
            background: "var(--white)",
          }}
        >
          <div className="pt-4 pt-lg-5 pb-2 pb-lg-0 px-4">
            <div className="text-center">
              <h1 className="display-3 mb-0">More than just a meal kit.</h1>
              <p className="lead-sm mt-2">
                Going the extra mile to make sure you achieve your health goals
                by offering free services with all our subscriptions.
              </p>
            </div>
          </div>
          <div
            className="px-4 pb-5 mt-3 mt-lg-5"
            style={{ maxWidth: 1200, margin: "auto" }}
          >
            <IonGrid>
              <IonRow>
                <IonCol size-xs="6" size-sm="3" className="text-center">
                  <div className="d-none d-lg-block">
                    <img
                      src={coaching}
                      alt="Personalized Plan Icon"
                      style={{ height: 60, width: 60 }}
                    ></img>
                    <div className="mt-3">
                      <h3 className="font-weight-500 text-dark">
                        One-on-One<br></br>Coaching
                      </h3>
                    </div>
                  </div>
                  <div className="d-block d-lg-none">
                    <img
                      src={coaching}
                      alt="Personalized Plan Icon"
                      style={{ height: 50, width: 50 }}
                    ></img>
                    <div className="mt-2 mb-4 mb-sm-0">
                      <h5 className="m-0 font-weight-500 text-dark">
                        One-on-One<br></br>Coaching
                      </h5>
                    </div>
                  </div>
                </IonCol>
                <IonCol size-xs="6" size-sm="3" className="text-center">
                  <div className="d-none d-lg-block">
                    <img
                      src={personalized}
                      alt="Personalized Plan Icon"
                      style={{ height: 60, width: 60 }}
                    ></img>
                    <div className="mt-3">
                      <h3 className="font-weight-500 text-dark">
                        Personalized <br></br>Nutrition Plans
                      </h3>
                    </div>
                  </div>
                  <div className="d-block d-lg-none">
                    <img
                      src={personalized}
                      alt="Personalized Plan Icon"
                      style={{ height: 50, width: 50 }}
                    ></img>
                    <div className="mt-2 mb-4 mb-sm-0">
                      <h5 className="font-weight-500 m-0 text-dark">
                        Personalized <br></br>Nutrition Plans
                      </h5>
                    </div>
                  </div>
                </IonCol>
                <IonCol size-xs="6" size-sm="3" className="text-center">
                  <div className="d-none d-lg-block">
                    <img
                      src={inbody}
                      alt="Personalized Plan Icon"
                      style={{ height: 60, width: 60 }}
                    ></img>
                    <div className="mt-3">
                      <h3 className="font-weight-500 text-dark">
                        Body Composition Insights
                      </h3>
                    </div>
                  </div>
                  <div className="d-block d-lg-none">
                    <img
                      src={inbody}
                      alt="Personalized Plan Icon"
                      style={{ height: 50, width: 50 }}
                    ></img>
                    <div className="mt-2">
                      <h5 className="m-0 font-weight-500 text-dark">
                        Body Tracking <br></br>&amp; Insights
                      </h5>
                    </div>
                  </div>
                </IonCol>
                <IonCol size-xs="6" size-sm="3" className="text-center">
                  <div className="d-none d-lg-block">
                    <img
                      src={store}
                      alt="Personalized Plan Icon"
                      style={{ height: 60, width: 60 }}
                    ></img>
                    <div className="mt-3">
                      <h3 className="font-weight-500 text-dark">
                        Local Stores<br></br> &amp; Online
                      </h3>
                    </div>
                  </div>
                  <div className="d-block d-lg-none">
                    <img
                      src={store}
                      alt="Personalized Plan Icon"
                      style={{ height: 50, width: 50 }}
                    ></img>
                    <div className="mt-2">
                      <h5 className="m-0 font-weight-500 text-dark">
                        Local Stores<br></br> &amp; Online
                      </h5>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>

        <div
          className="pt-4 pt-lg-5 pb-2 pb-lg-0 px-4"
          style={{ background: "white" }}
        >
          <div className="text-center">
            <h1 className="display-3 mb-0">
              Never get bored. Flexible menu every week.
            </h1>
            <p className="lead-sm mt-2">
              Choose from 25+ healthy & delicious options on our regularly
              rotating menu.
            </p>
          </div>
        </div>
        <div style={{ background: "white" }}>
          <div
            className="px-4 pb-5 mt--5 pt-2 pt-lg-4"
            style={{ maxWidth: 1200, margin: "auto" }}
          >
            <MealTopicSlider></MealTopicSlider>
          </div>
        </div>

        <div
          style={{
            background: "var(--superlight)",
            borderTop: "1px solid var(--midlighter)",
            borderBottom: "1px solid var(--midlighter)",
          }}
        >
          <div className="pt-4 pt-lg-5 pb-2 pb-lg-0 px-4">
            <div className="text-center">
              <h1 className="display-3 mb-0">
                Helping you achieve your dream results.
              </h1>
              <p className="lead-sm mt-2">
                We've helped 15,000+ people just like you achieve incredible
                health & weight loss results. Here's what a few of them had to
                say.
              </p>
            </div>
          </div>
          <div
            className="px-4 pb-5 mt-2 mt-md-5"
            style={{ maxWidth: 1200, margin: "auto" }}
          >
            <TestimonialSlider></TestimonialSlider>
          </div>
        </div>
        <div>
          <div className="pt-4 pt-lg-5 pb-2 pb-lg-0 px-4">
            <div className="text-center">
              <h1 className="display-3 mb-0">Frequently Asked Questions</h1>
              <p className="lead-sm mt-2">
                Have questions? We're happy to help!
              </p>
            </div>
          </div>
          <div
            className="px-2 px-lg-2 pb-3 mt-2 mt-md-5"
            style={{ maxWidth: 1200, margin: "auto" }}
          >
            <FAQQuestions></FAQQuestions>
          </div>
        </div>
        <Footer></Footer>
      </IonContent>
    </IonPage>
  );
};

export default Start;
