import {
  AsYouType,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";

export default class StringUtils {
  static centsToCurrency(cents: number = 0) {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(cents / 100);
  }

  static getFirstName(str: string = "") {
    if (!str) {
      return "";
    }

    let strArr = str.split(" ");

    if (strArr.length == 1) {
      return str;
    }

    return strArr[0];
  }

  static getLastName(str: string = "") {
    if (!str) {
      return "";
    }

    let nameParts = str.split(" ");
    nameParts.shift(); // Removes the first name
    return nameParts.join(" "); // Joins the rest of the array back into a string
  }

  static generateCouponValueString(coupon: any, truncated = false) {
    if (!coupon) {
      return "";
    }

    if (coupon?.couponUsage == "tiered") {
      let tierStrings = [];

      for (let i = 0; i < coupon?.couponTiers?.length; i++) {
        const tier = coupon?.couponTiers[i];

        let intro =
          i == 0
            ? "your first"
            : i == coupon?.couponTiers?.length - 1
            ? `${tier.maxNumberUses == 9999 ? "all " : ""}your following`
            : "your next";
        let value = "";
        let count = `${
          tier.maxNumberUses != 9999 ? tier.maxNumberUses + " " : ""
        }weeks`;

        if (tier.maxNumberUses == 1) {
          count = "week";
        }

        if (tier.discountType == "Flat") {
          value = this.centsToCurrency(tier?.flatDiscount);
        } else {
          value = tier?.percentDiscount * 100 + "%";
        }

        tierStrings.push(
          `${
            i == coupon?.couponTiers?.length - 1 ? "and " : ""
          }${value} off ${intro} ${count}`
        );
      }

      let out = `${!truncated ? "You'll receive " : ""}`;

      for (let i = 0; i < tierStrings?.length; i++) {
        const string = tierStrings[i];

        out += string;

        if (i != tierStrings?.length - 1) {
          out += ", ";
        }
      }

      return out + "!";
    } else {
      let out = `${!truncated ? "You'll receive " : ""}`;

      let discount =
        coupon?.discountType == "Flat"
          ? coupon?.removeFromSubscriptionAfterUses > 1
            ? this.centsToCurrency(
                coupon?.flatDiscount * coupon?.removeFromSubscriptionAfterUses
              )
            : this.centsToCurrency(coupon?.flatDiscount)
          : coupon?.percentDiscount * 100 + "%";

      out += `${discount} off `;

      if (coupon?.removeFromSubscriptionAfterUses == -1) {
        out += "for Life";
      } else if (coupon?.removeFromSubscriptionAfterUses == 1) {
        out += "your first week";
      } else if (coupon?.removeFromSubscriptionAfterUses > 1) {
        out += `your first ${coupon?.removeFromSubscriptionAfterUses} weeks`;
      }

      return out;
    }
  }

  static htmlToText(html: any) {
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }
  /**
   * Generates a request ID string
   *
   * @param length number the length of the requestID
   * @return string
   */
  static requestID(length: number = 12) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /**
   * Formats a phone number partial while the user is typing it
   *
   * @param phone
   * @returns
   */
  static formatPhoneNumberWhileTyping(phone: string) {
    if (!phone) {
      return {
        value: "",
        formatted: "",
      };
    }

    let resp: any = "";

    if (phone == "+") {
      return {
        value: phone?.split(" ").join(""),
        formatted: "+",
      };
    }

    if (!phone?.startsWith("1") && !phone?.startsWith("+1")) {
      phone = `1${phone}`;
    }

    if (!phone?.startsWith("+")) {
      phone = `+${phone}`;
    }

    resp = new AsYouType().input(phone);

    return {
      value: phone?.split(" ")?.join(""),
      formatted: resp,
    };
  }

  static formatPhoneNumber(phoneNumberString = "") {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  static formatExpiryWhileTyping(exp: string, prev: string) {
    if (!exp) {
      return {
        value: "",
        formatted: "",
      };
    }

    let resp: any = "";

    let res = exp.replace(/[^0-9/]/g, "");

    res = res.replace(/^(\d{2}\/\d{2}).*|^[^\/\d].*/g, "$1");

    if (res?.length == 1 && res != "1" && res != "0") {
      res = "0" + res;
    }

    if (res?.length == 2 && prev?.indexOf("/") == -1) {
      res += "/";
    }

    if (res?.length == 3 && res?.indexOf("/") == -1) {
      res = res?.substring(0, 2) + "/" + res.substring(2, 3);
    }

    if (res?.length > 5) {
      res = res.substring(0, 5);
    }

    if (res?.length > 3) {
      res =
        res.split("/")[0] +
        "/" +
        res.substring(3, res?.length).split("/").join("");
    }

    return {
      value: res,
      formatted: res,
    };
  }

  static formatCVCWhileTyping(exp: string) {
    if (!exp) {
      return {
        value: "",
        formatted: "",
      };
    }

    let resp: any = "";

    let res = exp.replace(/[^0-9]/g, "");

    return {
      value: res,
      formatted: res,
    };
  }

  static formatCardNumberWhileTyping(exp: string) {
    if (!exp) {
      return {
        value: "",
        formatted: "",
      };
    }

    let res = exp.replace(/[^0-9]/g, "");

    let formatted = res
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();

    if (res?.length > 16) {
      formatted = res;
    }

    return {
      value: res,
      formatted,
    };
  }

  /**
   * Determines if a phone number is a valid format
   *
   * @param phone
   * @returns
   */
  static isValidPhone(phone: string) {
    if (!phone) {
      return false;
    }

    try {
      const phoneNumber = parsePhoneNumber(phone);

      let out = phoneNumber.formatInternational().split(" ").join("");

      let valid = isValidPhoneNumber(out, "US");

      return valid;
    } catch (e: any) {
      return false;
    }
  }

  /**
   * Formats a phone number for the server.
   *
   * @param phone
   * @returns
   */
  static formatPhoneForServer(phone: string) {
    if (!this.isValidPhone(phone)) {
      return null;
    }

    try {
      let out = "";

      if (out?.length == 10) {
        out = `+1${out}`;
      }

      if (out.startsWith("1") && out?.length == 11) {
        out = `+${out}`;
      }

      const phoneNumber = parsePhoneNumber(phone);

      out = phoneNumber.formatInternational().split(" ").join("");

      if (out?.startsWith("+")) {
        return out;
      }
    } catch (e: any) {
      return null;
    }
  }

  /**
   * Determines whether an email is a valid format
   *
   * @param email
   * @returns
   */
  static isValidEmail(email: string) {
    // Regular expression for validating email format
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
  }

  static getMarketingName(store: any) {
    if (!store) {
      return "";
    }

    if (store?.displayCity && store?.displayState) {
      return `${store?.displayCity?.trim()}, ${store?.displayState?.trim()}`;
    }

    return `${store?.location?.address?.city?.trim()}, ${store?.location?.address?.state?.trim()}`;
  }

  static uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        // eslint-disable-next-line
        let r = (Math.random() * 16) | 0,
          // eslint-disable-next-line
          v = c == "x" ? r : (r & 0x3) | 0x8;
        // eslint-disable-next-line
        return v.toString(16);
      }
    );
  }

  /**
   * Identifies a credit card type by the card number
   *
   * @param cardNumber
   * @returns
   */
  static identifyCardType(cardNumber: any = null) {
    if (!cardNumber) {
      return "unknown";
    }

    const num = cardNumber.toString();

    // Check for Visa: length 13 or 16, starts with 4
    if ((num.length === 13 || num.length === 16) && num.startsWith("4")) {
      return "visa";
    }

    // Check for MasterCard: length 16, starts with 51 through 55
    if (num.length === 16 && num.match(/^5[1-5]/)) {
      return "mastercard";
    }

    // Check for Amex: length 15, starts with 34 or 37
    if (num.length === 15 && num.match(/^(34|37)/)) {
      return "amex";
    }

    const discoverRegex =
      /^(6011|622(12[6-9]|1[3-9][0-9]|2[8][0-9][0-9]|9([01][0-9]|2[0-5]))|64[4-9]|65)/;

    // Check for Discover: length 16, starts with 6011, 622126-622925, 644-649, or 65
    if (num.length === 16 && num.match(discoverRegex)) {
      return "discover";
    }

    // If nothing matched
    return "unknown";
  }

  static numberFormat(num: any) {
    var formatter = new Intl.NumberFormat("en-US");

    return formatter.format(num);
  }

  static ingredientsToHTML(input: string) {
    if (!input) {
      return input;
    }

    try {
      // Function to parse the ingredient list
      const parseIngredients: any = (str: any) => {
        const result = [];
        let bracketCount = 0;
        let currentIngredient = "";
        for (let char of str) {
          if (char === "[") bracketCount++;
          else if (char === "]") bracketCount--;
          else if (char === "," && bracketCount === 0) {
            result.push(
              currentIngredient
                ?.split("*")
                ?.join("")
                ?.split(".")
                ?.join("")
                ?.trim()
            );
            currentIngredient = "";
            continue;
          }
          currentIngredient += char;
        }
        result.push(
          currentIngredient?.split("*")?.join("")?.split(".")?.join("")?.trim()
        ); // Push the last ingredient
        return result.map((ingredient) => {
          if (ingredient.includes("[")) {
            const [name, subIngredientsStr] = ingredient.split("[");
            return {
              name: name.trim(),
              subIngredients: parseIngredients(subIngredientsStr.slice(0, -1)), // Remove closing bracket and recurse
            };
          } else {
            return { name: ingredient };
          }
        });
      };

      // Function to construct HTML from the parsed ingredients
      const constructHTML = (ingredients: any) => {
        let html = "<ul>";
        for (let ingredient of ingredients) {
          html += `<li>${ingredient.name
            ?.split("*")
            ?.join("")
            ?.split(".")
            ?.join("")
            ?.trim()}`;
          if (ingredient.subIngredients) {
            html += constructHTML(ingredient.subIngredients); // Recursively construct sub-ingredient lists
          }
          html += "</li>";
        }
        html += "</ul>";
        return html;
      };

      const parsedIngredients = parseIngredients(input);
      return constructHTML(parsedIngredients);
    } catch (e: any) {
      return input;
    }
  }
}
