import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Capacitor } from "@capacitor/core";
import Constants from "./lib/Constants";
import API from "./lib/API";
import SegmentUtils from "./lib/SegmentUtils";

let win: any = window;

const loadGooglePaymentsClient = () => {
  if (!Capacitor.isNativePlatform()) {
    win.googlePayClient = new win.google.payments.api.PaymentsClient({
      environment: "TEST", // TODO: switch to production
    });

    //console.log("Google Pay Client loaded.");
  }
};

const loadSegmentClient = () => {
  SegmentUtils.load();
};

if (win) {
  if (win?.google?.payments) {
    loadGooglePaymentsClient();
  }

  loadSegmentClient();

  win.onGooglePayLoaded = function () {
    if (!win.google.payments) {
      loadGooglePaymentsClient();
    }
  };
}

if (win?.ResizeObserver) {
  // Save a reference to the original ResizeObserver
  const OriginalResizeObserver: any = window.ResizeObserver;

  // Create a new ResizeObserver constructor
  win.ResizeObserver = function (callback: any) {
    const wrappedCallback = (entries: any, observer: any) => {
      window.requestAnimationFrame(() => {
        callback(entries, observer);
      });
    };

    // Create an instance of the original ResizeObserver
    // with the wrapped callback
    return new OriginalResizeObserver(wrappedCallback);
  };

  // Copy over static methods, if any
  for (let staticMethod in OriginalResizeObserver) {
    if (OriginalResizeObserver.hasOwnProperty(staticMethod)) {
      win.ResizeObserver[staticMethod] = OriginalResizeObserver[staticMethod];
    }
  }
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (!Capacitor.isNativePlatform()) {
  serviceWorkerRegistration.register();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
