import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
  withIonLifeCycle,
  IonLoading,
} from "@ionic/react";
import { useParams } from "react-router";
import Navbar from "../../../../components/Checkout/Navbar/Navbar";
import ConversionForm from "../../../../components/ConversionForm/ConversionForm";
import "./Stores.css";

import background from "../../../../assets/img/backgrounds/bg_meals_superlight_lg.jpg";
import backgroundSM from "../../../../assets/img/backgrounds/bg_meals_superlight_sm.jpg";
import personalized from "../../../../assets/img/icons/personalized.svg";
import coaching from "../../../../assets/img/icons/coaching.svg";
import inbody from "../../../../assets/img/icons/inbody.svg";
import store from "../../../../assets/img/icons/store.svg";

import React, { useMemo } from "react";
import MealTopicSlider from "../../../../components/ConversionForm/MealTopicSlider";
import TestimonialSlider from "../../../../components/ConversionForm/TestimonialSlider";
import FAQQuestions from "../../../../components/FAQQuestions";
import Footer from "../../../../components/Checkout/Footer";
import { arrowForward, navigateCircleOutline } from "ionicons/icons";

import {
  Map,
  LayerProps,
  SymbolLayer,
  FullscreenControl,
  NavigationControl,
  Marker,
  Popup,
} from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";

import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import SegmentUtils, { SegmentIdentify } from "../../../../lib/SegmentUtils";
import StringUtils from "../../../../lib/StringUtils";
import SEOHeaders from "../../../../components/SEOHeaders";
import LocationSearchInput, {
  geocodeResult,
} from "../../../../components/Map/LocationSearchInput";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import mapboxgl from "mapbox-gl";

class Stores extends React.Component<{ history: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    mode: "selectstore",
    viewState: {
      longitude: -87.29267964419937,
      latitude: 37.4316,
      zoom: 4,
    },
    defaultViewState: {
      longitude: -87.29267964419937,
      latitude: 37.4316,
      zoom: 4,
    },
  };

  locationMapRef: any = React.createRef();
  formSection: any = React.createRef();
  listSection: any = React.createRef();

  setListStyles() {
    setTimeout(() => {
      if (!this.formSection?.current) {
        return;
      }

      let headerHeight = 118;

      if (document.getElementsByClassName("pln-navbar")?.length) {
        const navbar = document.getElementsByClassName("pln-navbar")[0];

        const { height } = navbar?.getBoundingClientRect();

        headerHeight = height;
      }

      const { height } = this.formSection?.current?.getBoundingClientRect();

      const listStyles = {
        maxHeight: this.state.isMobile
          ? "unset"
          : `calc(100vh - ${height}px - ${headerHeight}px)`,
        minHeight: this.state.isMobile
          ? `calc(100vh - ${height}px - ${headerHeight}px)`
          : `unset`,
        height: "auto",
        overflowY: this.state.isMobile ? "auto" : "scroll",
        marginTop: this.state.isMobile ? "unset" : "unset",
      };

      let formSectionStyles: any = {
        borderBottom: "1px solid var(--lighter)",
      };

      if (this.state.isMobile) {
        formSectionStyles = {
          position: "fixed",
          top: `calc(0px)`,
          background: "var(--white)",
          zIndex: 1,
          borderBottom: "1px solid var(--lighter)",
          width: "100%",
        };
      }

      const googleMapStyles = {
        width: "100%",
        height: `calc(100vh - ${headerHeight}px)`,
        background: "#000",
        position: "sticky",
        minHeight: "100%",
        top: 0,
      };

      this.setState({
        listStyles,
        googleMapStyles,
        formSectionStyles,
      });
    }, 0);
  }

  async onMarkerClick(data: any) {
    const listIdx = this.state.filteredStores?.length
      ? _.findIndex(this.state.filteredStores, (st: any) => {
          return st?.name == data?.title;
        })
      : -1;

    if (listIdx >= 0) {
      // console.log(listIdx);

      const scrollPixels = listIdx * 76.39 + 50;

      if (this.listSection?.current) {
        console.log(scrollPixels);

        this.listSection.current.scrollTop = scrollPixels;
      }

      const store = this.state.filteredStores[listIdx];

      //  console.log(store);

      /*

      if (DateUtils.storeIsComingSoon(store)) {
        this.setState({
          mode: "comingsoon",
        });

        return;
      }

      await StorageUtils.set("checkout_store", store);

      this.props.history.push("/start/plans");*/

      this.setState({
        activeIDX: listIdx,
      });
    }
  }

  /**
   * Fetches all the stores
   *
   * @returns
   */
  async loadStores() {
    this.setState({
      loadingStores: true,
      activeIDX: null,
    });

    return new Promise((resolve, reject) => {
      API.getStores()
        .then(
          (data: any) => {
            let stores = data?.data?.data?.stores?.length
              ? data?.data?.data?.stores
              : [];

            stores = _.filter(stores, (store: any) => {
              return (
                store &&
                (DateUtils.storeIsComingSoon(store, false) ||
                  DateUtils.storeIsPrelaunch(store) ||
                  DateUtils.storeIsLive(store))
              );
            });

            stores = _.filter(stores, (store: any) => {
              return store?.type == "retail";
            });

            stores = stores.map((st: any) => {
              st.name = st.name?.replace("Project LeanNation", "PLN").trim();

              if (st?.location?.address?.city) {
                st.location.address.city = st.location.address.city?.trim();
              }

              return st;
            });

            stores = _.sortBy(stores, "name");

            this.setState(
              {
                allStores: stores,
                allStoresLoaded: true,
              },
              () => {
                this.updateUserLocation(this.state.userLocation);
              }
            );

            if (!this.state.location) {
              this.setState({
                filteredStores: stores,
              });
            }

            resolve(stores);
          },
          (e) => {
            console.error(e);

            reject(e);
          }
        )
        .finally(() => {
          this.setState({
            loadingStores: false,
          });
        });
    });
  }

  async _loadLead(lead: any) {
    if (!lead) {
      return;
    }

    //console.log(lead);

    this.setState({
      lead,
    });

    if (lead?.zipCode) {
      let address = await geocodeByAddress(lead?.zipCode);

      if (address?.length) {
        let latLng = await getLatLng(address[0]);

        console.log(address);

        this.updateUserLocation({
          formattedAddress: address[0]?.formatted_address,
          latitude: latLng?.lat,
          longitude: latLng?.lng,
          showMarker: true,
          isStore: false,
        });
      }
    }
  }

  componentDidMount(): void {
    PubSub.subscribe(Event.NAVBAR.DISCOUNT_BANNER_CLOSED, () => {
      this.setListStyles();
    });

    this.loadStores();

    const windowSize = WindowUtils.getDimensions();

    if (windowSize !== null) {
      const mobile = windowSize?.width < 768;

      this.setState(
        {
          isMobile: mobile,
        },
        () => {
          this.setListStyles();
        }
      );
    } else {
      this.setListStyles();
    }

    PubSub.subscribe(Event.WINDOW_RESIZE, (size: any) => {
      // console.log("resize");

      if (size?.width) {
        if (this.state.isMobile && size?.width >= 768) {
          this.setState(
            {
              isMobile: false,
            },
            () => {}
          );
        } else if (!this.state.isMobile && size?.width < 768) {
          this.setState({
            isMobile: true,
          });
        }
      }

      this.setListStyles();
    });

    StorageUtils.get("checkout_lead").then((lead: any) => {
      if (lead) {
        this._loadLead(lead);
      }
    });

    PubSub.subscribe(Event.CHECKOUT.LEAD_RESTORED, (lead: any) => {
      this._loadLead(lead);
    });
  }

  ionViewDidEnter() {
    const windowSize = WindowUtils.getDimensions();

    if (windowSize !== null) {
      const mobile = windowSize?.width < 768;

      this.setState(
        {
          isMobile: mobile,
        },
        () => {
          this.setListStyles();
        }
      );
    } else {
      this.setListStyles();
    }

    setTimeout(() => {
      this.setState({
        showMap: true,
      });
    }, 0);
  }

  getStoreDistance(coordinates: any, store: any) {
    let distance: any = MapUtils.calculateDistanceInMiles(
      coordinates?.latitude,
      coordinates?.longitude,
      store?.location?.geographic?.lat,
      store?.location?.geographic?.lng
    );

    distance = Math.round(distance * 100) / 100;

    return distance;
  }

  async _loadStoreResults(stores: any = [], forceSelectStore = true) {
    if (!stores?.length) {
      this.setState({
        unavailable: true,
        mode: "unavailable",
        urlZipCode: this.state.zipCode,
      });

      return;
    }

    this.setState({
      loadingStores: false,
    });

    stores = stores.map((store: any) => {
      if (this.state.userLocation) {
        store.distanceMiles = this.getStoreDistance(
          this.state.userLocation,
          store
        );
      }

      store.name = store?.name?.replace("Project LeanNation", "PLN");

      return store;
    });

    if (this.state.userLocation) {
      stores = _.sortBy(stores, "distanceMiles");
    } else {
      stores = _.sortBy(stores, "name");
    }

    this.setState({
      filtered: true,
      filteredStores: stores, //debug
    });

    // if only one store, select it automatically
    if (stores?.length == 1) {
      const store = stores[0];

      // TODO: assign lead to store

      // Check if store is in coming soon mode
      if (DateUtils.storeIsComingSoon(store)) {
        this.setState({
          store,
          mode: "comingsoon",
        });

        return;
      }

      if (forceSelectStore) {
        this.selectStore(store);
      }
    }

    if (this.state.mode == "unavailable") {
      this.setState({
        mode: "selectstore",
      });
    }
  }

  /**
   * Fetches the user's current geolocation
   */
  async getGeolocation() {
    try {
      let coordinates: any = null;

      try {
        coordinates = await Geolocation.getCurrentPosition();
      } catch (e) {
        console.error(e);

        this.setState({
          loadingStores: false,
        });

        // TODO: set error here

        return;
      }

      this.setState({
        userGeolocation: coordinates,
      });

      if (!coordinates?.coords?.latitude || !coordinates?.coords?.longitude) {
        // TODO: set error here

        this.setState({
          loadingStores: false,
        });

        return;
      }

      // TODO: update here to use updateUserLocation()

      const { latitude, longitude } = coordinates?.coords;

      this.setState(
        {
          loadingStores: false,
        },
        () => {
          this.updateUserLocation({
            latitude,
            longitude,
            formattedAddress: "Your Current Location",
            showMarker: true,
            isGeolocation: true,
            isIP: false,
            zoom: 10,
          });
        }
      );

      // console.log(coordinates);
    } catch (e) {
      // TODO: show error here
    }
  }

  async selectStore(store: any, method: any = "") {
    if (!store) {
      return;
    }

    let lead: any = this.state.lead;

    //console.log("LEAD", lead);

    if (!lead) {
      lead = await StorageUtils.get("checkout_lead");
    }

    const identity: SegmentIdentify = {
      email: lead?.email,
    };

    if (lead?.phone) {
      identity.phone = lead?.phone;
    }

    if (store) {
      identity.store_id = store?._id;
      identity.store_name = store?.name?.replace("PLN", "Project LeanNation");
    }

    if (lead?.name) {
      identity.first_name = StringUtils.getFirstName(lead?.name);
      identity.last_name = StringUtils.getLastName(lead?.name);
    }

    SegmentUtils.track("Location Assigned", {
      store_id: store?._id,
      store_name: store?.name?.replace("PLN", "Project LeanNation"),
    });

    SegmentUtils.identify(identity);

    if (DateUtils.storeIsComingSoon(store, true)) {
      // console.log("COMING SOON");

      this.setState({
        mode: "comingsoon",
        store,
      });

      if (lead) {
        lead.storeID = store?._id;

        API.updateLeadCart(lead, { step: "plans" }, true)
          .then(
            async (data: any) => {
              let l = data.data.data.lead;

              await StorageUtils.set("checkout_lead", l);
            },
            (e) => {
              console.error(e);
            }
          )
          .finally(() => {
            this.setState({
              selectingStore: false,
            });
          });
      }

      return;
    }

    this.setState({
      selectingStore: true,
    });

    // TODO: set store for lead
    // TODO: save lead result into localstorage / storage api

    this.setState({
      store,
    });

    //console.log("HERE");

    let existingStore = await StorageUtils.get("checkout_store");

    if (existingStore && existingStore?._id != store?._id) {
      console.log("RESET DATE");

      await StorageUtils.set("checkout_first_bill_date", "");
    }

    await StorageUtils.set("checkout_store", store);

    if (lead) {
      lead.storeID = store?._id;

      PubSub.publish(Event.CHECKOUT.STORE_UPDATED, store);

      API.updateLeadCart(lead, { step: "plans" }, true)
        .then(
          async (data: any) => {
            let l = data.data.data.lead;

            await StorageUtils.set("checkout_lead", l);

            //PubSub.publish(Event.CHECKOUT.LEAD_RESTORED, l);

            console.log("LEAD LOADED");

            this.setState({
              selectingStore: false,
            });

            this.props.history?.push("/start/plans");
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            selectingStore: false,
          });
        });
    } else {
      console.log("NO LEAD");

      this.setState({
        selectingStore: false,
      });
    }
  }

  async updateUserLocation(data: any) {
    if (this.state.loadingStores) {
      return;
    }

    if (data) {
      if (this.locationMapRef?.current) {
        this.locationMapRef?.current?.flyTo({
          center: [data?.longitude, data?.latitude],
          zoom: data?.zoom ? data?.zoom : 9.5,
        });
      }

      this.setState(
        {
          userLocation: data,
          location: data?.formattedAddress ? data?.formattedAddress : "",
        },
        () => {
          let matchingStores = _.filter(this.state.allStores, (store: any) => {
            return this.getStoreDistance(data, store) <= 15;
          });

          this._loadStoreResults(matchingStores, matchingStores?.length == 1);
        }
      );
    } else {
      if (this.locationMapRef?.current) {
        this.locationMapRef?.current?.flyTo(this.state.defaultViewState);
      }

      this._loadStoreResults(this.state.allStores);

      this.setState({
        filtered: false,
        userLocation: null,
      });
    }
  }

  async submitLocation(zipCode: any = "") {
    if (!zipCode) {
      return;
    }

    this.setState({
      submittingLocation: true,
      userGeolocation: null,
      zipcodeGeolocation: null,
      loadingStores: true,
    });

    let lead = this.state.lead;

    if (lead) {
      lead.zipCode = zipCode;

      API.updateLeadCart(lead, { step: "locations" }).then(
        async (data: any) => {
          let l = data.data.data.lead;

          await StorageUtils.set("checkout_lead", lead);
        },
        (e) => {
          console.error(e);
        }
      );
    }
  }

  async requestGeolocation() {
    this.setState({
      requestingLocation: true,
      loadingStores: true,
    });

    SegmentUtils.track("Form Submitted", {
      name: "Checkout Locations Geolocation Form",
      submit_button_text: "Icon: navigateCircleOutline",
      submit_button_color: "white",
    });

    await this.getGeolocation();

    this.setState({
      requestingLocation: false,
    });
  }

  submitComingSoon() {
    this.setState({
      submittingComingSoon: true,
    });

    let params: any = {
      phone: this.state.phoneVal,
      name: this.state.name,
      storeID: this.state.store?._id,
    };

    API.createOrUpdateLead(this.state.email, null, params)
      .then(
        (res: any) => {
          if (res?.data?.data?.lead) {
            const lead = res?.data?.data?.lead;

            StorageUtils.set("checkout_lead", lead);

            let segmentProps: SegmentIdentify = {
              email: lead?.email,
              phone: lead?.phone,
              first_name: StringUtils.getFirstName(lead?.name),
              last_name: StringUtils.getLastName(lead?.name),
              store_id: lead?.storeID,
              store_name: this.state.store?.name,
              id: lead?.userID,
              lead_id: lead?._id,
            };

            SegmentUtils.identify(segmentProps);

            this.setState({
              comingSoonSubmitted: true,
            });
          }
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          submittingComingSoon: false,
        });
      });
  }

  getMarketingName(store: any) {
    if (store?.displayCity && store?.displayState) {
      return `${store?.displayCity?.trim()}, ${store?.displayState?.trim()}`;
    }

    return `${store?.location?.address?.city?.trim()}, ${store?.location?.address?.state?.trim()}`;
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {}

  render() {
    return (
      <IonPage>
        <Navbar
          showSupportOptions={false}
          onSupportChat={() => {}}
          onSupportCall={() => {}}
          activeStep={1.5}
          onGoBack={() => {
            if (
              this.state.mode == "comingsoon" ||
              this.state.mode == "unavailable"
            ) {
              this.setState({
                mode: "selectstore",
                location: null,
                userLocation: null,
              });

              return;
            }

            this.props.history.push("/start");
          }}
          showBackButton={true}
        ></Navbar>

        <IonContent style={{ background: "var(--white)" }}>
          <div
            className="pb-lg-4"
            style={{
              background: "var(--white)",
            }}
          >
            {/** Desktop View */}
            <div
              className="min-h-[100vh]"
              style={{
                maxWidth: "85rem",
                margin: "auto",
                overflow: "hidden",
              }}
            >
              <div className="flex items-start lg:gap-x-4">
                <div className="w-[100%] lg:max-w-[50vw]">
                  {this.state.mode == "selectstore" ? (
                    <>
                      <div className="px-4 pt-4">
                        <h1 className="text-2xl sm:text-3xl font-extrabold mt-0 mb-0">
                          View Your Local Menu & Plans
                        </h1>
                        <p className="text-lg leading-6 text-gray-900 mt-2">
                          Use geolocation or enter your postal code to view your
                          local menu & plan pricing.
                        </p>
                        <form
                          onSubmit={async (e) => {
                            e.preventDefault();

                            SegmentUtils.track("Form Submitted", {
                              name: "Checkout Locations Zip Code Form",
                              submit_button_text: "View",
                              submit_button_color: "primary",
                              fields: [
                                {
                                  name: "Zip Code",
                                  type: "text",
                                },
                              ],
                            });

                            try {
                              let address = await geocodeByAddress(
                                this.state.location?.address
                              );

                              if (address?.length) {
                                let latLng = await getLatLng(address[0]);

                                SegmentUtils.track("Button Clicked", {
                                  color: "solid",
                                  fill: "bg-gray-50",
                                  text: "Location Search",
                                  type: "event",
                                });

                                this.updateUserLocation({
                                  formattedAddress:
                                    address[0]?.formatted_address,
                                  latitude: latLng?.lat,
                                  longitude: latLng?.lng,
                                  showMarker: true,
                                  isStore: false,
                                });
                              }
                            } catch (e: any) {
                              console.log(e);
                            }
                          }}
                          className="mt-4 mb-3 mb-md-4"
                        >
                          <IonGrid className="p-0">
                            <IonRow className="ion-align-items-center">
                              {!this.state.userLocation?.formattedAddress ? (
                                <IonCol size-xs="auto" className="pr-2 pl-0">
                                  <IonButton
                                    size="large"
                                    color="white"
                                    className="pln-button-web button-icon-only"
                                    mode="ios"
                                    disabled={
                                      this.state.requestingLocation ||
                                      this.state.submittingLocation
                                    }
                                    onClick={() => {
                                      this.requestGeolocation();
                                    }}
                                  >
                                    {this.state.requestingLocation ? (
                                      <div
                                        style={{
                                          paddingLeft: "0.25rem",
                                          paddingRight: "0.25rem",
                                        }}
                                      >
                                        <IonSpinner
                                          name="dots"
                                          color="dark"
                                        ></IonSpinner>
                                      </div>
                                    ) : (
                                      <div
                                        slot="icon-only"
                                        className="px-[0.4rem]"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <line x1="2" x2="5" y1="12" y2="12" />
                                          <line
                                            x1="19"
                                            x2="22"
                                            y1="12"
                                            y2="12"
                                          />
                                          <line x1="12" x2="12" y1="2" y2="5" />
                                          <line
                                            x1="12"
                                            x2="12"
                                            y1="19"
                                            y2="22"
                                          />
                                          <circle cx="12" cy="12" r="7" />
                                        </svg>
                                      </div>
                                    )}
                                  </IonButton>
                                </IonCol>
                              ) : null}
                              <IonCol
                                size-xs=""
                                className={
                                  this.state.userLocation ? "pl-1" : "pl-0"
                                }
                                style={{ maxWidth: "calc(100% - 67px)" }}
                              >
                                {this.state.userLocation?.formattedAddress ? (
                                  <>
                                    <p className="rounded-sm text-truncate inline-block bg-white text-sm sm:text-base font-medium text-gray-800 focus:text-orange-500">
                                      Viewing
                                      {this.state.availableStores?.length
                                        ? ` ${this.state.availableStores?.length}`
                                        : ""}
                                      &nbsp;Location
                                      {this.state.availableStores?.length !=
                                        1 && "s"}
                                      {this.state.viewState?.zoom > 6
                                        ? " Nearby"
                                        : ""}
                                    </p>
                                    <h2 className="text-xl leading-6 mt-0 md:text-xl font-bold truncate">
                                      {
                                        this.state.userLocation
                                          ?.formattedAddress
                                      }
                                    </h2>
                                    {this.state.locationError ? (
                                      <div className="text-xs mt-1 text-red-500">
                                        {this.state.locationError}
                                      </div>
                                    ) : null}
                                  </>
                                ) : (
                                  <LocationSearchInput
                                    onChange={(data: any) => {
                                      this.setState({
                                        location: data,
                                      });
                                    }}
                                    onSelect={(data: any) => {
                                      console.log("select", data);

                                      this.updateUserLocation({
                                        formattedAddress:
                                          data?.result?.formatted_address,
                                        latitude: data?.geographic?.lat,
                                        longitude: data?.geographic?.lng,
                                        showMarker: true,
                                      });
                                    }}
                                  ></LocationSearchInput>
                                )}
                              </IonCol>

                              <IonCol size-xs="auto" className="pr-0">
                                {this.state.userLocation?.formattedAddress ? (
                                  <IonButton
                                    size="large"
                                    mode="ios"
                                    color="white"
                                    className="pln-button-web button-icon-only"
                                    disabled={
                                      !this.state.location ||
                                      this.state.submittingLocation
                                    }
                                    type="button"
                                    onClick={() => {
                                      this.updateUserLocation(null);
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingLeft: "0.5rem",
                                        paddingRight: "0.5rem",
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path d="M18 6 6 18" />
                                        <path d="m6 6 12 12" />
                                      </svg>
                                    </div>
                                  </IonButton>
                                ) : (
                                  <IonButton
                                    size="large"
                                    mode="ios"
                                    color="primary"
                                    className="pln-button-web button-icon-only"
                                    disabled={
                                      !this.state.location ||
                                      this.state.submittingLocation
                                    }
                                    type="submit"
                                  >
                                    {this.state.submittingLocation ? (
                                      <div
                                        style={{
                                          paddingLeft: "0.45rem",
                                          paddingRight: "0.45rem",
                                        }}
                                      >
                                        <IonSpinner
                                          name="dots"
                                          color="white"
                                        ></IonSpinner>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          paddingLeft: "0.55rem",
                                          paddingRight: "0.55rem",
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#FFFFFF"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path d="M5 12h14" />
                                          <path d="m12 5 7 7-7 7" />
                                        </svg>
                                      </div>
                                    )}
                                  </IonButton>
                                )}
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </form>
                      </div>
                      <div style={this.state.listStyles} ref={this.listSection}>
                        <IonList className="py-0">
                          <IonListHeader
                            mode="ios"
                            style={{ background: "var(--white)" }}
                          >
                            <IonLabel className="mt-3 mb-2">
                              {this.state.loadingStores ||
                              this.state.requestingLocation ? (
                                <>
                                  {this.state.allStores?.length ? (
                                    <>Finding Nearby Locations</>
                                  ) : (
                                    <>All Locations</>
                                  )}
                                </>
                              ) : (
                                <>
                                  {this.state.filtered ? (
                                    "Locations Near You"
                                  ) : (
                                    <>
                                      All {this.state.allStores?.length}{" "}
                                      Locations
                                    </>
                                  )}
                                </>
                              )}
                            </IonLabel>
                            {this.state.filtered ? (
                              <IonButton
                                onClick={() => {
                                  this.updateUserLocation(null);
                                }}
                              >
                                View All
                              </IonButton>
                            ) : null}
                          </IonListHeader>
                          {this.state.loadingStores ? (
                            <>
                              <IonItem
                                className="pln-store-list-item"
                                mode="ios"
                              >
                                <IonLabel>
                                  <IonSkeletonText
                                    animated={true}
                                    style={{ width: 130, height: 18 }}
                                  ></IonSkeletonText>

                                  <IonSkeletonText
                                    animated={true}
                                    style={{ width: "80%", height: 14 }}
                                  ></IonSkeletonText>
                                </IonLabel>
                              </IonItem>
                              <IonItem
                                className="pln-store-list-item"
                                mode="ios"
                              >
                                <IonLabel>
                                  <IonSkeletonText
                                    animated={true}
                                    style={{ width: 130, height: 18 }}
                                  ></IonSkeletonText>

                                  <IonSkeletonText
                                    animated={true}
                                    style={{ width: "80%", height: 14 }}
                                  ></IonSkeletonText>
                                </IonLabel>
                              </IonItem>
                              <IonItem
                                className="pln-store-list-item"
                                mode="ios"
                              >
                                <IonLabel>
                                  <IonSkeletonText
                                    animated={true}
                                    style={{ width: 130, height: 18 }}
                                  ></IonSkeletonText>

                                  <IonSkeletonText
                                    animated={true}
                                    style={{ width: "80%", height: 14 }}
                                  ></IonSkeletonText>
                                </IonLabel>
                              </IonItem>
                              <IonItem
                                className="pln-store-list-item"
                                mode="ios"
                              >
                                <IonLabel>
                                  <IonSkeletonText
                                    animated={true}
                                    style={{ width: 130, height: 18 }}
                                  ></IonSkeletonText>

                                  <IonSkeletonText
                                    animated={true}
                                    style={{ width: "80%", height: 14 }}
                                  ></IonSkeletonText>
                                </IonLabel>
                              </IonItem>
                            </>
                          ) : (
                            <>
                              {this.state.filteredStores?.map(
                                (store: any, i: number) => (
                                  <IonItem
                                    onMouseEnter={(e) => {
                                      if (!this.state.zipCode) {
                                        return;
                                      }

                                      this.setState({
                                        activeStoreItemHover: store?.name,
                                      });
                                    }}
                                    onMouseLeave={(e) => {
                                      if (!this.state.zipCode) {
                                        return;
                                      }

                                      if (
                                        this.state.activeStoreItemHover ==
                                        store?.name
                                      ) {
                                        this.setState({
                                          activeStoreItemHover: null,
                                        });
                                      }
                                    }}
                                    onClick={() => {
                                      this.selectStore(store);
                                    }}
                                    key={store?._id}
                                    className={`pln-store-list-item ${
                                      this.state.activeIDX == i
                                        ? "item-active"
                                        : ""
                                    }`}
                                    mode="ios"
                                    button={true}
                                  >
                                    <IonLabel>
                                      <h2 className="font-weight-500">
                                        {store?.name}
                                      </h2>
                                      <p className="text-medium">
                                        {this.state.userLocation &&
                                        store?.distanceMiles ? (
                                          <>
                                            {store?.distanceMiles} mi.
                                            &bull;&nbsp;
                                          </>
                                        ) : null}
                                        {store?.type == "retail"
                                          ? store?.location?.address
                                              ?.formattedAddress
                                          : "Delivered To You"}
                                      </p>
                                    </IonLabel>
                                  </IonItem>
                                )
                              )}
                            </>
                          )}
                        </IonList>
                      </div>
                    </>
                  ) : null}
                  {this.state.mode == "unavailable" ? (
                    <>
                      <>
                        <div
                          className="px-4 pt-4"
                          ref={this.formSection}
                          style={this.state.formSectionStyles}
                        >
                          <h1 className="display-3 mt-0 mb-0">
                            We're Always Expanding
                          </h1>
                          <p className="lead-sm mt-2">
                            Unfortunately Project LeanNation isn't available in
                            your zip code, {this.state.urlZipCode}, right now.
                          </p>
                          <p className="lead-sm mt-2">
                            We'll reach out to notify you so you're the first to
                            learn when we're opening a location in your area, as
                            well as receive exclusive membership offers.
                          </p>

                          <h4 className="mt-4 mb-0 font-weight-bold">
                            Or Enter A Different Location
                          </h4>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();

                              this.submitLocation(this.state.zipCode);
                            }}
                            className="mt-2 mb-3 mb-md-4"
                          >
                            <IonGrid className="p-0">
                              <IonRow className="ion-align-items-center">
                                <IonCol size-xs="" className="pl-0">
                                  <div className="relative mx-auto">
                                    <label
                                      htmlFor="name"
                                      className="absolute rounded-sm -top-[9px] left-2 inline-block bg-white px-1.5 text-[13px] font-medium text-gray-800 focus:text-orange-500"
                                    >
                                      Zip / Postal Code
                                    </label>
                                    <input
                                      autoCapitalize="none"
                                      inputMode="numeric"
                                      color="primary"
                                      autoFocus={true}
                                      type="text"
                                      name="zipCode"
                                      value={this.state.zipCode}
                                      autoComplete="postal-code"
                                      placeholder="90210"
                                      className="block w-full text-base sm:text-lg outline-0 rounded outline-0 border h-[56px] border-gray-100 px-3 py-[0.9rem] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 transition-all focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:leading-6"
                                      onChange={(e: any) => {
                                        const val = e?.target?.value;

                                        if (
                                          val?.length == 0 &&
                                          this.state.zipCode?.length > 1
                                        ) {
                                          if (this.state.allStoresLoaded) {
                                            this._loadStoreResults(
                                              this.state.allStores
                                            );
                                          }

                                          this.setState({
                                            userGeolocation: null,
                                            activeIDX: null,
                                            filtered: false,
                                          });
                                        }

                                        this.setState({
                                          zipCode: val,
                                        });
                                      }}
                                    />
                                  </div>
                                </IonCol>
                                <IonCol size-xs="auto" className="pr-0">
                                  <IonButton
                                    size="large"
                                    color="white"
                                    className="pln-button-web button-icon-only"
                                    mode="ios"
                                    disabled={
                                      this.state.requestingLocation ||
                                      this.state.submittingLocation
                                    }
                                    onClick={() => {
                                      this.requestGeolocation();
                                    }}
                                  >
                                    {this.state.requestingLocation ? (
                                      <div
                                        style={{
                                          paddingLeft: "0.25rem",
                                          paddingRight: "0.25rem",
                                        }}
                                      >
                                        <IonSpinner
                                          name="dots"
                                          color="dark"
                                        ></IonSpinner>
                                      </div>
                                    ) : (
                                      <IonIcon
                                        slot={"icon-only"}
                                        icon={navigateCircleOutline}
                                      ></IonIcon>
                                    )}
                                  </IonButton>
                                </IonCol>
                                <IonCol size-xs="auto" className="pr-0">
                                  <IonButton
                                    size="large"
                                    mode="ios"
                                    color="primary"
                                    className="pln-button-web"
                                    disabled={
                                      !this.state.zipCode ||
                                      this.state.submittingLocation
                                    }
                                    type="submit"
                                  >
                                    {this.state.submittingLocation ? (
                                      <div
                                        style={{
                                          paddingLeft: "0.45rem",
                                          paddingRight: "0.45rem",
                                        }}
                                      >
                                        <IonSpinner
                                          name="dots"
                                          color=""
                                        ></IonSpinner>
                                      </div>
                                    ) : (
                                      <>View</>
                                    )}
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </form>
                        </div>
                      </>
                    </>
                  ) : null}
                  {this.state.mode == "comingsoon" ? (
                    <>
                      <div className="px-4 pt-4" ref={this.formSection}>
                        <h1 className="display-3 mt-0 mb-0">
                          {this.getMarketingName(this.state.store)} Has A New
                          Way To Hit Health Goals
                        </h1>
                        <p className="lead-sm mt-2">
                          You'll be the first to learn more about Project
                          LeanNation Birmingham, including the benefits of PLN,
                          exclusive membership offers, and Grand Opening
                          information.
                        </p>

                        <h4 className="mt-4 mb-0 font-weight-bold">
                          Enter Your Information &amp; Receive Updates
                        </h4>
                        {this.state.comingSoonSubmitted ? (
                          <>
                            <div
                              className="my-4 p-4"
                              style={{
                                border: "1px solid var(--midlighter)",
                                borderRadius: 6,
                              }}
                            >
                              <h2 className="font-weight-bold">
                                We'll Be In Touch!
                              </h2>
                              <p>
                                Thanks for your interest in becoming a member of{" "}
                                {this.state.store?.name}! We'll reach out as we
                                get closer to opening with more exciting details
                                and exclusive offers.
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();

                                this.submitComingSoon();
                              }}
                              className="mt-2 mb-3"
                            >
                              <IonItem
                                color="primary"
                                className={`pln-input-web my-3`}
                                mode="md"
                                fill="outline"
                              >
                                <IonLabel position="floating">Name</IonLabel>
                                <IonInput
                                  autocapitalize="none"
                                  inputMode="text"
                                  color="primary"
                                  type="text"
                                  name="name"
                                  value={this.state.name}
                                  autocomplete="name"
                                  placeholder="Name"
                                  required={true}
                                  clearInput={true}
                                  enterkeyhint={"done"}
                                  onIonChange={(e: any) => {
                                    const val = e?.detail?.value;

                                    this.setState({
                                      name: val,
                                    });
                                  }}
                                ></IonInput>
                              </IonItem>
                              <IonItem
                                color="primary"
                                className={`pln-input-web mb-3`}
                                mode="md"
                                fill="outline"
                              >
                                <IonLabel position="floating">Phone</IonLabel>
                                <IonInput
                                  autocapitalize="none"
                                  name="phone"
                                  inputMode="tel"
                                  color="primary"
                                  type="tel"
                                  autocomplete="tel"
                                  placeholder="Phone"
                                  required={true}
                                  clearInput={true}
                                  value={this.state.phone}
                                  onIonChange={(e: any) => {
                                    const val =
                                      StringUtils.formatPhoneNumberWhileTyping(
                                        e?.detail?.value
                                      );

                                    // console.log(val);
                                    this.setState({
                                      phone: val?.formatted,
                                      phoneVal: val?.value,
                                    });
                                  }}
                                  onIonBlur={() => {}}
                                ></IonInput>
                              </IonItem>
                              <IonItem
                                color="primary"
                                className={`pln-input-web mb-3`}
                                mode="md"
                                fill="outline"
                              >
                                <IonLabel position="floating">Email</IonLabel>
                                <IonInput
                                  autocapitalize="none"
                                  inputMode="email"
                                  color="primary"
                                  type="email"
                                  name="email"
                                  autocomplete="email"
                                  placeholder="Email"
                                  required={true}
                                  value={this.state.email}
                                  clearInput={true}
                                  onIonChange={(e: any) => {
                                    const val = e?.detail?.value;

                                    this.setState({
                                      email: val,
                                    });
                                  }}
                                  onIonBlur={() => {}}
                                ></IonInput>
                              </IonItem>

                              <IonButton
                                size="large"
                                mode="ios"
                                color="primary"
                                expand="block"
                                className="pln-button-web"
                                disabled={
                                  !this.state.name ||
                                  !this.state.phone ||
                                  !this.state.email ||
                                  this.state.submittingComingSoon
                                }
                                type="submit"
                                onClick={() => {
                                  this.submitComingSoon();
                                }}
                              >
                                {this.state.submittingComingSoon ? (
                                  <div>
                                    <IonSpinner
                                      name="dots"
                                      color=""
                                    ></IonSpinner>
                                  </div>
                                ) : (
                                  <>Keep Me Updated</>
                                )}
                              </IonButton>
                            </form>
                            <div className="px-4 text-center my-3">or</div>
                          </>
                        )}
                        <IonButton
                          size="large"
                          mode="ios"
                          color="dark"
                          fill="outline"
                          expand="block"
                          className="pln-button-web-outline mb-4"
                          onClick={() => {
                            this.setState({
                              mode: "selectstore",
                              store: null,
                              zipCode: "",
                            });

                            this._loadStoreResults(this.state.allStores);

                            if (this.state.googleMap) {
                              this.state.googleMap.setCamera({
                                coordinate: {
                                  lat: 37.4316,
                                  lng: -84.6569,
                                },
                                zoom: 5,
                              });
                            }
                          }}
                          disabled={this.state.submittingComingSoon}
                          type="submit"
                        >
                          View All Locations
                        </IonButton>
                      </div>
                    </>
                  ) : null}
                </div>
                <div
                  style={{
                    position: "fixed",
                    right: 0,
                    top: `calc(${
                      this.state.defaultCoupon ? "115px" : "71px"
                    } + 0.75rem)`,
                  }}
                  className="hidden d-lg-block w-full  border-[1px] border-gray-100 min-h-[300px] h-[90vh] max-w-[47vw] max-h-[500px] mt-[1rem] mr-[1rem] rounded-[0.5rem] overflow-hidden shadow-sm"
                >
                  {this.state.showMap ? (
                    <Map
                      mapLib={mapboxgl}
                      cooperativeGestures={true}
                      mapboxAccessToken={
                        "pk.eyJ1IjoiYnJhbmRvbnBsbiIsImEiOiJjbG45aDFiaTAwNWQxMmptZzAzOG1uaXY5In0.XXjMdPbzVh4zxnSxAP6akw"
                      }
                      {...this.state.viewState}
                      onMove={(evt) => {
                        this.setState({
                          bounds: this.locationMapRef?.current?.getBounds(),
                          viewState: evt?.viewState,
                        });
                      }}
                      style={{ width: "100%", height: "100%" }}
                      mapStyle="mapbox://styles/brandonpln/clnckvms707vb01qi8o4h8fo5"
                      maxZoom={17}
                      minZoom={3}
                      id="locationMap"
                      ref={this.locationMapRef}
                    >
                      <FullscreenControl position="top-right" />
                      <NavigationControl
                        showCompass={false}
                        position="top-right"
                      />

                      {this.state?.userLocation?.showMarker ? (
                        <Marker
                          latitude={this.state?.userLocation?.latitude}
                          longitude={this.state?.userLocation?.longitude}
                          draggable={false}
                          scale={0.8}
                          offset={[0, -10]}
                        >
                          <svg
                            width="25px"
                            height="25px"
                            viewBox="0 0 100 100"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title>Artboard</title>
                            <defs>
                              <circle
                                id="path-1"
                                cx="50"
                                cy="50"
                                r="45"
                              ></circle>
                              <filter
                                x="-12.2%"
                                y="-11.1%"
                                width="124.4%"
                                height="124.4%"
                                filterUnits="objectBoundingBox"
                                id="filter-2"
                              >
                                <feOffset
                                  dx="0"
                                  dy="1"
                                  in="SourceAlpha"
                                  result="shadowOffsetOuter1"
                                ></feOffset>
                                <feGaussianBlur
                                  stdDeviation="3.5"
                                  in="shadowOffsetOuter1"
                                  result="shadowBlurOuter1"
                                ></feGaussianBlur>
                                <feComposite
                                  in="shadowBlurOuter1"
                                  in2="SourceAlpha"
                                  operator="out"
                                  result="shadowBlurOuter1"
                                ></feComposite>
                                <feColorMatrix
                                  values="0 0 0 0 0.0666666667   0 0 0 0 0.0784313725   0 0 0 0 0.0666666667  0 0 0 0.362953453 0"
                                  type="matrix"
                                  in="shadowBlurOuter1"
                                ></feColorMatrix>
                              </filter>
                            </defs>
                            <g
                              id="Artboard"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <g id="Oval">
                                <use
                                  fill="black"
                                  fillOpacity="1"
                                  filter="url(#filter-2)"
                                ></use>
                                <circle
                                  stroke="#FFFFFF"
                                  strokeWidth="11"
                                  strokeLinejoin="inherit"
                                  fill="#FB6340"
                                  fillRule="evenodd"
                                  cx="50"
                                  cy="50"
                                  r="39.5"
                                ></circle>
                              </g>
                            </g>
                          </svg>
                        </Marker>
                      ) : null}

                      {this.state?.filteredStores?.map((store: any) => (
                        <Marker
                          key={store?._id}
                          latitude={store?.location?.geographic?.lat}
                          longitude={store?.location?.geographic?.lng}
                          draggable={false}
                          scale={0.8}
                          offset={[0, -10]}
                          onClick={(e) => {
                            return;

                            // If we let the click event propagates to the map, it will immediately close the popup
                            // with `closeOnClick: true`
                            /**
                             * e.originalEvent.stopPropagation();
                            //setPopupInfo(store);

                            this.setState(Popu)

                            setPriorViewState({
                              ...viewState,
                            });

                            SegmentUtils.track("Button Clicked", {
                              color: "solid",
                              fill: "",
                              text: "Store Location Marker",
                              type: "event",
                            });

                            updateUserLocation({
                              latitude: store?.location?.geographic?.lat,
                              longitude: store?.location?.geographic?.lng,
                              formattedAddress:
                                store?.location?.address?.formattedAddress,
                              showMarker: false,
                              zoom: 16,
                              isStore: true,
                            });
                             */
                          }}
                        >
                          <svg
                            width="32px"
                            height="38px"
                            viewBox="0 0 257 300"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cursor-pointer"
                          >
                            <title>Artboard</title>
                            <g
                              id="Artboard"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <path
                                d="M128.5,252 C175.602132,252 213.848505,223.700149 236.220859,186.49442 C247.556841,167.642429 255.17764,148.994996 255.17764,125.5 C255.17764,90.2946568 242.805089,58.321064 219.468708,35.9948472 C196.132327,13.6686304 163.706641,2.54610247 128.5,2.54610247 C57.5314097,2.54610247 1.86237402,55.6359792 1.86237402,125.5 C1.86237402,136.554182 2.64537399,145.972541 5.63239746,155.638877 C9.24741912,167.337484 13.803557,179.027937 20.4762726,189.617924 C26.0806152,198.51234 31.5558975,208.128543 37.802813,216.446201 C56.4624279,241.291149 93.7639984,252 128.5,252 Z"
                                id="Oval"
                                fill="#212529"
                              ></path>
                              <g id="Group">
                                <path
                                  d="M128.5,0 C162.745186,0 193.90455,12.8038793 216.961562,33.6413881 L199.175499,51.2439661 C180.728708,33.8467874 155.842718,23.1638935 128.5,23.1638935 C71.7635134,23.1638935 25.6049047,69.1596847 25.6049047,125.698302 C25.6049047,152.695981 36.1297373,177.290041 53.3054116,195.620116 L35.4885199,213.25362 C29.683854,205.455723 24.5638998,197.687003 20.156927,189.983923 C6.77800036,166.599788 0,143.791418 0,122.186859 C0,54.8132851 57.6448108,0 128.5,0 Z"
                                  id="Combined-Shape"
                                  fill="#77CE00"
                                  fillRule="nonzero"
                                ></path>
                                <path
                                  d="M221.39976,37.8511446 C243.444189,59.7970733 257,89.515121 257,122.186859 C257,143.791418 250.222,166.599788 236.843073,189.983923 C226.002207,208.938398 210.843359,228.286301 191.78757,247.490426 C172.36815,266.907889 151.181349,284.486538 128.5,300 C105.818651,284.486538 84.6318497,266.907889 65.2124301,247.490426 C55.7816474,237.985249 47.3050566,228.44437 39.8337109,218.933639 L58.25855,200.560807 C76.6607562,217.717592 101.371155,228.234018 128.5,228.234018 C185.236487,228.234018 231.395095,182.235612 231.395095,125.698302 C231.395095,98.6328392 220.816857,73.9828927 203.563895,55.6383903 Z"
                                  id="Combined-Shape"
                                  fill="#FB6340"
                                  fillRule="nonzero"
                                ></path>
                                <g
                                  id="Project_LeanNation_Symbol_Round_Primary"
                                  transform="translate(3.684588, 0.920245)"
                                >
                                  <path
                                    d="M95.3937321,84.2699387 C99.2411582,84.2699387 102.360113,87.3888938 102.360113,91.23632 L102.360113,129.231333 C102.360113,133.078759 99.2411582,136.197715 95.3937321,136.197715 L75.0237016,136.197715 L75.0237016,166.03681 L62.6379928,166.03681 L62.6379928,84.2699387 L95.3937321,84.2699387 Z M89.3820834,94.6938852 L74.6318941,94.6938852 L74.6318941,125.613376 L89.3820834,125.613376 L89.3820834,94.6938852 Z M108.728926,84.2699387 L121.061783,84.2699387 L121.061783,155.205604 L141.239952,155.205604 L141.239952,166.03681 L108.728926,166.03681 L108.728926,84.2699387 Z M147.085963,166.03681 L159.161532,166.03681 L159.161532,116.645244 L177.522995,166.03681 L188.749152,166.03681 L188.749152,84.2699387 L176.911221,84.2699387 L176.911221,132.314667 L158.473528,84.2699387 L147.085963,84.2699387 L147.085963,166.03681 Z"
                                    id="Combined-Shape"
                                    fill="#FFFFFF"
                                  ></path>
                                  <path
                                    d="M250.551971,125.153374 C250.551971,194.273674 194.464002,250.306748 125.275986,250.306748 C92.3557386,250.306748 62.4012602,237.621211 40.0432124,216.876267 L54.2090521,202.687732 C72.9320703,219.824737 97.8815949,230.282209 125.275986,230.282209 C183.393919,230.282209 230.507814,183.214426 230.507814,125.153374 C230.507814,97.6847102 219.962627,72.6765959 202.695952,53.9489876 L216.860216,39.7588663 C237.760808,62.120987 250.551971,92.1448744 250.551971,125.153374 Z"
                                    id="Combined-Shape"
                                    fill="#FB6340"
                                  ></path>
                                  <path
                                    d="M125.275986,0 C158.29843,0 188.336664,12.7644215 210.716764,33.6239168 L196.497374,47.7606683 C177.757221,30.5396557 152.745775,20.0245399 125.275986,20.0245399 C67.1580519,20.0245399 20.0441577,67.0923223 20.0441577,125.153374 C20.0441577,152.414513 30.4306087,177.25214 47.4658231,195.932226 L33.2462461,210.068732 C12.609541,187.757774 -1.42108547e-14,157.926691 -1.42108547e-14,125.153374 C-1.42108547e-14,56.0330743 56.0879693,0 125.275986,0 Z"
                                    id="Combined-Shape"
                                    fill="#77CE00"
                                  ></path>
                                </g>
                              </g>
                              <path
                                d="M128.5,1 C162.096461,1 192.701658,13.4150503 215.503474,33.6774962 L215.503474,33.6774962 L199.15558,49.8567421 C180.603126,32.6807133 155.760817,22.1638935 128.5,22.1638935 C99.8563295,22.1638935 73.882729,33.7743902 55.0701551,52.5210188 C36.2564751,71.2687497 24.6049047,97.1530162 24.6049047,125.698302 C24.6049047,152.611194 34.9619915,177.158947 51.9135992,195.590649 L51.9135992,195.590649 L35.6050864,211.731299 C30.1071004,204.277769 25.2385681,196.852509 21.0249052,189.487321 C7.74397697,166.274471 1,143.635602 1,122.186859 C1,88.7812084 15.2955974,58.4922566 38.3685237,36.5528918 C61.4600725,14.5958193 93.3422122,1 128.5,1 Z"
                                id="Combined-Shape"
                                stroke="#212529"
                                strokeWidth="2"
                                fill="#77CE00"
                                fillRule="nonzero"
                              ></path>
                              <path
                                d="M221.397569,39.2656169 C242.841501,60.9564008 256,90.1373181 256,122.186859 C256,143.635602 249.256023,166.274471 235.975022,189.487448 C225.172855,208.374261 210.06524,227.650748 191.080499,246.783283 C171.865645,265.996202 150.91654,283.404774 128.497174,298.785884 C106.080882,283.402631 85.133196,265.995043 65.9223095,246.786102 C56.750926,237.542372 48.4844615,228.264977 41.1706202,219.014444 L41.1706202,219.014444 L58.2850663,201.94665 C76.7893391,218.882618 101.454434,229.234018 128.5,229.234018 C157.143416,229.234018 183.116812,217.623074 201.929348,198.876244 C220.743323,180.127981 232.395095,154.243178 232.395095,125.698302 C232.395095,98.7162844 221.98426,74.1113803 204.954473,55.6638911 L204.954473,55.6638911 Z"
                                id="Combined-Shape"
                                stroke="#212529"
                                strokeWidth="2"
                                fill="#FB6340"
                                fillRule="nonzero"
                              ></path>
                            </g>
                          </svg>
                        </Marker>
                      ))}
                    </Map>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </IonContent>
        <IonLoading
          isOpen={this.state.selectingStore}
          mode="ios"
          message={"Hang tight, we're loading your menu!"}
          animated={true}
          spinner={"dots"}
        />
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Stores);
