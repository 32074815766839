import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router";

import star from "../assets/img/icons/star.svg";

const TestimonialCard: React.FC<{ body?: string; name?: string }> = ({
  body = "",
  name = "",
}) => {
  return (
    <>
      <div
        style={{
          background: "var(--white)",
          width: "100%",
          height: "auto",
          borderRadius: 6,
        }}
        className="p-4 p-md-5 text-center"
      >
        <div>
          <svg
            width="14px"
            height="10px"
            viewBox="0 0 14 10"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: 28, height: 20 }}
          >
            <title>format-quote-close</title>
            <g
              id="Misc"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="format-quote-close" fill="#FB6340" fill-rule="nonzero">
                <path
                  d="M9,10 L12,10 L14,6 L14,0 L8,0 L8,6 L11,6 M1,10 L4,10 L6,6 L6,0 L0,0 L0,6 L3,6 L1,10 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <h2
          className="display-3 my-4"
          style={{ maxWidth: 800, margin: "auto" }}
        >
          {body}
        </h2>
        <div
          className="d-flex"
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: 15,
              height: 2,
              background: "var(--dark)",
              position: "relative",
              top: 1,
            }}
          ></div>
          <div
            className="pl-1"
            style={{ fontSize: 18, fontWeight: 500, color: "var(--dark)" }}
          >
            {name}
          </div>
        </div>
        <div className="mt-4">
          <img
            src={star}
            alt="Star Icon"
            style={{ height: 24, width: 24 }}
            className="mr-2"
          ></img>
          <img
            src={star}
            alt="Star Icon"
            style={{ height: 24, width: 24 }}
            className="mr-2"
          ></img>
          <img
            src={star}
            alt="Star Icon"
            style={{ height: 24, width: 24 }}
            className="mr-2"
          ></img>
          <img
            src={star}
            alt="Star Icon"
            style={{ height: 24, width: 24 }}
            className="mr-2"
          ></img>
          <img
            src={star}
            alt="Star Icon"
            style={{ height: 24, width: 24 }}
          ></img>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
