import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRadio,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useRef, useState } from "react";
import { useParams } from "react-router";

import plnSymbol from "../../../assets/img/logo/pln_symbol.svg";
import { chevronBackOutline, close, helpCircleOutline } from "ionicons/icons";
import LinkUtils from "../../lib/LinkUtils";

const Footer: React.FC<{ bgWhite?: boolean }> = ({ bgWhite = false }) => {
  const marketingModalRef = useRef<HTMLIonModalElement>(null);

  return (
    <div style={{ background: bgWhite ? "white" : "", zIndex: 0 }}>
      <div
        style={{
          maxWidth: 1200,
          margin: "auto",
        }}
        className="px-2 py-3"
      >
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="12" sizeMd="" className="text-center text-md-left">
              <div className="text-medium" style={{ fontSize: 14 }}>
                &copy; Project LeanNation Franchising Inc. All Rights Reserved.
              </div>
            </IonCol>
            <IonCol
              sizeXs="12"
              sizeMd="auto"
              className="text-center text-md-right"
            >
              <a
                href="https://www.iubenda.com/terms-and-conditions/76912715"
                rel="noreferrer"
                onClick={(e: any) => {
                  e.preventDefault();

                  LinkUtils.openInNewTab(
                    "https://www.iubenda.com/terms-and-conditions/76912715"
                  );
                }}
                className="mr-2"
                style={{
                  color: "var(--medium)",
                  textDecoration: "none",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Terms
              </a>
              <a
                className="mr-2"
                href="https://www.iubenda.com/privacy-policy/76912715"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();

                  LinkUtils.openInNewTab(
                    "https://www.iubenda.com/privacy-policy/76912715"
                  );
                }}
                style={{
                  color: "var(--medium)",
                  textDecoration: "none",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Privacy
              </a>
              <a
                href="#"
                style={{
                  color: "var(--medium)",
                  textDecoration: "none",
                  fontWeight: 500,
                  fontSize: 14,
                }}
                onClick={(e) => {
                  e.preventDefault();

                  marketingModalRef?.current?.present();
                }}
              >
                Marketing Policy
              </a>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <IonModal ref={marketingModalRef} mode="ios">
        <IonHeader>
          <IonToolbar
            className="box-modal-toolbar-web"
            style={{ borderBottom: "none" }}
          >
            <IonTitle>Marketing Policy</IonTitle>
            <IonButtons slot="end">
              <IonButton
                color="dark"
                onClick={() => {
                  marketingModalRef?.current?.dismiss();
                }}
              >
                <IonIcon slot="icon-only" icon={close}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div>
            <div className="p-4">
              By accepting Project LeanNation Franchising Inc.'s Marketing
              Policy, you assert the following: I agree to receive recurring
              telephone calls and/or SMS or MMS text messages or emails for
              marketing purposes at the phone number and/or email address
              provided, including but not limited to calls or texts sent using
              an automatic telephone dialing system or an artificial or
              prerecorded voice. I am authorized to consent to receive text
              messages sent to the phone number provided. I understand that I
              may revoke consent at any time. I understand that consent is not a
              condition of purchase. Message and data rates may apply. View our{" "}
              <a
                href="https://www.iubenda.com/terms-and-conditions/76912715"
                rel="noreferrer"
                onClick={(e: any) => {
                  e.preventDefault();

                  LinkUtils.openInNewTab(
                    "https://www.iubenda.com/terms-and-conditions/76912715"
                  );
                }}
              >
                Terms of Service
              </a>
              &nbsp;for details.
            </div>
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default Footer;
