import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";

import "./SchedulingHourButton.css";

import moment from "moment-timezone";
import _ from "underscore";
import {
  checkmark,
  checkmarkOutline,
  checkmarkSharp,
  chevronBack,
  chevronForward,
  close,
} from "ionicons/icons";
import PubSub from "../../../lib/PubSub";

const SchedulingHourButton: React.FC<{
  onDateSelected?: any;
  date?: any;
  loading?: boolean;
  disabled?: boolean;
}> = ({
  date = null,
  disabled = false,
  loading = false,
  onDateSelected = null,
}) => {
  const [selected, setSelected] = useState<boolean>(false);

  useEffect(() => {
    PubSub.subscribe("scheduling-hour-button-selected", (evt: any) => {
      if (evt !== date) {
        setSelected(false);
      }
    });
  }, []);

  const dateMoment = useMemo(() => {
    if (!date) {
      return null;
    }

    return moment(date);
  }, [date]);

  const onConfirm = () => {
    if (typeof onDateSelected == "function") {
      onDateSelected(date);
    }
  };

  return (
    <>
      {loading ? (
        <IonSkeletonText
          style={{
            borderRadius: 6,
            width: "100%",
            height: 50,
          }}
          animated={true}
        />
      ) : (
        <div
          className="d-flex ion-align-items-center"
          style={{ height: 50, width: "100%" }}
        >
          <div
            className={`${
              disabled || !dateMoment ? "disabled" : "cursor-pointer"
            } ${
              selected && "selected"
            } d-flex ion-align-items-center ion-justify-content-center scheduling-hour-button`}
            onClick={() => {
              if (disabled) {
                return;
              }

              if (!selected) {
                PubSub.publish("scheduling-hour-button-selected", date);

                setSelected(true);
              }
            }}
          >
            <h4
              className="m-0 text-center"
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingLeft: "0.25rem",
                paddingRight: "0.25rem",
              }}
            >
              {dateMoment ? dateMoment.format("h:mm A") : "--"}
            </h4>
          </div>
          {selected && (
            <>
              <div
                style={{
                  height: 50,
                  borderRadius: 6,
                  border: "1px solid var(--danger)",
                  background: "var(--danger)",
                  color: "white",
                  width: 50,
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                  fontSize: 24,
                  cursor: "pointer",
                }}
                className="cursor-pointer d-flex ion-align-items-center ion-justify-content-center"
                onClick={() => {
                  setSelected(false);
                }}
              >
                <IonIcon icon={close} />
              </div>
              <div
                style={{
                  height: 50,
                  borderRadius: 6,
                  border: "1px solid var(--success)",
                  width: 50,
                  color: "white",
                  background: "var(--success)",
                  fontSize: 24,
                }}
                className="cursor-pointer d-flex ion-align-items-center ion-justify-content-center"
                onClick={() => {
                  onConfirm();
                }}
              >
                <IonIcon icon={checkmarkSharp} />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SchedulingHourButton;
