import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlide,
  IonSlides,
  IonAccordionGroup,
  IonAccordion,
  IonCol,
  IonRow,
  IonGrid,
} from "@ionic/react";

import contract from "../assets/img/icons/contract.svg";
import pause from "../assets/img/icons/pause.svg";
import support from "../assets/img/icons/support.svg";

const FAQQuestions: React.FC<{ body?: string; name?: string }> = ({
  body = "",
  name = "",
}) => {
  let questions = [
    {
      value: "one",
      question: "What is Project LeanNation?",
      answer:
        "<p>Project LeanNation is our continued commitment to providing the community with healthy, convenient, and affordable meals – while simultaneously acting as a support hub for education and support of your individual goals. We know the right food can change your life; it already has for so many of us!</p>",
    },
    {
      value: "two",
      question: "Why do you have retail stores?",
      answer: `<div>
        <p>Our stores are the epicenters of the community. We maintain a positive environment for in-store subscription pickups, or even just a place to come to work or socialize. Whenever possible, we prefer to conduct in-person assessments and consultations with current and prospective members, so that we may provide education and support to the best of our ability. All of our meals and <a href="https://projectleannation.com/refuel-shakes" target="_blank">Refuel Shakes</a> are also available at each location.</p>
<p>For more info on the individual stores, <a href="https://projectleannation.com/contact-us" target="_blank">click here to contact us</a>.</p>
    </div>`,
    },
    {
      value: "three",
      question: "What's the portion size like?",
      answer: `<div>
        <p>All of our meals are single serving and meant to feed an average adult. On average, our Lifestyle meals weigh in at just about 12-13 ounces and Athlete meals are 16-18oz.</p>
<p>Average Calories per plan.</p>
<p>Lifestyle Meals: <strong>451</strong><br>
Athlete Meals: <strong>660</strong></p>
    </div>`,
    },
    {
      value: "four",
      question: "Can I choose my meals?",
      answer:
        "Yes, you can!  Part of the signup process is selecting your first order from our current menu. Then, you’ll have the power to make changes to your selections as often or as infrequently as you so choose.",
    },
    {
      value: "five",
      question: "Can I change my plan?",
      answer:
        "Yes, you can! After completing the signup process, you can change your plan at any time to increase or decrease the amount of meals you'll receive weekly.",
    },
    {
      value: "six",
      question: "How are your ingredients sourced?",
      answer:
        "We aim to source the best ingredients we can find and prepare them by hand. Our main distributor is a local, fifth-generation family business. We are committed to maintaining the integrity of our philosophy – healthy, convenient, and affordable.",
    },
    {
      value: "seven",
      question: "What is the minimum order?",
      answer:
        "Both the Lifestyle and Athlete plans have a 12-meal option which you can subscribe to on a weekly or biweekly basis. Should you prefer to receive fewer meals per week, you can purchase a-la-carte meals at any of our stores! (*Subject to availability)",
    },
    {
      value: "eight",
      question: "Do you offer any gluten-free options?",
      answer:
        "You bet – all of our meals are gluten-free! We currently have several 100% grain-free/dairy free options as well. There may be gluten-containing ingredients in the facility in which the meals are manufactured, but cross-contaminations are rare.",
    },
    {
      value: "nine",
      question: "What can I expect during my consultation?",
      answer: `The Project Lean Nation consultation is what we believe sets us apart from being just another meal service. During your free consultation, our nutritional coaches will discuss your goals and/or challenges and help you create a nutritional strategy that will get you where you want to be. You will also receive a body composition scan using our Inbody 270. This will provide you with insights on your body composition, hydration and provide us everything we need to make an ideal caloric recommendation based specifically on your goals.`,
    },
  ];

  return (
    <>
      <IonAccordionGroup
        className="pln-accordion-group"
        mode="md"
        expand="inset"
      >
        {questions?.map((question: any) => (
          <IonAccordion
            className="pln-accordion"
            key={question?.value}
            value={question?.value}
          >
            <IonItem className="no-ripple" slot="header" color="">
              <IonLabel
                style={{ fontWeight: 500 }}
                className="py-2"
                color="dark"
              >
                {question?.question}
              </IonLabel>
            </IonItem>
            <div
              dangerouslySetInnerHTML={{ __html: question?.answer }}
              className="ion-padding"
              slot="content"
            ></div>
          </IonAccordion>
        ))}
      </IonAccordionGroup>
      <div className="px-2 pb-5 mt-4 mt-lg-5">
        <IonGrid>
          <IonRow>
            <IonCol size-xs="12" size-md="4" className="text-center">
              <div
                className="d-flex"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <img
                  src={pause}
                  alt="Personalized Plan Icon"
                  style={{ height: 35, width: 35 }}
                ></img>
                <div className="ml-3">
                  <h5 className="m-0 font-weight-500 text-medium text-left">
                    Skip Any Week.
                  </h5>
                </div>
              </div>
            </IonCol>
            <IonCol
              size-xs="12"
              size-md="4"
              className="text-center mt-3 mt-md-0"
            >
              <div
                className="d-flex"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <img
                  src={contract}
                  alt="Personalized Plan Icon"
                  style={{ height: 35, width: 35 }}
                ></img>
                <div className="ml-3">
                  <h5 className="m-0 font-weight-500 text-medium text-left">
                    No Contracts. Cancel Anytime.
                  </h5>
                </div>
              </div>
            </IonCol>

            <IonCol
              size-xs="12"
              size-md="4"
              className="text-center mt-3 mt-md-0"
            >
              <div
                className="d-flex"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <img
                  src={support}
                  alt="Personalized Plan Icon"
                  style={{ height: 35, width: 35 }}
                ></img>
                <div className="ml-3">
                  <h5 className="m-0 font-weight-500 text-medium text-left">
                    In-Person & Online Support.
                  </h5>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default FAQQuestions;
